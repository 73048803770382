import { DataGridItem } from '../../../core/componet'
import { Identity, Nullable } from '../../../core/type'

export interface ItemCategory extends Identity {
  title: string
  level: ItemCategoryLevel
  parent: ItemCategory
}

export interface ItemCategoryEntry extends DataGridItem, Omit<ItemCategory, 'createdAt'
  | 'updatedAt'> {
}

export interface ItemCategoryDto {
  title: string
  parentId: Nullable<number>
}

export interface ItemCategoryFindRequest {
  ids?: number[]
  id?: number
  title?: string
  level?: ItemCategoryLevel
  parentId?: number
}

export enum ItemCategoryLevel {
  Category = 'Category',
  Subcategory = 'Subcategory',
  Subcategory2 = 'Subcategory2',
}
