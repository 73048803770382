import { notify, translate } from '../../../core'
import { Form, Modal } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { BadRequestException } from '../../../core/type'
import { ErrorMessage } from '../../../core/componet'
import { ItemEntry } from '../type'
import { ItemPageContext } from './ItemPageContext'
import { ItemForm } from './ItemForm'
import { useItemData } from '../hook/item-data-hook'

interface ItemSelectNewProps {
  open: boolean
  close: () => void
  onSaveCompleted: (id: number) => void
}

export const ItemSelectNew = ({
  open,
  close,
  onSaveCompleted,
}: ItemSelectNewProps) => {
  const [sending, setSending] = useState<boolean>(false)
  const [form] = Form.useForm<ItemEntry>()
  const [errors, setErrors] = useState<string[]>([])
  const {create, toDto} = useItemData()

  const save = useCallback(async (entry: ItemEntry) => {
    setSending(true)
    const dto = toDto(entry)

    try {
      const result = await create(dto)
      onSaveCompleted(result.id)
      notify.success(
        translate('item'),
        translate('save_success_message')
      )
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
    }
  }, [])

  const onOpen = () => {
    form.resetFields()
    form.setFieldsValue({})
  }

  useEffect(() => {
    onOpen()
  }, [open])
  const obj = useMemo(() => ({ form, save }), [form, save])

  return (
    <Modal
      maskClosable={false}
      title={translate('new', 'item')}
      open={open}
      onOk={form.submit}
      onCancel={close}
      confirmLoading={sending}
      width='70%'
    >
      <ItemPageContext.Provider value={obj}>
        <ErrorMessage
          title={translate('contact_entry_fail_message')}
          message={errors}
        />
        <ItemForm />
      </ItemPageContext.Provider>
    </Modal>
  )
}
