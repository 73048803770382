import type React from 'react'
import { GraphQLErrors } from '@apollo/client/errors'
import { Nullable } from '../../type'
import { ComboOption } from '../Combo'
import { FormInstance } from 'antd'

export type SearchOptions<TRequest> = {
  key: string
  label: string
  render?: (options: { isActive: boolean}) => React.ReactNode
  hideExactMatch?: boolean
  setValue: (params: SearchParameters, request: TRequest) => void
}

export type SearchParameters = {
  key: string
  value: any
  isActive: boolean
  exactMatch?: boolean
}

export type SearchAndFind = {
  id: any
  parameters: SearchParameters[]
  sorts: SortItem[]
}

export type SearchContainerProps<TRequest> = {
  open: boolean
  closeMe: () => void
  onComplete: (request: Nullable<TRequest>, page?: number) => void
  title: string
  idLabel: string
  searchOptions: SearchOptions<TRequest>[]
  findPage: (id: any) => Promise<{ page?: number, error?: GraphQLErrors }>
  findPageLoading: boolean
  sort?: {
    fields: SortField[]
    defaults?: SortItem[]
  }
}

export type SearchProps = {
  closeMe: () => void
  open: boolean
}

export enum SearchMode {
  Find = 'Find',
  Search = 'Search',
}

export type SortDirectionOptions = 'asc' | 'desc'
export type SortField = Omit<ComboOption<any>, 'item'>
export type SortItem = {field: string, direction: SortDirectionOptions}
export interface SortRequest {
  field: string
  sortOrder: SortDirectionOptions
}
export type SortContextValue = {
  fields: SortField[]
  form: FormInstance<SearchAndFind>
}