export const getStoneCode = (name: string) => {
  const [key] = Object.entries(stoneName).find(([_, value]) => value === name) ?? []

  if (!key)
    return ''

  return stoneCode[Number(key)] ?? ''
}


const stoneName: Record<number, string> = {
  1: 'AMETHYST',
  2: 'AQUA',
  3: 'BK TAHITIAN PEARL',
  4: 'BL TOPAZ',
  5: 'CAMEO',
  6: 'CHALCEDONY,DYED BL',
  7: 'CITRINE',
  8: 'DIAMOND',
  9: 'DYED BLACK PEARL',
  10: 'EMERALD',
  11: 'GARNET',
  12: 'IOLITE',
  13: 'JADE',
  14: 'LAPIS',
  15: 'MABE PEARL',
  16: 'MALACHITE',
  17: 'ONYX',
  18: 'OPAL',
  19: 'PEARL',
  20: 'PERIDOT',
  21: 'ROSE QUARTZ',
  22: 'RUBY',
  23: 'SAPPHIRE',
  24: 'SYN EMERALD',
  25: 'SYN SAPPHIRE',
  26: 'SYN STAR RUBY',
  27: 'SYN STAR SAPPHIRE',
  28: 'TANZANITE',
  29: 'TIGER EYE',
  30: 'TOURMALINE,GREEN',
  31: 'TOURMALINE,PINK',
  32: 'TURQUOISE',
  33: 'WH TAHITIAN PEARL',
  34: 'ZIRCON,BLUE',
  35: 'ZIRCON,WHITE',
}

const stoneCode: Record<number, string> = {
  1: 'AM',
  2: 'AQ',
  3: 'BTP',
  4: 'BT',
  5: 'CA',
  6: 'DBC',
  7: 'CI',
  8: 'D',
  9: 'DBP',
  10: 'E',
  11: 'G',
  12: 'IO',
  13: 'JD',
  14: 'LP',
  15: 'MP',
  16: 'MA',
  17: 'ON',
  18: 'OP',
  19: 'PL',
  20: 'PD',
  21: 'RQ',
  22: 'R',
  23: 'S',
  24: 'SE',
  25: 'SS',
  26: 'SSR',
  27: 'SSS',
  28: 'TZ',
  29: 'TE',
  30: 'GT',
  31: 'PT',
  32: 'TQ',
  33: 'WTP',
  34: 'BZ',
  35: 'WZ',
}