import { Col, Descriptions, DescriptionsProps, Row, Space, Table } from 'antd'
import { Appointment } from '../type/appointment'
import { translate } from '../../../core'
import { useMutation, useQuery } from '@apollo/client'
import {
  GetAppointmentQuery,
  GetNewAppointmentCountQuery,
  SetAppointmentAsSeenMutation
} from '../graphql/appointment.graphql'
import React, { useState } from 'react'
import { dateDisplay, dateFormatAndTimeSlashed } from '../../../core/until'
import { InputCard } from '../../inventory/component/InputCard'

export const AppointmentList = () => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data, loading} = useQuery(GetAppointmentQuery, {
    variables: {
      request: {
        take: pageSize,
        skip: currentPage * pageSize,
      }
    }
  })
  const [execute] = useMutation(SetAppointmentAsSeenMutation, {
    refetchQueries: [GetNewAppointmentCountQuery],
  })

  return (
    <InputCard className='content-size'>
      <Table<Appointment>
        loading={loading}
        columns={[
          {
            dataIndex: 'id',
            title: translate('#'),
            width: 50,
          },
          {
            dataIndex: 'createdAt',
            title: translate('created_at'),
            width: 200,
            render: value => dateDisplay(value, dateFormatAndTimeSlashed),
          },
          {
            dataIndex: 'firstName',
            title: translate('first_name'),
            width: 150,
          },
          {
            dataIndex: 'lastName',
            title: translate('last_name'),
            width: 150,
          },
          {
            dataIndex: 'email',
            title: translate('email'),
            width: 150,
          },
        ]}
        dataSource={data?.appointmentsFind.data.map(it => ({key: it.id.toString(), ...it})) ?? []}
        pagination={{
          total: data?.appointmentsFind.count,
          position: ['bottomCenter'],
          pageSize: pageSize,
          showSizeChanger: true,
        }}
        onChange={e => {
          if (e.current)
            setCurrentPage(e.current - 1)

          if (e.pageSize)
            setPageSize(e.pageSize)
        }}
        expandable={{
          onExpand: (expanded, record) => {
            if (!expanded)
              return

            if (record.seen)
              return

            return execute({
              variables: {
                id: record.id,
              }
            })

          },
          expandedRowRender: record =>
            <Descriptions
              layout='vertical'
              bordered
              items={[
                {
                  style: {width: '30%'},
                  key: '1',
                  label: translate('when'),
                  children: record.appointmentDate,
                },
                {
                  key: '2',
                  label: translate('additional_info'),
                  children: record.additionalInfo,
                },
              ]}
            />
        }}
      />
    </InputCard>
  )
}