import { DESIGNER_CONTAINER, VIEWER_CONTAINER } from './constants'
import { reportFileService } from '../../../app/shared/service'
import { notify, translate } from '../../index'
import { HttpRequest } from '../../http.service'
import { ReportVariable } from './type'
import { generateShortGuid } from '../../until'
import { reportConfigure } from '../../../config/Report'

export class Report {
  constructor() {
    // @ts-ignore
    this.stiReport = new Stimulsoft.Report.StiReport()
    this.elementId = generateShortGuid()
  }

  public designer: Designer | undefined
  public viewer: Viewer | undefined
  private readonly elementId: string
  private readonly stiReport: any


  static create(): Report {
    const instance = new Report()
    instance.configure()
    return instance
  }

  static load(fileName: string): Report {
    const instance = Report.create()

    instance.stiReport.loadFile(`${new HttpRequest().baseURL}/report-files/${fileName}`)
    instance.configure()

    return instance
  }

  configure() {
    const config = reportConfigure()
    this.setVariables(config.variables)
  }

  load(filename: string): void {
    this.stiReport.loadFile(`${new HttpRequest().baseURL}/report-files/${filename}`)
  }

  setData(data: any): void {
    const dataContainer = {
      fields: data
    }

    this.stiReport.regData('data', 'data', dataContainer)
    this.stiReport.dictionary.synchronize()
  }

  setVariables(variables: ReportVariable[]): void {
    variables.forEach(e => this.addVariable(e))
    this.stiReport.dictionary.synchronize()
  }

  print() {
    this.stiReport.renderAsync(() => {
      this.stiReport.print()
    })
  }

  createDesigner(): void {
    this.designer = new Designer(this.stiReport)
    this.designer.render(DESIGNER_CONTAINER)
  }

  createViewer(): void {
    this.viewer = new Viewer(this.stiReport)
    this.viewer.render(VIEWER_CONTAINER)
  }

  addVariable(variable: ReportVariable): void {
    // @ts-ignore
    const stiVariable = new Stimulsoft.Report.Dictionary.StiVariable()
    stiVariable.name = variable.name
    stiVariable.alias = variable.alias
    stiVariable.category = variable.category
    stiVariable.value = variable.value
    this.stiReport.dictionary.variables.add(stiVariable)
  }
}

export class Designer {
  constructor(stiReport: any) {
    this.stiReport = stiReport
    // @ts-ignore
    this.stiDesigner = new Stimulsoft.Designer.StiDesigner(null, 'StiDesigner', false)

    this.stiDesigner.report = stiReport
    this.stiDesigner.onSaveReport = (e: any) => {
      e.preventDefault = true
      const filename = e.fileName
      const content = e.report.saveToJsonString()
      reportFileService
        .save({
          filename,
          content
        })
        .then(() => notify.success(translate('report_file'), translate('save_success_message')))
        .catch(() => notify.error(translate('report_file'), translate('wrong_message')))
    }
  }

  private stiDesigner: any
  private stiReport: any

  render(elementId: string): void {
    this.stiDesigner.renderHtml(elementId)
  }
}

export class Viewer {
  constructor(stiReport: any) {
    // @ts-ignore
    this.stiViewer = new Stimulsoft.Viewer.StiViewer(this.viewerConfig(), 'StiViewer', false)
    this.stiViewer.report = stiReport
  }

  render(elementId: string): void {
    this.stiViewer.renderHtml(elementId)
  }

  private stiViewer: any

  private viewerConfig(): any {
    // @ts-ignore
    const config = new Stimulsoft.Viewer.StiViewerOptions()
    config.toolbar.showDesignBotton = false

    // @ts-ignore
    config.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table

    // @ts-ignore
    config.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct

    return config
  }
}

