import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { SignupEntry } from './SignupEntry';
import { notify, translate } from '../../../core';
import { SignupActivation } from './SignupActivation';
import styles from './Auth.module.scss';
import { GoogleButton } from './GoogleButton';
import { Alert } from 'antd';

export const Signup = () => {
  const navigate = useNavigate();
  const [ view, setView ] = useState('entry');
  const [ errorMessage, setErrorMessage ] = useState<string>('');

  const onEntryCompleted = () => {
    setView('activation');
  }

  const onActivationCompleted = () => {
    notify.success(translate('signup'), translate('activation_success_message'));
    navigate('/login');
  }

  return (
    <>
      <h1>{ translate('signup') }</h1>

      <div className="flex-column align-items-center">
        <span>{ translate('create_your_account') }</span>

        <div className="mt-3">
          <GoogleButton/>
        </div>

        {
          errorMessage.length > 0 &&
          <Alert
            className="mt-5"
            message={ translate('signup_fail_message') }
            description={ errorMessage }
            type="error"
            showIcon
          />
        }

        <div className="mt-3">
          {
            view === 'entry'
              ? <SignupEntry onCompleted={ onEntryCompleted } setErrorMessage={ setErrorMessage }/>
              : <SignupActivation onCompleted={ onActivationCompleted } setErrorMessage={ setErrorMessage }/>
          }
        </div>
      </div>


      <div className="text-center">
        <span>{ translate('already_have_an_account') }</span>
        <Link
          className={ styles.marginLeft5 }
          to="/login"
        >
          { translate('log_in') }
        </Link>
      </div>
    </>
  )
}

