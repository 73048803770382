import { AddressType, Contact } from '../../contact/type/contact'
import { Item } from '../../inventory/type'
import { Identity } from '../../../core/type'
import { User } from '../../user/type'

export enum SpouseType {
  Bride = 'Bride',
  Groom = 'Groom'
}

export interface WeddingRegistryAddress {
  type: AddressType
  address1: string
  address2: string
  street: string
  city: string
  state: string
  country: string
  zipCode: string
}

export interface WeddingRegistryLine {
  item: Item
  quantity: number
  quantityTaken: number
  quantityAvailable: number
  description: string
  unitPrice: number
  extendedPrice: number
}

export interface WeddingRegistrySpouse {
  type: SpouseType
  firstName: string
  middleName: string
  lastName: string
  email: string
  homePhone: string
  cellPhone: string
  workPhone: string
}

export interface WeddingRegistry extends Identity {
  createdBy: User
  number: number
  customer: Contact
  orderDate: Date
  weddingDate: Date
  preOrdered: boolean
  totalPrice: number
  showSpecialInstructions: boolean
  showOnWeb: boolean
  spouses: WeddingRegistrySpouse[]
  addresses: WeddingRegistryAddress[]
  lines: WeddingRegistryLine[]
}
