import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { translate } from '../../../core';
import { BadRequestException, CompletionProps, SetErrorMessageProps } from '../../../core/type';
import styles from './Auth.module.scss';
import { ActivateDto } from '../../user/type';
import { userService } from '../../user/service';

export const SignupActivation = ({ onCompleted, setErrorMessage }: SignupActivationProps) => {
  const [ loading, setLoading ] = useState<boolean>(false);

  const onEntry = async ({ code }: ActivateDto) => {
    setErrorMessage('');

    setLoading(true);

    try {
      await userService.activate({ code });
      onCompleted();
    } catch (e) {
      if (e instanceof BadRequestException)
        setErrorMessage(e.message);

    } finally {
      setLoading(false);
    }
  }

  return (
    <Form
      onFinish={ onEntry }
      layout="vertical"
      name="normal_login"
      className="login-form"
      initialValues={ { remember: true } }
    >
      <Form.Item
        label={ translate('code') }
        name="code"
        rules={ [ { required: true, message: translate('code_required_message') } ] }
      >
        <Input size="large"/>
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          loading={ loading }
          className={ styles.submitButton }>
          { translate('activate') }
        </Button>
      </Form.Item>
    </Form>
  )
}

interface SignupActivationProps extends CompletionProps, SetErrorMessageProps {
}
