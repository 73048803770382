import React from 'react'
import UserSvg from '../../../../assets/user.svg'
import styles from './ProtectedLayout.module.scss'
import { translate } from '../../../../core'
import { useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { User } from '../../../user/type'
import { MenuItem } from '../../../../core/type'
import { MenuLink } from '../../../../core/until'

const UserText = () => {
  const {currentUser} = useSelector(s => s) as { currentUser: User }
  return (
    <span style={{fontSize: 16}}>{currentUser.name}</span>
  )
}

const UserIcon = () => <img
  className={styles.userDisplayImage}
  src={UserSvg}
  alt="user"
/>

export const userMenuItem: MenuItem = {
  label: <UserText/>,
  key: '0',
  icon: <UserIcon/>,
  children: [
    {
      label: <MenuLink
        title={translate('profile')}
        to='/profile'/>,
      key: '1',
      icon: <UserOutlined/>,
    },
  ]
}
