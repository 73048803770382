import { ItemCategoryDto } from '../type'
import { httpRequest } from '../../../core'
import { HttpMethod, Identity } from '../../../core/type'
import {
  itemCategoryEntityToEntry,
  itemCategoryEntryToDto,
  itemCategoryInitialize,
} from '../model-mapper/item-category-mapper'

export const useItemCategoryData = () => {
  return {
    init: itemCategoryInitialize,
    toEntry: itemCategoryEntityToEntry,
    toDto: itemCategoryEntryToDto,
    create: (dto: Partial<ItemCategoryDto>) =>
      httpRequest.execute<Identity>({
        method: HttpMethod.POST,
        url: 'inventory/item-categories',
        body: dto,
      }),
    update: (id: number, dto: Partial<ItemCategoryDto>) =>
      httpRequest.execute<void>({
        method: HttpMethod.PUT,
        url: `inventory/item-categories/${id}`,
        body: dto,
      }),
    remove: (id: number) =>
      httpRequest.execute({
        method: HttpMethod.DELETE,
        url: `inventory/item-categories/${id}`,
      }),
  }
}