import { memory } from '../../../core'
import { AUTHENTICATION_TOKEN, LOCAL_RETURN_URL_AFTER_LOGIN } from '../../../App.constants'

export class AuthService {
  persistCurrentURL(): void {
    memory.set(LOCAL_RETURN_URL_AFTER_LOGIN, window.location.pathname)
  }

  resolveReturnURL(): string {
    const returnURL = memory.get<string>(LOCAL_RETURN_URL_AFTER_LOGIN)

    if (!returnURL)
      return '/'

    memory.remove(LOCAL_RETURN_URL_AFTER_LOGIN)

    return returnURL
  }

  logout(forceReload?: boolean): void {
    memory.remove(AUTHENTICATION_TOKEN)

    if (forceReload)
      /* eslint-disable */
      location.reload()
  }
}
