import { Button, Flex, Table } from 'antd'
import { translate } from '../../../core'
import { dateDisplay, dateFormatSlashed } from '../../../core/until'
import { InputCard } from '../../inventory/component/InputCard'
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetOnlineOrders } from '../graphql/order-queries.graphql'
import { Order } from '../type'
import { EyeOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

export const OnlineOrderList = () => {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const {data, loading} = useQuery(GetOnlineOrders, {
    variables: {
      request: {
        take: pageSize,
        skip: currentPage * pageSize,
        sort: [{field: 'date', sortOrder: 'desc'}],
        online: true,
      }
    }
  })
  const navigate = useNavigate()

  return (
    <InputCard className='content-size'>
      <Table<Order>
        loading={loading}
        columns={[
          {
            dataIndex: 'number',
            title: translate('number'),
            width: 200,
          },
          {
            dataIndex: 'date',
            title: translate('date'),
            width: 200,
            render: value => dateDisplay(value, dateFormatSlashed),
          },
          {
            dataIndex: 'number',
            title: '',
            width: '60%',
            render: value => (
              <Flex justify='end'>
                <Button
                  type='text'
                  shape='circle'
                  icon={<EyeOutlined/>}
                  onClick={() => navigate(`/order/${value}`)}
                />
              </Flex>
            )
          }
        ]}
        dataSource={data?.ordersFind.data.map(it => ({key: it.id.toString(), ...it})) ?? []}
        pagination={{
          total: data?.ordersFind.count,
          position: ['bottomCenter'],
          pageSize: pageSize,
          showSizeChanger: true,
        }}
        onChange={e => {
          if (e.current)
            setCurrentPage(e.current - 1)

          if (e.pageSize)
            setPageSize(e.pageSize)
        }}
      />
    </InputCard>
  )
}