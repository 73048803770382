import styles from "./InputCard.module.scss";
import { PropsWithChildren, ReactNode } from "react";

export interface InputCardProps extends PropsWithChildren<{}> {
  title?: ReactNode;
  noborder?: boolean;
  className?: string;
}

export const InputCard = ({ children, title, noborder, className }: InputCardProps) => {
  return (
    <div className={[styles["input-card"], className].join(" ")} data-noborder={noborder}>
      {title && <div className={[styles["input-title"], "bg-gray py-0"].join(" ")}>{title}</div>}
      {children}
    </div>
  );
};
