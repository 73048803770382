import React from 'react';
import { Card, Tooltip } from 'antd';
import { CloseButton } from 'react-bootstrap';
import { translate } from '../translate';

export const Panel = ({ children, onClose, header, className }: FrameProps) => {
  return (
    <Card
      bodyStyle={ { padding: 0 } }
      headStyle={{minHeight: "36px"}}
      className={ className + " bg-gray border-gray"}
      type='inner'
      title={ header }
      extra={
        onClose &&
        <Tooltip title={ translate('close') }>
          <CloseButton onClick={ onClose }/>
        </Tooltip>
      }
    >
      { children }
    </Card>
  )
}

export interface FrameProps {
  header?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
  className?: string
}
