import { Nullable } from '../type';

export const stringConcat = (...values: NullableString[]) => {
  return values.map(v => v || '').join(' ');
}

export const stringEllipsis = (value: string, numberOfChars: number, suffix: string = '...') =>
  `${ value.substring(0, numberOfChars) } ${ suffix }`

const alphabets = [ 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z' ];
export const Alphabet = {
  firstLetter: () => alphabets[0].toUpperCase(),
  nextLetter: (current: string) => {
    const index = alphabets.indexOf(current.toLowerCase());
    const letter = alphabets[index + 1];
    return letter.toUpperCase();
  }
}


export const isNullOrEmpty = (value: Nullable<string> | Nullable<number>): boolean => {
  if (value === undefined)
    return true

  if (value === null)
    return true

  return typeof value === 'string' && value.trim() === ''
}
type NullableString = string | undefined;

export const  generateShortGuid = (): string =>  {
  let firstPart: any = (Math.random() * 46656) | 0
  let secondPart: any = (Math.random() * 46656) | 0
  firstPart = ("000" + firstPart.toString(36)).slice(-3)
  secondPart = ("000" + secondPart.toString(36)).slice(-3)
  return firstPart + secondPart
}

export const toPascalCase = (text: string): string => {
  return `${ text }`
    .toLowerCase()
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${ $2.toUpperCase() + $3 }`
    )
    .replace(new RegExp(/\w/), s => s.toUpperCase());
}

