import { Col, Form, Input, Row } from 'antd'
import { translate } from '../../../core'
import React from 'react'
import styles from './WeddingRegistry.module.scss'
import { InputCard } from '../../inventory/component/InputCard'

export const WeddingRegistryFormSpouse = () => {
  return (
    <InputCard
      title={translate('spouse_2')}
      className={styles['amountsWrapper']}
    >
      <Row gutter={[16, 16]} style={{ width: '1072px', paddingInline: '1rem' }}>
        <Col span={6}>
          <Form.Item
            label={translate('NameFirst')}
            name={['otherSpouse','firstName']}
            className='mb-0'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={translate('Last')}
            name={['otherSpouse','lastName']}
            className='mb-0'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={translate('Phone')}
            name={['otherSpouse','homePhone']}
            className='mb-0'
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={translate('eMail')}
            name={['otherSpouse','email']}
            className='mb-0'
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </InputCard>
  )
}