import { useLazyQuery } from '@apollo/client'
import { init } from '../model-mapper/init'
import { entityToEntry } from '../model-mapper/entity-to-entry'
import { entryToDto } from '../model-mapper/entry-to-dto'
import { HttpMethod, Identity } from '../../../core/type'
import { httpRequest } from '../../../core'
import { AppraisalDto } from '../type'
import {GetAppraisalNewNumber} from '../graphql/appraisal.graphql'

export const useAppraisalData = () => {
  const [ getNewNumber ] = useLazyQuery<{ appraisalNewNumber: number }>(GetAppraisalNewNumber)

  const getNewNumberFunc = async () => {
    const { data } = await getNewNumber()
    return data?.appraisalNewNumber.toString() ?? ''
  }

  return {
    init: () => init(getNewNumberFunc),
    toEntry: entityToEntry,
    toDto: entryToDto,
    create: (dto: AppraisalDto) => httpRequest.execute<Identity>({
      method: HttpMethod.POST,
      url: 'appraisals',
      body: dto,
    }),
    update: (id: number, dto: AppraisalDto) => httpRequest.execute<void>({
      method: HttpMethod.PUT,
      url: `appraisals/${ id }`,
      body: dto
    }),
    delete: (id: number) => httpRequest.execute<void>({
      method: HttpMethod.DELETE,
      url: `appraisals/${ id }`,
    }),
    deleteRelation: (id: number) => httpRequest.execute<void>({
      method: HttpMethod.DELETE,
      url: `sales/contacts/${ id }/relations`,
    }),
  }
}
