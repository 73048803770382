import { ReportArgs, ReportViewState } from './type'
import { useDispatch } from 'react-redux'
import { setReportViewState } from '../../../store/redux/reducer'
import { Report } from './report'

export const useReport = () => {
  const dispatch = useDispatch()

  const handleShowViewer = (args: ReportArgs) => {
    const state: ReportViewState = {
      fileName: args.fileName,
      loading: !!args.loading,
      data: args.data,
      title: args.title,
      open: true,
    }
    dispatch(setReportViewState(state))
  }

  const handleShowBrowserPreview = (args: ReportArgs) => {
    const report = Report.load(args.fileName)
    report.setData(args.data)
    report.print()
  }

  return {
    show: process.env.REACT_APP_NODE_ENV === 'development'
      ? handleShowViewer
      : handleShowBrowserPreview,
    print: handleShowBrowserPreview,
  }
}
