import { translate } from '../../../core'
import { Section } from '../../../core/componet'
import { Tabs, TabsProps } from 'antd'
import { ItemCategoryList } from './ItemCategoryList'

export const InventorySettingsContent = () => {
  const items: TabsProps['items'] = [
    {
      label: translate('item', 'category'),
      key: 'item_category',
      children: <ItemCategoryList/>
    }
  ]

  return (
    <Section noMargin>
      <Tabs
        defaultActiveKey='item_category'
        items={ items }
      />
    </Section>
  )
}
