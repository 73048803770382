import { ContactEntry, ContactType } from "../type/contact-entry";
import { ContactDto } from "../type/contact-dto";

export const contactEntryToDto = (entry: ContactEntry): ContactDto => {
  entry.phones = entry.phones || [];
  entry.addresses = entry.addresses || [];
  entry.webInfos = entry.webInfos || [];
  entry.relations = entry.relations || [];

  return {
    gender: entry.isBride ? "Male" : "Female",
    isCustomer: entry.contactType === ContactType.Customer,
    isVendor: entry.contactType === ContactType.Vendor,
    noEmailMarketing: !!entry.noEmailMarketing,
    allowContact: !!entry.allowContact,
    contactId: entry.contactId,
    company: entry.company,
    designer: entry.designer,
    firstName: entry.firstName,
    lastName: entry.lastName,
    note: entry.note,
    dateOfBirth: entry.dateOfBirth,
    anniversary: entry.anniversary,
    accountNumber: entry.accountNumber,
    markupNumber: entry.markupNumber,
    promotion: entry.promotion,
    percentOff: entry.percentOff,
    groupId: entry.group ? entry.group.id : null,
    categoryId: entry.category ? entry.category.id : null,
    phones: entry.phones.map((e) => ({
      type: e.type,
      countryCode: e.countryCode,
      number: e.number,
      extension: e.extension,
    })),
    addresses: entry.addresses.map((e) => ({
      type: e.type,
      country: e.country,
      state: e.state,
      city: e.city,
      street: e.street,
      zipCode: e.zipCode,
      line1: e.line1,
      line2: e.line2,
    })),
    webInfos: entry.webInfos.map((e) => ({
      type: e.type,
      value: e.value,
    })),
    relations: entry.relations
      .filter((e) => !e.id)
      .map((e) => ({
        contactId: e.contactId,
        firstName: e.firstName,
        lastName: e.lastName,
        city: e.city,
        phone: e.phone,
      })),
  };
};
