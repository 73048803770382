import { AutoComplete as AntAutoComplete } from 'antd'
import React, { useState } from 'react'

export const AutoComplete = ({ items, onChange, value, placeholder, style, onBlur }: AutoCompleteProps) => {
  const [ options, setOptions ] = useState<{ value: string }[]>([])

  const doSearch = (text: string) => {
    const searchItems = items.filter(e => e.toLowerCase().includes(text.toLowerCase()))
    setOptions(searchItems.map(value => ({ value })))
  }

  return (
    <AntAutoComplete
      options={ options }
      style={ style }
      onChange={ onChange }
      value={ value }
      onSearch={ doSearch }
      placeholder={ placeholder }
      onBlur={ ((e: { target: { value: string } }) => onBlur && onBlur(e.target.value)) as any  }
    />
  )
}

export interface AutoCompleteProps {
  items: string[]
  style?: React.CSSProperties
  onChange?: (value: string) => void
  value?: string | null | undefined
  placeholder?: string
  onBlur?: (value: string | any) => void
}