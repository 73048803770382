import { Button, message, Tooltip } from 'antd'
import { translate } from '../translate'
import { CopyOutlined } from '@ant-design/icons'
import { CopyToClipboard as CopyToClipboardBaseComponent } from 'react-copy-to-clipboard'

export type CopyToClipboardProps = {
  text: string
  tooltip?: string
}

export const CopyToClipboard = ({text, tooltip}: CopyToClipboardProps) => {
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      <CopyToClipboardBaseComponent
        text={text}
        onCopy={(text, result) => result && text && messageApi.success(translate(text, 'copied'))}
      >
        <Tooltip title={tooltip}>
          <Button
            type='text'
            shape='circle'
            icon={<CopyOutlined/>}
          />
        </Tooltip>
      </CopyToClipboardBaseComponent>
      {contextHolder}
    </>
  )
}