import React from 'react';
import { Space, Spin } from 'antd';

export const Container = (props: ContainerProps) => {
  const { loading, children, paddingClass, innerClassName, leftClassName } = props;

  return (
    <div className={ ` ${ paddingClass } ` }>
      <div>
        <Spin spinning={ loading ?? false }>
          <>
            <div className='d-flex flex-row bg-gray rounded px-2'>
              {
                props.toolbarItems?.start?.length && (
                  <div className={`d-flex flex-row align-items-center justify-content-start flex-wrap w-75 ${leftClassName}`}>
                    <Space wrap>
                      { props.toolbarItems.start.map(component => (<>{ component }</>)) }
                    </Space>
                  </div>
                )

              }

              {
                props.toolbarItems?.end?.length && (
                  <div className='d-block flex-row align-items-center justify-content-end'>
                    <Space wrap>
                      { props.toolbarItems.end.map(component => (<>{ component }</>)) }
                    </Space>
                  </div>
                )

              }
            </div>
            <div className={ innerClassName }>
              { children }
            </div>
          </>
        </Spin>
      </div>
    </div>
  )
}

export interface ContainerProps {
  children: React.ReactNode;
  loading?: boolean;
  paddingClass?: string;
  innerClassName?: string;
  toolbarItems?: {
    start?: React.ReactNode[],
    end?: React.ReactNode[]
  };
  leftClassName?:string;
}
