import { Item, ItemComponent, ItemComponentParent } from '../type'
import { toMoment } from '../../../core/until'

export const itemInitializeEntity = async (getSkuNumber: () => Promise<string>): Promise<Item> => {
  return {
    sku: await getSkuNumber(),
    showOnline: false,
    parents: [] as Array<ItemComponentParent>,
    components: [] as Array<ItemComponent>,
    dateOfPurchase: toMoment(new Date()),
  } as Item
}

