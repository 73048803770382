export const chunkArray = (array: any[], chunkSize: number, defaultValue?: any) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        if (defaultValue && chunk.length < chunkSize) {
            while (chunk.length < chunkSize) {
                chunk.push(defaultValue);
            }
        }
        result.push(chunk);
    }
    return result;
}

export const chunkArrayWithSize = (array: any[], chunkSize: number, totalSize:number, defaultValue : any) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);

            while (chunk.length < totalSize) {
                chunk.push(defaultValue);
            }

        result.push(chunk);
    }
    return result;
}

export const maskNumber =(input: number | string)=> {
    let strInput = input.toString();
    if (strInput.includes('-')) {
        let parts = strInput.split('-');
        strInput = parts.join('');
    }
    if(/\d/.test(strInput)) {
        let len = strInput.length;
        let maskLength = len - 4;
        let maskPattern = '*'.repeat(maskLength);
        return maskPattern + strInput.slice(-4);
    }
    else {
        return "";
    }
}


export const formatPhoneNumber = (phoneNumber: string)=> {
    phoneNumber = phoneNumber.replace(/\D/g, '');
    if (phoneNumber === "") {
        return ''
    }
    if (phoneNumber.length !== 10) {
        return 'Invalid phone number'
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}
