export const loadScripts = async (setLoading: (value: boolean) => void) => {
  if (window.hasOwnProperty('Stimulsoft'))
    return;

  setLoading(true);

  const scripts = [
    'stimulsoft.reports',
    'stimulsoft.viewer',
  ];

  if (process.env.REACT_APP_NODE_ENV === 'development')
    scripts.push('stimulsoft.designer');

  for (const script of scripts)
    await createScript(script);

  // @ts-ignore
  Stimulsoft.Base.StiLicense.Key = process.env.REACT_APP_STIMULSOFT_LICENSE_KEY;

  setLoading(false);
}

const createScript = (filename: string) => {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `/stimulsoft/${ filename }.js`;
    script.onload = () => resolve(null);
    const head = document.getElementsByTagName('head')[0];
    head.append(script);
  })
}
