import { translate } from '../../../core';
import { Button, Form, Input } from 'antd';
import styles from './Auth.module.scss';
import { BadRequestException, CompletionProps, SetErrorMessageProps } from '../../../core/type';
import { useState } from 'react';
import { ForgotPasswordDto } from '../../user/type';
import { userService } from '../../user/service';

export const ResetPasswordEmailEntry = ({ onCompleted, setErrorMessage }: ResetPasswordEmailEntryProps) => {
  const [ loading, setLoading ] = useState<boolean>(false);

  const onEnter = async (data: ForgotPasswordDto) => {
    setLoading(true);

    try {
      await userService.forgotPassword(data);
      onCompleted();
    } catch (e) {
      if (e instanceof BadRequestException)
        setErrorMessage(e.message);

    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        onFinish={ onEnter }
        layout="vertical"
        name="normal_login"
        className="login-form"
        initialValues={ { remember: true } }
      >
        <Form.Item
          label={ translate('email') }
          name="email"
          rules={ [ { required: true, message: translate('email_required_message') } ] }
        >
          <Input size="large"/>
        </Form.Item>

        <Form.Item>
          <Button
            loading={ loading }
            block
            size="large"
            htmlType="submit"
            className={ styles.submitButton }>
            { translate('next') }
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

interface ResetPasswordEmailEntryProps extends CompletionProps, SetErrorMessageProps {
}
