import { Appraisal, AppraisalEntry } from '../type'
import { toMoment } from '../../../core/until'
import { UploadingStatus } from '../../inventory/type'

export const entityToEntry = (entity: Appraisal): AppraisalEntry => {
  return {
    ...entity,
    id: entity.id,
    number: entity.number,
    appraiserId: entity.appraiser?.id || null,
    date: toMoment(entity.date),
    numberOfItems: entity.numberOfItems,
    customerKey: entity.customerKey,
    customerFirstname: entity.customerFirstname,
    customerLastname: entity.customerLastname,
    customerEmail: entity.customerEmail,
    customerNumber: entity.customerNumber,
    customerAddress: entity.customerAddress,
    lines: entity.lines.map(e => ({
      key: e.key,
      item: e.item,
      amount: e.amount,
      description: e.description,
      image: e.image
        ? {
          status: UploadingStatus.Uploaded,
          name: '',
          url: e.image,
          item: {
            filename: e.image
          }
        }
        : null
    }))
  }
}
