import { notify, translate } from '../../../core'
import { Form, Modal } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { ContactForm } from './ContactForm'
import { ContactPageContext } from './ContactPageContext'
import { ContactEntry, ContactType } from '../type/contact-entry'
import { BadRequestException, PageContextValue } from '../../../core/type'
import { ErrorMessage, ShowIf } from '../../../core/componet'
import { useContactData } from '../hook/contact-data.hook'
import { Contact } from '../type/contact'

export const ContactModal = ({open, close, onSaveCompleted, entity, isReadOnly}: ContactModalProps) => {
  const [sending, setSending] = useState<boolean>(false)
  const [form] = Form.useForm<ContactEntry>()
  const [errors, setErrors] = useState<string[]>([])
  const {create, toDto, toEntry} = useContactData()

  const save = async (entry: ContactEntry) => {
    setSending(true)
    const dto = toDto(entry)

    try {
      const result = await create(dto)
      onSaveCompleted(result.id)
      notify.success(translate("contact"), translate("save_success_message"))
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
    }
  }

  const onOpen = () => {
    form.resetFields()

    const data = entity ? toEntry(entity) : {} as ContactEntry
    form.setFieldsValue({
      ...data,
      contactType: ContactType.Customer,
    })
  }

  useEffect(() => {
    onOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, entity])

  const contextValue = useMemo<PageContextValue<Contact, ContactEntry>>(() => ({
    form, save, onFormChange: () => {
    }
  }), [form, save])

  return (
    <Modal
      title={translate('new', 'customer')}
      open={open}
      onOk={form.submit}
      onCancel={close}
      confirmLoading={sending}
      width='60%'
      footer={isReadOnly ? [] : undefined}
      maskClosable={false}
    >
      <ContactPageContext.Provider value={contextValue}>
        <ShowIf condition={!isReadOnly} replacement={<></>}>
          <ErrorMessage
            title={translate('contact_entry_fail_message')}
            message={errors}
          />
        </ShowIf>
        <ContactForm contactTypeDisabled={!isReadOnly}/>
      </ContactPageContext.Provider>
    </Modal>
  )
}

interface ContactModalProps {
  open: boolean
  close: () => void
  onSaveCompleted: (id: number) => void
  isReadOnly?: boolean
  entity?: Contact
}
