import Enumerable from 'linq'
import { Order, OrderAddress, OrderEntry, OrderLineEntry, OrderRepair } from '../type'
import { resolveBoolean, resolveNumber, round, toMoment, } from '../../../core/until'
import { AddressType, WebInfoType } from '../../contact/type/contact'
import { DEFAULT_TAX_VALUE } from '../../../App.constants'
import { Item } from '../../inventory/type'

export const orderMapEntityToEntry = (entity: Order): OrderEntry => {
  const entry: OrderEntry = {
    ...entity,
    lines: entity.lines?.map<OrderLineEntry>((line) => {
      const lineEntry = {...line} as OrderLineEntry
      mapEntryLine(lineEntry, entity.taxRate)
      return lineEntry
    }),
    total: 0,
    taxRate: entity.taxRate || DEFAULT_TAX_VALUE,
    tax: entity.tax,
    date: toMoment(entity.date),
    repairs: entity.repairs
      ? entity.repairs.map((e) => ({
        ...e,
        completionDate: toMoment(e.completionDate),
        pickDate: toMoment(e.pickDate),
        expectedDate: toMoment(e.expectedDate),
      }))
      : [],
    payments: entity.payments
      ? entity.payments.map((e) => ({
        ...e,
        date: toMoment(e.date),
      }))
      : [],
    customerEmail:
      entity.customer?.webInfos?.find(
        (info) => info.type === WebInfoType.EmailAddress
      )?.value || '',
    singleRepair: null,
  }
  resolveAddresses(entry, entity.addresses)
  mapEntry(entry, entity.taxRate)
  return entry as OrderEntry
}

export const mapEntryLine = (line: OrderLineEntry, taxRate: number) => {
  line.sku = line.item?.sku
  line.extended = resolveNumber(line.quantity) * resolveNumber(line.unitPrice)
  line.tax = line.taxable ? (line.extended * taxRate) / 100 : 0
  line.onHandQuantity = getOnHandQuantity(line.item)

  line.taxable = resolveBoolean(line.taxable)
}

export const mapEntry = (entry: OrderEntry, taxRate: number) => {
  const {lines} = entry
  lines.forEach(ln => mapEntryLine(ln, taxRate))
  entry.tax = Enumerable.from(lines)
    .where((e) => !!e.taxable)
    .sum(
      (e) =>
        (resolveNumber(e.quantity) * resolveNumber(e.unitPrice) * taxRate) / 100
    )
  const freight = resolveNumber(entry.freight)
  entry.totalPrice = Enumerable.from(lines).sum((e) => e.extended)
  entry.total = entry.totalPrice + entry.tax + freight
  entry.totalPay = Enumerable.from(entry.payments).sum((e) =>
    resolveNumber(e.amount)
  )
  entry.balanceDue = round(entry.total - entry.totalPay)
}

export const mapRepair = (entry: OrderEntry, repairs: OrderRepair[]) => {
  entry.singleRepair = repairs?.length === 1 ? repairs[0] : null
}

const resolveAddresses = (entry: OrderEntry, addresses: OrderAddress[]) => {
  const defaultAddresses = [
    AddressType.Billing,
    AddressType.Shipping,
  ].map<OrderAddress>((type) => ({type} as OrderAddress))

  if (!addresses || addresses.length === 0) {
    entry.addresses = defaultAddresses
    return
  }

  if (addresses.length === 1) {
    entry.addresses = [
      ...addresses,
      ...defaultAddresses.filter((e) => e.type === AddressType.Shipping),
    ]
    return
  }

  return addresses
}

export const getOnHandQuantity = (item?: Item) => {
  return resolveNumber(item?.quantity?.onHand, {changeNegativeToZero: true}) +
  resolveNumber(item?.quantity?.display, {changeNegativeToZero: true})
}