import axios, { AxiosRequestConfig } from 'axios'

export type PrintArgs<TVariables> = {
  template_name: 'repair' | 'tableware' | 'jewelry',
  variables: TVariables
}

export type PrintRepairVariables = {
  title: string
  twoCharacters: string
  shortDescription: string
  customerName: string
  address: string
  homeNumber: string
  cellNumber: string
  workNumber: string
  altNumber: string
  date?: string
  expectedDate: string
  item: string
  work: string
  specialInstruction: string
  relation: string
}

export type PrintJewelryVariables = {
  firstText: string
  secondText: string
  thirdText: string
  fourthText: string
  fifthText: string
  sixthText: string
  barcode: string
}

export type PrintTablewareVariables = {
  firstText: string
  secondText: string
  thirdText: string
  fourthText: string
  barcode: string
}

const escapeCharacters = (inputString: string) => {
  let escapedString = inputString.replace(/\//g, '\\')  // Replace \ with \
  escapedString = escapedString.replace(/'/g, `\\'`)      // Replace ' with '
  escapedString = escapedString.replace(/"/g, `\\"`)      // Replace ' with '
  escapedString = escapedString.replace(/'/g, '\'')      // Replace ' with '
  escapedString = escapedString.replace(/\//g, '//')    // Replace / with //
  escapedString = escapedString.replace(/\n/g, '\n')     // Replace newline with \n
  escapedString = escapedString.replace(/\r/g, '\r')     // Replace carriage return with \r
  escapedString = escapedString.replace(/\t/g, '\t')     // Replace tab with \t
  return escapedString
}

export const usePrinter = () => {
  return {
    print: <TVariables extends Record<string, string>>(args: PrintArgs<TVariables>) => {
      console.log('[LOCAL-PRINTER-DATA]', args)

      const encodedVariables: Record<string, string> = {}
      Object.entries(args.variables).forEach(([key, value]) => {
        if (typeof value !== 'string')
          return
        encodedVariables[key] = escapeCharacters(value)
      })

      console.log('[LOCAL-PRINTER-DATA-ENCODED]', encodedVariables)

      const config: AxiosRequestConfig<PrintArgs<TVariables>> = {
        method: 'post',
        url: `${process.env.REACT_APP_LOCAL_PRINTER_URL}/print-request-usb`,
        data: {
          template_name: args.template_name,
          variables: encodedVariables as TVariables,
        },
      }
      return axios(config)
    }
  }
}