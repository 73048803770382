import { Nullable } from '../../../core/type'

const mapping: Record<string, string> = {
  round: 'RB',
  oval: 'OV',
  marquise: 'MQ',
  baguette: 'BA',
  pear: 'PS',
  heart: 'HE',
  triangle: 'TR',
  radiant: 'RA',
  old_mine: 'OM',
  european: 'EU',
  cushion: 'CU',
  trapezoid: 'TZ',
  Emerald : 'EM',
  Princess : 'PR',
  Asscher : 'AS',
}

export default (shape: Nullable<string>) => {
  if (!shape)
    return ''

  const term = shape.replace(/\s+/g, '_').toLowerCase()
  const [[_, value]] = Object.entries(mapping).filter(([key]) => key === term)

  return value ?? ''
}