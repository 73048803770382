import { Segmented } from 'antd'
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import { SortDirectionOptions } from './type'
import { translate } from '../../translate'

export type SortDirectionProps = {
  value?: SortDirectionOptions
  onChange?: (value: SortDirectionOptions) => void
}

export const SortDirection = ({value, onChange}: SortDirectionProps) => {
  return (
    <Segmented
      value={value}
      options={[
        {
          value: 'asc',
          title: translate('ascending_abbr'),
          icon: <SortAscendingOutlined style={{fontSize: 20}}/>,
        },
        {
          value: 'desc',
          title: translate('descending_abbr'),
          icon: <SortDescendingOutlined style={{fontSize: 20}}/>,
        },
      ]}
      onChange={value => onChange!(value as SortDirectionOptions)}
    />
  )
}