import { Contact } from "./contact";

export enum ContactType {
  Customer = "Customer",
  Vendor = "Vendor",
}

export type ContactEditable = Contact & {
  contactType?: ContactType;
  relationsEntry: {
    id: number;
    contactId: string;
    firstName: string;
    lastName: string;
    city: string;
    phone: string;
  }[];
};

export interface ContactEntry extends Omit<Contact, "relations" | "isCustomer" | "isVendor"> {
  contactType?: ContactType;
  relations: ContactRelationEntry[];
  isBride?: boolean;
}

interface ContactRelationEntry {
  id: number;
  contactId: string;
  firstName: string;
  lastName: string;
  city: string;
  phone: string;
  street: string
  line1: string
  line2: string
}
