import { ContactGroup, ContactGroupDto, ContactGroupEntry } from '../type/contact-group'

export const contactGroupEntityToEntry = (entity: ContactGroup): ContactGroupEntry => {
  return {
    id: entity.id,
    title: entity.title,
    key: entity.id.toString()
  } as ContactGroupEntry
}

export const contactGroupEntryToDto = (entry: ContactGroupEntry): ContactGroupDto => {
  return {
    title: entry.title
  }
}
