import { ReportConfiguration, ReportVariableCategory } from '../core/componet/Report'
import { dateDisplay, dateFormatSlashed } from '../core/until'

export const reportConfigure = (): ReportConfiguration => {
  return {
    variables: [
      {
        name: 'apiUrl',
        alias: 'apiUrl',
        value: process.env.REACT_APP_API_BASE_URL,
        category: ReportVariableCategory.General,
      },
      {
        name: 'today',
        alias: 'today',
        value: dateDisplay(new Date(), dateFormatSlashed),
        category: ReportVariableCategory.General,
      },
      {
        name: 'companyLogo',
        alias: 'CompanyLogo',
        value: 'logo.svg',
        category: ReportVariableCategory.General
      },
    ]
  }
}