import { translate } from '../../../core'
import { Alert, Button, Checkbox, Form, Input } from 'antd'
import styles from './Auth.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { authService } from '../service'
import { useState } from 'react'
import { UnauthorizedException } from '../../../core/type'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from '../../../store/redux/reducer'
import { GoogleButton } from './GoogleButton'
import {LoginDto, User} from '../../user/type'
import { userService } from '../../user/service'
import { useLazyQuery } from '@apollo/client'
import {GetUserAuthenticated} from '../../user/graphql/user-queries.graphql'

export const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ errorMessage, setErrorMessage ] = useState<string>('')
  const [ getUserAuthenticated ] = useLazyQuery<{userAuthenticated: User}>(GetUserAuthenticated)

  const onLogin = async ({ email, password }: LoginDto) => {
    setLoading(true)

    try {
      await userService.login({ email, password })

      const { data } = await getUserAuthenticated()
      const authenticatedUser = data?.userAuthenticated || {} as User
      dispatch(setCurrentUser(authenticatedUser))

      navigate(authService.resolveReturnURL())
    } catch (e) {
      if (e instanceof UnauthorizedException)
        setErrorMessage(translate('invalid_email_or_password'))

    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex-column align-items-center">
      <h1>{ translate('log_in') }</h1>
      <span>{ translate('welcome_back') }</span>

      <div className="mt-3">
        <GoogleButton/>
      </div>

      {
        errorMessage.length > 0 &&
        <Alert
          className="mt-5"
          message={ translate('login_fail_message') }
          description={ errorMessage }
          type="error"
          showIcon
        />
      }

      <div className="mt-3">
        <Form
          onFinish={ onLogin }
          layout="vertical"
          name="normal_login"
          className="login-form"
          initialValues={ { remember: true } }
        >
          <Form.Item
            label={ translate('email') }
            name="email"
            rules={ [ { required: true, message: translate('email_required_message') }, {
              type: 'email',
              message: translate('email_invalid_message')
            } ] }
          >
            <Input size="large"/>
          </Form.Item>
          <Form.Item
            label={ translate('password') }
            name="password"
            rules={ [ { required: true, message: translate('password_required_message') } ] }
          >
            <Input size="large"
                   type="password"
            />
          </Form.Item>
          <Form.Item>
            <div className="d-flex flex-row">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{ translate('remember_me') }</Checkbox>
              </Form.Item>

              <Link
                className="login-form-forgot float-end"
                to="/reset-password"
              >
                { translate('forgot_password') }
              </Link>
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              block
              size="large"
              htmlType="submit"
              loading={ loading }
              className={ styles.submitButton }>
              { translate('log_in') }
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div className="text-center">
        <span>{ translate('dont_have_an_account') }</span>
        <Link to="/signup" className={ styles.marginLeft5 }>
          { translate('signup') }
        </Link>
      </div>
    </div>
  )
}
