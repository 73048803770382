import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { translate } from '../../../core'
import { Uploader } from '../../../core/componet/Upload'
import { DeleteOutlined, PlusOutlined, } from '@ant-design/icons'
import { PageContextValue } from '../../../core/type'
import { Appraisal, AppraisalEntry } from '../type'
import { AppraisalPageContext } from './AppraisalPageContext'
import { NumericInput, SinglePageable, SinglePageableResponse } from '../../../core/componet'
import { AppraisalFormLinesItem } from './AppraisalFormLinesItem'
import { AppraisalLinesSlider } from './AppraisalLinesSlider'

type AppraisalFormLinesProps = { onChanged: () => void }

export const AppraisalFormLines = ({onChanged}: AppraisalFormLinesProps) => {
  const [linesLength, setLinesLength] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const {entity, form} =
    useContext<PageContextValue<Appraisal, AppraisalEntry>>(AppraisalPageContext)
  const [currentRowIndex, setCurrentRowIndex] = useState<number>()

  const resetLinesLength = (targetPage: 'first' | 'last') => {
    const lines: any[] = form?.getFieldValue('lines')
    const length = lines?.length || 0
    setLinesLength(length)

    let thisPage = 0

    if (length > 0) {
      thisPage = targetPage === 'first'
        ? 1
        : length
    }
    setPage(0)
    setTimeout(() => setPage(thisPage), 500)
    onChanged?.()
  }

  useEffect(() => {
    setTimeout(() => resetLinesLength('first'), 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const onChangeSlideChange = async (_: unknown, page: number): Promise<SinglePageableResponse<any>> => {
    if (!linesLength)
      return {count: linesLength}

    setCurrentRowIndex(page - 1)

    return {
      count: linesLength
    }
  }

  return (
    <Form.List name='lines'>
      {(fields, {add, remove}) => (
        <>
          <div className='mt-2 d-flex flex-row'>
            <div className='w-75 d-flex flex-row'>
              <Button
                onClick={() => {
                  add({})
                  resetLinesLength('last')
                }}
                icon={<PlusOutlined/>}
              >
                {translate('add', 'item')}
              </Button>

              <Button
                disabled={!linesLength}
                className='ms-1'
                danger
                onClick={() => {
                  currentRowIndex && remove(currentRowIndex)
                  resetLinesLength('first')
                }}
                icon={<DeleteOutlined/>}
              >
                {translate('remove', 'item')}
              </Button>
            </div>

            <div className='w-25 d-flex flex-row justify-content-end'>
              <SinglePageable
                disabled={linesLength === 0}
                onChange={onChangeSlideChange}
                defaultPage={page}
                size='small'
                rowsCaption={translate('items')}
                hideLastButton
                hideFirstButton
              />
            </div>
          </div>

          <AppraisalLinesSlider
            items={fields}
            renderItem={({name: row}) => <Row gutter={[10, 10]}>
              <Col span={18}>
                <Row gutter={[10, 10]}>
                  <Col span={9} style={{paddingLeft: '1.8rem'}}>
                    <Form.Item
                      label={translate('item', 'number_abbr')}
                      name={[row, 'item', 'id']}
                    >
                      <AppraisalFormLinesItem
                        row={row}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label={translate('number_abbr')}>
                      <Input disabled={true} value={row + 1}/>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Space>
                      <Form.Item label={translate('of')}>
                        <Input disabled={true} value={fields.length}/>
                      </Form.Item>
                    </Space>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      label={translate('amount')}
                      name={[row, 'amount']}
                    >
                      <NumericInput prefix='$'/>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Form.Item
                      label={translate('description')}
                      name={[row, 'description']}
                    >
                      <Input.TextArea rows={5}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Form.Item noStyle name={[row, 'image']}>
                  <Uploader/>
                </Form.Item>
              </Col>
            </Row>
            }
            currentIndex={currentRowIndex}
          />
        </>
      )}
    </Form.List>
  )
}


