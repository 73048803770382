import React from 'react'
import { AppRoutes } from './config/Routes'
import { Provider } from 'react-redux'
import { store } from './store'
import { ApolloProvider } from '@apollo/client'
import { client } from './core/query'

function App() {
  return (
    <>
      <ApolloProvider client={ client }>
        <Provider store={ store }>
          <AppRoutes/>
        </Provider>
      </ApolloProvider>
    </>
  )
}

export default App
