import { MenuItem } from '../type';
import { Link } from 'react-router-dom';
import React from 'react';

export function resolveMenuItem(item: MenuItem) {
  return (
    <>
      {
        item.path
          ? <MenuLink
            title={item.label}
            to={item.path}
          />
          : <span>{item.label}</span>
      }
    </>
  )
}

export const MenuLink = ({title, to}: { title: React.ReactNode, to: string }) =>
  <Link to={to} style={{textDecoration: 'none'}}>{title}</Link>
