import { contactCategoryService } from '../service'
import { Button, Divider, Input, Space } from 'antd'
import { Combo } from '../../../core/componet'
import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { FormEntryField, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { useLazyQuery } from '@apollo/client'
import { ContactCategory } from '../type/contact-category'
import { GetContactCategoriesLookup } from '../graphql/contact-category-queries.graphql'
import { ContactCategoryFindRequest } from '../type/contact-category.query'

export const ContactFormCategory = ({ onChange, value }: FormEntryField<number>) => {
  const [ title, setTitle ] = useState<string>()
  const [ saving, setSaving ] = useState<boolean>(false)
  const [ find ] = useLazyQuery<
    QueryPageableResponse<ContactCategory, 'contactCategoriesFind'>,
    QueryPageableRequest<ContactCategoryFindRequest>>(GetContactCategoriesLookup)

  return (
    <Combo<ContactCategory, number>
      value={ value }
      onChange={ (value) => onChange!(value as number) }
      fetch={ async ({ request, value, search }) => {
        const { data } = await find({
          variables: {
            request: {
              take: request?.take ?? 100,
              skip: request?.skip ?? 0,
              title: search,
              id: value
            }
          }
        })

        const list = data?.contactCategoriesFind.data ?? []

        return list.map(e => ({
          value: e.id || 0,
          display: e.title || '',
          item: e || {}
        }))
      } }
      dropdownRender={ menu => (
        <>
          <Space style={ { padding: '0 8px 4px' } }>
            <Input
              value={ title }
              onChange={ e => setTitle(e.target.value) }
              placeholder="Please enter item"
            />
            <Button
              disabled={ !(title?.length && title.length >= 3) }
              type="text"
              icon={ <PlusOutlined/> }
              loading={ saving }
              onClick={ async () => {
                setSaving(true)
                const { id } = await contactCategoryService.create({ title })
                onChange?.(id)
                setTitle(undefined)
                setSaving(false)
              } }
            >
              Add item
            </Button>
          </Space>
          <Divider style={ { margin: '8px 0' } }/>
          { menu }
        </>
      ) }

    />
  )
}

export interface ContactCategoryEntryProps {
  onChange?: (value: number) => void
  value?: number
}
