import { Memory } from './memory.service';
import { HttpRequest } from './http.service';
import { Notify } from './notify.service';

export * from './translate';
export * from './confirm.service';

export const memory = new Memory();
export const httpRequest = new HttpRequest();
export const notify = new Notify();
