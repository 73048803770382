import { ItemEntry, RelationType } from '../type';
import { ItemDto } from '../type/item-dto';
import { resolveNumber } from '../../../core/until'

export const itemEntryToDto = (entry: ItemEntry): ItemDto => {
  const data: ItemDto = {
    ...entry,
    sku: entry.sku.toString(),
    components: entry.relations?.filter(e => e.type === RelationType.Child || !e.type)
      .map(e => ({ id: e.item.id, quantity: Number(e.quantity) })),
    categoryId: entry.category?.categoryId,
    subcategoryId: entry.category?.subcategoryId,
    subcategory2Id: entry.category?.subcategory2Id,
  }

  // @ts-ignore
  delete data['category']

  const {quantity, sales} = entry

  data.quantity = {
    onHand: resolveNumber(quantity?.onHand),
    display: resolveNumber(quantity?.display),
    onOrder: resolveNumber(quantity?.onOrder),
    backOrder: resolveNumber(quantity?.backOrder),
    inSet: resolveNumber(quantity?.inSet),
    committed: resolveNumber(quantity?.committed),
    min: resolveNumber(quantity?.min),
    max: resolveNumber(quantity?.max),
  }

  data.costPrice = resolveNumber(entry.costPrice)
  data.listPrice = resolveNumber(entry.listPrice)
  data.oursPrice = resolveNumber(entry.oursPrice)
  data.costSale = resolveNumber(entry.costSale)
  data.listSale = resolveNumber(entry.listSale)
  data.oursSale = resolveNumber(entry.oursSale)

  data.sales = {
    yearToDateQuantity: resolveNumber(sales?.yearToDateQuantity),
    yearToDateAmount: resolveNumber(sales?.yearToDateAmount),
    lastYearQuantity: resolveNumber(sales?.lastYearQuantity),
    lastYearAmount: resolveNumber(sales?.lastYearAmount),
  }

  if (entry.jewelery) {
    data.jewelery.weightGR = resolveNumber(entry.jewelery.weightGR)
    data.jewelery.cost = resolveNumber(entry.jewelery.cost)
    data.jewelery.laborCost = resolveNumber(entry.jewelery.laborCost)
    data.jewelery.width = resolveNumber(entry.jewelery.width)
    data.jewelery.length = resolveNumber(entry.jewelery.length)
    data.jewelery.height = resolveNumber(entry.jewelery.height)
    data.jewelery.fingerSize = resolveNumber(entry.jewelery.fingerSize)

    data.jewelery.stones = entry.jewelery?.stones?.length
      ? entry.jewelery.stones.map(st => ({
        ...st,
        shape: Boolean(st.shape) ? st.shape : null,
        averageWeight: resolveNumber(st.averageWeight),
        quantity: resolveNumber(st.quantity),
        totalWeight: resolveNumber(st.totalWeight),
        costCT: resolveNumber(st.costCT),
        totalCost: resolveNumber(st.totalCost)
      }))
      : []
  }

  const {stone} = entry

  if(stone) {
    data.stone = {
      ...stone,
      averageWeight: resolveNumber(stone.averageWeight),
      numberOfPieces: resolveNumber(stone.numberOfPieces),
      totalWeight: resolveNumber(stone.totalWeight),
      costCT: resolveNumber(stone.costCT),
      priceCT: resolveNumber(stone.priceCT),
      rap: resolveNumber(stone.rap),
      offPercent: resolveNumber(stone.offPercent),
      saleQuantity: resolveNumber(stone.saleQuantity),
      mountQuantity: resolveNumber(stone.mountQuantity),
      balanceQuantity: resolveNumber(stone.balanceQuantity),
      depht: resolveNumber(stone.depht),
      table: resolveNumber(stone.table),
    }
  }
  // @ts-ignore
  delete data['vrnPackages']
  // @ts-ignore
  delete data['relations']
  return data
}
