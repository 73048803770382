import { Button, Divider, Form, Input, Space, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { confirm, translate } from '../../../core'
import { AutoComplete } from '../../../core/componet'
import { useContext, useEffect, useState } from 'react'
import { CloseButton } from 'react-bootstrap'
import { PageContextValue } from '../../../core/type'
import { Contact } from '../type/contact'
import { ContactEntry } from '../type/contact-entry'
import { ContactPageContext } from './ContactPageContext'
import { useLazyQuery } from '@apollo/client'
import { useContactData } from '../hook/contact-data.hook'
import { GetCityList } from '../../shared/graphql/location-queries.graphql'
import { City } from '../../shared/type'

export const ContactEntryRelations = () => {
  const [cities, setCities] = useState<string[]>([])
  const [getCities] = useLazyQuery<{ cityList: City[] }>(GetCityList)

  const fetch = async () => {
    const {data} = await getCities()
    const list = (data?.cityList ?? []).map(c => c.name)
    setCities(list)
  }

  useEffect(() => {
    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form.List name="relations">
      {(fields, {add, remove}) => (
        <>
          <Divider
            orientation='left'
            className='fw-bold my-1'>
            <span>
              {translate('relations')}
            </span>

            <Button
              className='ms-3'
              type='default'
              onClick={() => add()}
              icon={<PlusOutlined/>}>
              {translate('add', 'relation')}
            </Button>
          </Divider>

          {
            fields.length
              ? <table className='table table-borderless mb-1'>
                <thead>
                <tr>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('contact_id')}</td>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('first_name')}</td>
                  <td className='text-center  py-0' style={{width: '9%'}}>{translate('last_name')}</td>
                  <td className='text-center py-0' style={{width: '9%'}}>{translate('city')}</td>
                  <td className='text-center py-0' style={{width: '14%'}}>{translate('street')}</td>
                  <td className='text-center py-0' style={{width: '14%'}}>{translate('line', '1')}</td>
                  <td className='text-center  py-0' style={{width: '14%'}}>{translate('line', '2')}</td>
                  <td className='text-center  py-0' style={{width: '12%'}}>{translate('phone')}</td>
                  <td style={{width: 200}}></td>
                </tr>
                </thead>

                <tbody>
                {fields.map(({key, name}) => (
                  <tr key={key}>
                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'contactId']}
                        rules={[{required: true, message: translate('missing_field_message', 'contact_id')}]}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'firstName']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'lastName']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'city']}
                      >
                        <AutoComplete
                          style={{width: '100%'}}
                          items={cities}
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'street']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'line1']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'line2']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={[name, 'phone']}
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        noStyle name={[name, 'id']}
                      >
                        <Commands
                          remove={() => remove(name)}
                        />
                      </Form.Item>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
              : null
          }
        </>
      )}
    </Form.List>
  )
}

const Commands = ({value, remove}: {
  value?: number,
  remove: () => void
}) => {
  const {fetch, refresh} = useContext<PageContextValue<Contact, ContactEntry>>(ContactPageContext)
  const [removing, setRemoving] = useState<boolean>(false)
  const {removeRelation} = useContactData()

  const handleRemoveRelation = async () => {
    if (!value)
      return

    const result = await confirm(
      translate('sure_to', 'remove', 'relation', '?')
    )

    if (!result)
      return

    setRemoving(true)

    try {
      await removeRelation(value)
      refresh?.()
    } finally {
      setRemoving(false)
    }
  }

  return (
    <>
      {
        value
          ? <Space>
            <Tooltip title={translate('view')}>
              <Button
                icon={<EyeOutlined/>}
                shape='circle'
                type='text'
                onClick={() => fetch!({id: value})}
              />
            </Tooltip>
            <Tooltip title={translate('remove')}>
              <Button
                danger
                loading={removing}
                icon={<DeleteOutlined/>}
                shape='circle'
                type='text'
                onClick={handleRemoveRelation}
              />
            </Tooltip>
          </Space>

          : <Button
            icon={<CloseButton/>}
            shape='circle'
            type='text'
            onClick={() => remove()}
          />
      }
    </>)
}
