import { AddressType, Contact } from '../../contact/type/contact'
import { OrderAddress, OrderEntry } from '../type'

export const createAddressByCustomer = (entry: OrderEntry, newCustomer?: Contact,) => {
  const billing = entry.addresses.find(ad => ad?.type === AddressType.Billing) as OrderAddress
  const shipping = entry.addresses.find(ad => ad?.type === AddressType.Shipping) as OrderAddress

  if (newCustomer) {
    const contactAddress = (newCustomer.addresses || [ {} ])[0] ?? {}

    mapAddress({
        ...contactAddress,
        firstName: newCustomer.firstName,
        lastName: newCustomer.lastName,
        middleName: '',
        address1: contactAddress.line1,
        address2: contactAddress.line2
      } as OrderAddress,
      billing)
  }

  mapAddress(
    entry.theShipmentAddressIsTheSameAsBilling
      ? billing
      : {} as OrderAddress
    , shipping)
}

const mapAddress = (source: OrderAddress, dist: OrderAddress) => {
  dist.firstName = source.firstName
  dist.lastName = source.lastName
  dist.middleName = source.middleName
  dist.address1 = source.address1
  dist.address2 = source.address2
  dist.street = source.street
  dist.city = source.city
  dist.state = source.state
  dist.zipCode = source.zipCode
  dist.country = source.country
}
