import { Button, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { translate } from '../../translate';
import { useState } from 'react';
import { ImageEntry, UploadingStatus } from '../../../app/inventory/type';
import { readUrlAsync } from '../../until';
import { UploadImageResponse } from '../../../app/shared/type';
import { uploaderService } from '../../../app/shared/service';
import { Image } from '../../type';

export const ImageUploaderButton = ({ onUploaded }: ImageUploaderButtonProps) => {
  const [ uploading, setUploading ] = useState<boolean>(false)
  const props: UploadProps = {
    beforeUpload: async (file) => {
      const image: ImageEntry = {
        name: file.name,
        status: UploadingStatus.Pending,
        file,
        url: await readUrlAsync(file)
      }

      const result = await handleUpload(image)
      onUploaded(result)

      return false
    },
    showUploadList: false
  }

  const handleUpload = async (image: ImageEntry): Promise<UploadImageResponse> => {
    setUploading(true)

    try {
      const [ result ] = await uploaderService.uploadImages([ image.file ] as UploadFile[])
      return result
    } finally {
      setUploading(false)
    }
  }

  return (
    <Upload { ...props }>
      <Tooltip title={ translate('upload') }>
        <Button
          shape='circle'
          icon={ <UploadOutlined/> }
          loading={ uploading }
          disabled={ uploading }
        />
      </Tooltip>
    </Upload>
  )
}

export interface ImageUploaderButtonProps {
  onUploaded: (image: Image) => void
}
