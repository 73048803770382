import { Input } from 'antd'
import { dateDisplay } from '../../until'

export const DateDisplay = ({value}: { value?: Date }) => (
  <Input
    disabled={true}
    className='input-disable-clear'
    value={dateDisplay(value)}
  />
)
