import styles from './AppraisalReport.module.scss';
import {Image, Table, TableProps} from "antd";
import GannImage from "../../../assets/gann-print-logo.png";
import React, {FC, useEffect, useState} from 'react';
import {useLazyQuery} from "@apollo/client";
import {GetAppraisalPrint} from "../graphql/appraisal.graphql";
import {chunkArrayWithSize} from "../../../core/until/print.utils";
import {AppraisalCustomerAddress, AppraisalLine} from "../type";
import {resolvePathFile} from "../../../core/until";

interface DataType2 {
    amount: number,
    image: number,
    description: string,
}

const itemColumns : TableProps<DataType2>['columns'] = [
    {
        title: 'DESCRIPTION',
        dataIndex: 'description',
        className: "description-col"
    }, {
        title: ' ',
        dataIndex: 'image',
        width: '150px',
        align: 'center',
        render: (text) => {
            return  <div className='image'>
                {text &&  <Image src={resolvePathFile(text)} width={100} height={100} preview={false} style={{ objectFit: "contain"}}/>}
            </div>
        }
    },{
        title: 'APPRAISED VALUE',
        dataIndex: 'amount',
        width: '110px',
        align: 'center',
        render: text => text.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
    },
]

interface IAppraisalReport {
    id: number;
    fetched: ()=> void;
    isRefetch: boolean;
}

interface IGetPrint {
    customerAddress?: AppraisalCustomerAddress;
    number?: string
    date?: string
    customerFirstname?: string
    customerLastname?: string;
    lines: AppraisalLine[]
}

const AppraisalReport: FC<IAppraisalReport> = ({id, isRefetch, fetched}) => {
    const [getPrint] = useLazyQuery<{ appraisalPrint: any }, { id: number }>(GetAppraisalPrint)

    const [itemsTable, setItemsTable] = useState<AppraisalLine[][]>([]);
    const [reportData, setReportData] = useState<IGetPrint>();

const getData = async () => {
    const {data} = await getPrint({
        variables: {
            id
        }
    })

    if(data) {
        const printData = data.appraisalPrint
        setReportData(printData);
        const chunks = chunkArrayWithSize(printData.lines, 3,5, {
            amount: '',
            image: '',
            description: "",
        });
        setItemsTable(chunks)
    }
}
    useEffect(() => {
        (async()=> {
            if(id) {
                await getData();
            }
        })()
    }, [id]);

    useEffect(() => {
        (async()=> {
            if(isRefetch) {
                await getData();
                fetched();
            }
        })()
    }, [isRefetch]);

    const Header = <header className='d-flex flex-column'>
        <div className='d-flex w-full '>
            <Image src={GannImage}  height={68} style={{ width: 'auto'}}  preview={false} />
        </div>
        <div className='d-flex w-full m-x-auto header-image'>
            <h1>Appraisal</h1>
        </div>
        <div className={ styles["address-wrapper"] } >
            <span>Property of: {`${reportData?.customerFirstname || ""}  ${reportData?.customerLastname || ""}`}</span>
            <span>Report No: {reportData?.number || ""}</span>
        </div>
        <div className={ styles["address-wrapper"] } >
            <div className='d-flex flex-column' style={{ marginLeft: 60}} >
                <span>{`${reportData?.customerAddress?.street || ""} ${reportData?.customerAddress?.line1 || ""} ${reportData?.customerAddress?.line2 || ""}`}</span>
                <span>{`${reportData?.customerAddress?.city || ""} ${reportData?.customerAddress?.state ? " , " : ""}  ${reportData?.customerAddress?.state || ""} ${reportData?.customerAddress?.zipCode || ""}`}</span>
            </div>
        </div>
    </header>
    const Footer = ({page}: { page: number}) =>  <footer className={styles['footer']}>
        <p>
            We hereby certify that the above named party has the listed articles for appraisal and that the descriptions and values indicated are accordance with our knowledge and belief. All of the above articles are in no need of repair otherwise indicated. The purpose of this appraisal is to adequately identify each item and assign a retail replacement cost. The values indicated do not reflect the price the customer paid and is not offer to purchase.
        </p>
        <div className="w-100 d-flex justify-content-between py-2">
            <b>Appraiser's signature:</b>
            <b>Joseph Gann Jewelers LCC</b>
        </div>
        <div className="w-100 d-flex justify-content-between py-1">
        <div className=" d-flex gap-2">
            <b>Date: </b>
            <span>{reportData?.date}</span>
        </div>
            <span>Page {page} of {itemsTable.length}</span>

        </div>
    </footer>
    return(
        <div className="print-show print-page-appraisal">
            {itemsTable.map((item,index) =>   {
                return <div className={ styles["appraisal-report"] }>
                    {Header}
                    <div className='mt-3' style={{width: '100%'}}>
                        <Table
                            key={'amount'}
                            dataSource={item as any}
                            columns={itemColumns}
                            pagination={false}
                            rowKey='itemColumns'
                            className='itemTable'
                        />
                        <Footer page={index + 1}/>
                    </div>
                </div>
            })}
        </div>
    );
};
export default AppraisalReport;



