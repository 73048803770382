import { translate } from "../../../core";
import { Form } from "antd";
import { NumericInput } from "../../../core/componet";
import styles from "./ItemEntry.module.scss";
import { InputCard } from "./InputCard";

export const ItemFormSales = () => {
  return (
    <InputCard className="py-0 mb-0" title={translate("sold")}>
      <table className={["table table-borderless", styles["SoldTable"]].join(" ")}>
        <body>
          <tr>
            <td colSpan={2}>{translate("year_to_date")}</td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("quantity_abbr")}</td>
            <td>
              <Form.Item noStyle name={["sales", "yearToDateQuantity"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("sales")}</td>
            <td>
              <Form.Item noStyle name={["sales", "yearToDateAmount"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{translate("last_year_to_date")}</td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("quantity_abbr")}</td>
            <td>
              <Form.Item noStyle name={["sales", "lastYearQuantity"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
          <tr>
            <td className={styles.QuantityCaption}>{translate("sales")}</td>
            <td>
              <Form.Item noStyle name={["sales", "lastYearAmount"]}>
                <NumericInput
                  style={{ width: 70 }}
                  decimalPlaces={0}
                />
              </Form.Item>
            </td>
          </tr>
        </body>
      </table>
    </InputCard>
  );
};
