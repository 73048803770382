import { Button, Form, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { notify, translate } from '../../../core'
import { useState } from 'react'
import { setCurrentUser } from '../../../store/redux/reducer'
import { User, UserUpdateDto } from '../type'
import { userService } from '../service'
import { useLazyQuery } from '@apollo/client'
import { GetUserAuthenticated } from '../graphql/user-queries.graphql'

export const UserChangeName = () => {
  const { currentUser } = useSelector(s => s) as { currentUser: User }
  const [ form ] = Form.useForm()
  const [ loading, setLoading ] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [ getUserAuthenticated ] = useLazyQuery<{ userAuthenticated: User }>(GetUserAuthenticated)

  const onSave = async (value: UserUpdateDto) => {
    setLoading(true)

    try {
      await userService.update(currentUser.id as any, value)

      const { data } = await getUserAuthenticated()
      const authenticatedUser = data?.userAuthenticated || {} as User
      dispatch(setCurrentUser(authenticatedUser))
      
      notify.success(translate('change_name'), translate('change_name_success_message'))
    } finally {
      setLoading(false)
    }
  }

  form.setFieldsValue({
    name: currentUser.name,
    status: currentUser.status
  })

  return (
    <>
      <Form
        form={ form }
        layout='vertical'
        name="changeNameForm"
        labelCol={ { span: 8 } }
        wrapperCol={ { span: 16 } }
        initialValues={ { remember: true } }
        onFinish={ onSave }
        autoComplete="off"
      >
        <Form.Item
          label={ translate('name') }
          name='name'
          rules={ [ { required: true, message: translate('name_required_message') } ] }
        >
          <Input size='large'/>
        </Form.Item>

        <Form.Item>
          <Button
            loading={ loading }
            type='primary'
            size='large'
            htmlType='submit'>
            { translate('save') }
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
