import { PrinterList } from './PrinterList'
import { Section } from '../../../../core/componet'

export const PrinterSettingsContent = () => {
  return (
    <Section noMargin>
     <PrinterList/>
    </Section>
  )
}
