import styles from '../../order/component/Order.module.scss'
import { Checkbox, Form, Input, Row } from 'antd'
import { InputCard } from '../../inventory/component/InputCard'
import { translate } from '../../../core'
import { AddressType } from '../../contact/type/contact'
import { useContext } from 'react'
import { PageContextValue } from '../../../core/type'
import {  WeddingRegistry, WeddingRegistryEntry } from '../type'
import { WeddingRegistryPageContext } from './WeddingRegistryPageContext'

const TextArea = Input.TextArea

type WeddingRegistryFormAddressesProps = {
  isTheSameAddressChanged: (value: boolean) => void
}

export const WeddingRegistryFormAddresses = ({ isTheSameAddressChanged }: WeddingRegistryFormAddressesProps) => {
  const { form } = useContext<PageContextValue<WeddingRegistry, WeddingRegistryEntry>>(WeddingRegistryPageContext)
  const getTitle = (index: number): string => {
    const type = form?.getFieldValue([ 'addresses', index, 'type' ]) as AddressType
    return {
      [AddressType.Billing]: translate('address'),
      [AddressType.Shipping]: translate('ship', 'address')
    }[type]
  }

  return (
      <div className={ styles['customerAddressWrapper'] }>
        <Form.List name='addresses'>
          { (fields) => (
            <Row align='stretch' className={ styles['customerAddressCard'] }>
              <InputCard className={ styles['titleCard'] }>
                <span className='fw-bold'>{ translate('first_name') }</span>
                <span className='fw-bold'>{ translate('last_name') }</span>
                <span className='fw-bold'>{ translate('address', '1') }</span>
                <span className='fw-bold'>{ translate('address', '2') }</span>
                <span className='fw-bold'>{ translate('street') }</span>
                <span className='fw-bold'>{ translate('city_state_zip') }</span>
                <span className='fw-bold'>{ translate('country') }</span>
              </InputCard>

              { fields.map((field, index) => (
                <InputCard key={`${field.name}-${field.key}`} title={ getTitle(index) } className={ styles['billingCard'] }>
                  <Form.Item
                    className={ [ styles.addressItemCellContent, styles['addressNameInput'] ].join(
                      ' '
                    ) }
                    name={ [ field.name, 'firstName' ] }
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name={ [ field.name, 'lastName' ] }
                    className={ [ styles.addressItemCellContent, styles['addressNameInput'] ].join(
                      ' '
                    ) }
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name={ [ field.name, 'address1' ] }
                    className={ styles.addressItemCellContent }
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name={ [ field.name, 'address2' ] }
                    className={ styles.addressItemCellContent }
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                    name={ [ field.name, 'street' ] }
                    className={ styles.addressItemCellContent }
                  >
                    <Input/>
                  </Form.Item>
                  <div className={ styles['cityStateZipWrapper'] }>
                    <Form.Item
                      name={ [ field.name, 'city' ] }
                      className={ [ styles.addressItemCellContent, styles['city'] ].join(' ') }
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      name={ [ field.name, 'state' ] }
                      className={ [ styles.addressItemCellContent, styles['state'] ].join(' ') }
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      name={ [ field.name, 'zipCode' ] }
                      className={ [ styles.addressItemCellContent, styles['zipCode'] ].join(' ') }
                    >
                      <Input/>
                    </Form.Item>
                  </div>
                  <Form.Item
                    name={ [ field.name, 'country' ] }
                    className={ styles.addressItemCellContent }
                  >
                    <Input/>
                  </Form.Item>
                </InputCard>
              )) }
            </Row>
          ) }
        </Form.List>
        <InputCard
          title={ translate('special_instruction') }
          className={ styles['specialInstructionsCard'] }
        >
          <Form.Item name='specialInstruction'>
            <TextArea size='large' rows={ 4 }/>
          </Form.Item>
        </InputCard>

        <Form.Item name='print' valuePropName='checked' className={ styles['showInstructionsInput'] }>
          <Checkbox>{ translate('show') }</Checkbox>
        </Form.Item>
        <Form.Item
          name='theShipmentAddressIsTheSameAsBilling'
          valuePropName='checked'
          className={ styles['shipSameInput'] }
        >
          <Checkbox
            onChange={ e => isTheSameAddressChanged(e.target.checked) }>
            { translate('ship_to_same') }
          </Checkbox>

        </Form.Item>
        <Form.Item name='isBride' valuePropName='checked' className={ styles['brideGroomeInput'] }>
          <Checkbox>{ translate('bride_groom') }</Checkbox>
        </Form.Item>
      </div>
  )
}
