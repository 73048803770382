import {Table, TableProps} from "antd";

import React, {FC, useEffect, useState} from 'react';
import styles from "./SalecByCustomer.module.scss"
import {IGetSalesByCustomer, ISalesByCustomerReportData} from "../type/customer-report";
import {chunkArray} from "../../../../core/until/print.utils";

const itemColumns : TableProps<ISalesByCustomerReportData>['columns'] = [
    {
        title: 'Order Date',
        dataIndex: 'orderDate',
        width: '10%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between mb-3 '>
            <label>Customer No: {row.contactId}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.contactId ? 4 : 1,
        }),
    },{
        title: 'No',
        dataIndex: 'orderNumber',
        width: '10%',
        onCell: (text) => ({
            colSpan: text.contactId ? 0 : 1,
        }),
    },{
        title: 'Item No.',
        dataIndex: 'sku',
        width: '10%',
        onCell: (text) => ({
            colSpan: text.contactId ? 0 : 1,
        }),
    },
    {
        title: 'Description/Style',
        dataIndex: 'description',
        width: '40%',
        render: (text, row) => text + " / " + row.style,
        onCell: (text) => ({
            colSpan: text.contactId ? 0 : 1,
        }),
    },{
        title: 'Otv',
        dataIndex: 'quantity',
        width: '10%',
        onCell: (text) => ({
            colSpan: text.contactId ? 0 : 1,
        }),
    },{
        title: 'Unit Price',
        dataIndex: 'unitPrice',
        width: '10%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between mb-3 '>
            <label>Totals for Customer: {row.total}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.contactId ? 3 : 1,
        }),
    },{
        title: 'Ext. Price',
        dataIndex: 'extendedPrice',
        width: '10%',
        onCell: (text) => {
            return {
            colSpan: text.contactId ? 0 : 1,
        }},
    }
]

interface IProps {
    data?: IGetSalesByCustomer
}

const SalesByCustomerReport: FC<IProps> = ({data}) => {

    const [itemsTable, setItemsTable] = useState<any[]>([]);

    useEffect(() => {
        if(data) {
            const printData = data.salesByCustomer;
            const flatArray = printData.flatMap(item => {
                return [...item.lines, {
                    contactId: item.contactId,
                    total: item.total
                }];
            });
            const chunks = chunkArray(flatArray, 30);
            setItemsTable(chunks)
        }
    }, [data]);

    useEffect(() => {
        if (itemsTable.length > 0) {
             window.print()
             setItemsTable([])
        }
    }, [itemsTable])

    const Header = <header className='d-flex flex-column gap-2 report-header'>
        <div className='d-flex justify-content-center'>
            <span>Sales By Customer</span>
        </div>
        <Table
            dataSource={[]}
            columns={itemColumns}
            pagination={false}
        />
    </header>
    return(
        <div className="print-show print-page-report ">
            <div className={styles['report']}>
                {itemsTable.map((item) =>   {
                    return <main className='page-break'>
                        {Header}
                            <Table
                                key={'sku'}
                                dataSource={item as any}
                                columns={itemColumns}
                                pagination={false}
                                rowKey='sku'
                                className='itemTable'
                            />
                    </main>
                })}
        </div>
        </div>
    );
};
export default SalesByCustomerReport;



