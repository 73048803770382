import React, { useState } from 'react';
import {
  DataGridColumn,
  DataGridItem,
  DataGridRef,
  EditableCellProps,
  EditableDataGrid,
  EditableDataGridHook
} from './type';
import { Button, Form, Space, Tooltip } from 'antd';
import { translate } from '../../translate';
import { DeleteOutlined, EditFilled, SaveFilled, UndoOutlined } from '@ant-design/icons';
import { DATA_GRID_NEW_KEY } from './resolver';

export const editableColumn = <T extends DataGridItem>(columnPlus: EditableDataGrid<T>) => {
  const {editableComponent, isEditing, ...column} = columnPlus;

  return {
    ...column,
    onCell: (record: T): EditableCellProps => ({
      editing: isEditing(record),
      dataIndex: column.dataIndex,
      record,
      editableComponent
    }),
    className: 'p-0 m-0'
  }
}

export const editableActions = <T extends DataGridItem>(
  editingKey: React.Key,
  saving: boolean,
  isEditingFunc: (record: T) => boolean,
  cancel: (record: T) => void,
  edit: (record: Partial<T>) => void,
  save: (record: T) => Promise<void>,
  remove?: (record: T) => Promise<void>,
  ): DataGridColumn<T> => {
  return {
    title: '',
    dataIndex: 'operation',
    width: 200,
    render: (_, record: T) => {
      const isEditing = isEditingFunc(record);
      return (
        <>
          {
            isEditing
              ? (
                <>
                  <Space>
                    <Tooltip title={translate('save')}>
                      <Button
                        loading={saving}
                        type='text'
                        shape='circle'
                        icon={<SaveFilled style={{color: '#1677ff', fontSize: 20}}/>}
                        onClick={() => save(record)}/>
                    </Tooltip>

                    <Tooltip title={translate('cancel')}>
                      <Button
                        type='text'
                        shape='circle'
                        icon={<UndoOutlined style={{fontSize: 20}}/>}
                        onClick={() => cancel(record)}
                      />
                    </Tooltip>
                  </Space>
                </>
              )
              : (
                <Space>
                  <Tooltip title={translate('edit')}>
                    <Button
                      type='text'
                      shape='circle'
                      disabled={editingKey !== ''}
                      icon={<EditFilled style={{color: '#1677ff', fontSize: 20}}/>}
                      onClick={() => edit(record)}/>
                  </Tooltip>

                  <Tooltip title={translate('remove')}>
                    <Button
                      type='text'
                      shape='circle'
                      disabled={editingKey !== ''}
                      icon={<DeleteOutlined style={{color: '#f50', fontSize: 20}}/>}
                      onClick={() => remove!(record)}/>
                  </Tooltip>
                </Space>
              )
          }
        </>
      )
    }
  }
}

export const DataGridEditableCell: React.FC<EditableCellProps> = (props) => {
  const {editing, dataIndex, title, editableComponent, record, index, children, ...restProps} = props;
  const editable = !!editableComponent;

  return (
    <td {...restProps}>
      {
        editing && editable
          ? (editableComponent)
          : (children)
      }
    </td>
  )
};

export const useDataGridEditable = <T extends DataGridItem>(
  ref: React.RefObject<DataGridRef<T>>,
  onSave: (key: React.Key, row: T) => Promise<void>
): EditableDataGridHook<T> => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState<string>('');
  const isEditing = (record: T) => editingKey === record.key;

  const cancel = (record: T) => {
    setEditingKey('');
    record.key === DATA_GRID_NEW_KEY && ref.current?.removeRow(record.key);
  };

  const edit = (record: Partial<T>) => {
    form.setFieldsValue({...(record.key === DATA_GRID_NEW_KEY ? {} : record)});
    setEditingKey(record.key || '');
  };

  const save = async (record: T) => {
    const row = (await form.validateFields()) as T;
    await Promise.resolve(onSave(record.key, {...record, ...row}));
  }

  return {
    form,
    editingKey,
    isEditing,
    cancel,
    edit,
    save,
    markAsCompleted: () => setEditingKey('')
  };
}
