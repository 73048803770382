import { translate } from '../../../core';
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import { LabelFormItem, Section } from '../../../core/componet';
import { dateFormat } from '../../../core/until';
import styles from "./ItemForm.module.scss"

export const ItemFromGiftwareTableware = ({type}: { type: 'tableware' | 'giftware' }) => {
  return (
    <Section noMargin>
      <Divider
        orientation="left"
        className="fw-bold mt-0 mb-1"
      >
        <Form.Item noStyle name="type">
          <LabelFormItem/>
        </Form.Item>
      </Divider>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            className={styles["giftware-input"]}
            label={translate('pattern_number_abbr')}
            name={[type, 'patternNumber']}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            className={styles["giftware-input"]}
            label={translate('dimensions')}
            name={[type, 'dimensions']}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            className={styles["giftware-input"]}
            label={translate('sequence')}
            name={[type, 'sequence']}
          >
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Form.Item
            className={styles["giftware-input"]}
            name={[type, 'discounted']}
            valuePropName="checked"
          >
            <Checkbox>{translate('discounted')}</Checkbox>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={translate('date')}
            name={[type, 'discontinuedDate']}
          >
            <DatePicker
              format={dateFormat}
              style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
      </Row>
    </Section>
  )
}
