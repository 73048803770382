import { Alert, Button, Form, Input } from 'antd';
import { notify, translate } from '../../../core';
import { useState } from 'react';
import { userService } from '../service';

export const UserChangePassword = () => {
  const [ loading, setLoading ] = useState<boolean>(false);
  const [ errorMessage, setErrorMessage ] = useState<string>('');

  const onSave = async ({ password, repeatPassword }: { password: string, repeatPassword: string }) => {
    if (password !== repeatPassword) {
      setErrorMessage(translate('password_and_repeat_password_are_not_equal'));
      return;
    }

    setLoading(true);

    try {
      await userService.changePassword({ password });
      notify.success(translate('change_password'), translate('reset_password_success_message'));
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {
        errorMessage.length > 0 &&
        <Alert
          className="mt-2"
          message={ translate('change_password_fail_message') }
          description={ errorMessage }
          type="error"
          showIcon
        />
      }

      <Form
        className="mt-3"
        layout='vertical'
        name="changePasswordForm"
        labelCol={ { span: 8 } }
        wrapperCol={ { span: 16 } }
        initialValues={ { remember: true } }
        onFinish={ onSave }
        autoComplete="off"
      >
        <Form.Item
          label={ translate('password') }
          name="password"
          rules={ [ { required: true, message: translate('password_required_message') } ] }
        >
          <Input size="large"
                 type="password"
          />
        </Form.Item>

        <Form.Item
          label={ translate('repeat_password') }
          name="repeatPassword"
          rules={ [ { required: true, message: translate('repeat_password_required_message') } ] }
        >
          <Input size="large"
                 type="password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={ loading }
            type='primary'
            size='large'
            htmlType='submit'>
            { translate('save_password') }
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
