import {Appraisal, AppraisalLine} from '../type'

export const init = async (getNewNumber: () => Promise<string>): Promise<Appraisal> => {
  return {
    number: await getNewNumber(),
    appraiser: null,
    customer: { id: null },
    date: new Date(),
    lines: [] as AppraisalLine[],
  } as unknown as Appraisal
}
