import { gql } from '@apollo/client'

export const GetContactGroups = gql`
    query GetContactGroups($request: ContactGroupFindRequest!) {
        contactGroupsFind(request: $request) {
            data {
                id
                title
            }
            count
        }
    }
`

export const GetContactGroupsLookup = gql`
    query GetContactGroupsLookup($request: ContactGroupFindRequest!) {
        contactGroupsFind(request: $request) {
            data {
                id
                title
            }
        }
    }

`


