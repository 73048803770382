import { gql } from '@apollo/client'

export const GetPrintersDocument = gql`
  query($request: PrinterPageableRequest!) {
    printersFind(request: $request) {
      data {
        id
        title
        ip
        port
        templateType
      }
      count
    }
  }  
`