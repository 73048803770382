import { Collapse } from 'antd';
import { translate } from '../../../core';
import { Container } from '../../../core/componet';
import { InventorySettingsContent } from '../../inventory/component/InventorySettingsContent';
import { ContactSettingsContent } from '../../contact/component/ContactSettingsContent';
import { ShopSettingsContent } from '../../shop/component/ShopSettingsContent'
import { PrinterSettingsContent } from './printer/PrinterSettingsContent'

const {Panel} = Collapse;

export const Settings = () => {
  return (
    <Container>
      <Collapse>
        <Panel header={translate('contact')} key='contact'>
          <ContactSettingsContent/>
        </Panel>

        <Panel header={translate('order')} key='order'>

        </Panel>

        <Panel header={translate('inventory')} key='inventory'>
          <InventorySettingsContent/>
        </Panel>

        <Panel header={translate('shop')} key='shop'>
          <ShopSettingsContent/>
        </Panel>

        <Panel header={translate('printer')} key='printer'>
          <PrinterSettingsContent/>
        </Panel>
      </Collapse>
    </Container>
  )
}
