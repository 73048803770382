import companyLogo from '../../../../assets/logo.svg';
import styles from './Layout.module.scss';
import { Outlet } from 'react-router-dom';

export const AuthLayout = () => {
  return (
    <>
      <div className={styles.authContainer}>
        <div className={ styles.authLogoContainer }>
          <img src={ companyLogo } alt={ '' }/>
        </div>
        <div className={ styles.authMain }>
          <Outlet/>
        </div>
      </div>
    </>
  )
}
