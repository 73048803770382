import { Button, Divider, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { translate } from '../../../core';
import { Combo, enumToComboOptionTransformer } from '../../../core/componet';
import { WebInfoType } from '../type/contact';
import { CloseButton } from 'react-bootstrap';

export const ContactEntryWebInfos = () => {
  return (
    <Form.List name="webInfos">
      { (fields, { add, remove }) => (
        <>
          <Divider
            orientation='left'
            className='fw-bold my-1'>
            <span>
              { translate('web_info') }
            </span>

            <Button
              className='ms-3'
              type="default"
              onClick={ () => add() }
              icon={ <PlusOutlined/> }>
              { translate('add', 'web_info') }
            </Button>
          </Divider>
          {
            fields.length
              ? <table className='table table-borderless mb-1'>
                <thead>
                <tr>
                  <td className='text-center py-0' style={ { width: '18%' } }>{ translate('type') }</td>
                  <td className='text-center py-0' style={ { width: '18%' } }>{ translate('value') }</td>
                </tr>
                </thead>
                <tbody>
                { fields.map(({ key, name, ...restField }) => (
                <tr key={key}>
                  <td className='py-0'>
                    <Form.Item
                      className='mb-1'
                      name={ [ name, 'type' ] }
                      rules={ [ { required: true, message: translate('missing_field_message', 'type') } ] }
                    >
                      <Combo
                        placeholder={ translate('select', 'type') }
                        size='middle'
                        options={ enumToComboOptionTransformer(WebInfoType) }
                      />
                    </Form.Item>
                  </td>
                  <td className='py-0'>
                    <Form.Item
                      className='mb-1'
                      name={ [ name, 'value' ] }
                    >
                      <Input
                        style={ { width: 400 } }
                      />
                    </Form.Item>
                  </td>
                  <td className='py-0'>
                    <Button
                      icon={ <CloseButton/> }
                      shape='circle'
                      type='text'
                      onClick={ () => remove(name) }
                    />
                  </td>
                </tr>
                ))
               }
                </tbody>
                </table>
                : null
          }
        </>
      ) }
    </Form.List>
  )
}
