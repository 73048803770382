import { FormListFieldData } from 'antd'
import React from 'react'
import { Nullable } from '../../../core/type';

type AppraisalLinesSliderProps = {
  items: FormListFieldData[]
  renderItem: (field: FormListFieldData) => React.ReactNode
  currentIndex: Nullable<number>
}

export const AppraisalLinesSlider = ({items, renderItem, currentIndex}: AppraisalLinesSliderProps) => {
  return (
    <div className='mt-2'>
      {
        items.map(field =>
          <div key={field.name + field.key} style={{display: currentIndex === field.name ? 'unset' : 'none'}}>
            {renderItem(field)}
          </div>)
      }
    </div>
  )
}
