import { Button, Result } from 'antd';
import styles from './Auth.module.scss';
import { translate } from '../../../core';
import { CompletionProps } from '../../../core/type';

export const ResetPasswordSuccess = ({ onCompleted }: CompletionProps) => {
  return (
    <>
      <Result
        status="success"
        title={ translate('repeat_password_success_message') }
        extra={ [
          <Button
            block
            size="large"
            onClick={ onCompleted }
            className={ styles.submitButton + ' mt-5' }>
            { translate('done') }
          </Button>
        ] }
      />
    </>
  )
}

