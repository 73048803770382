import { KeyValuePair } from '../../type';
import { ComboOption, XOptionType } from './type';

export const enumToComboOptionTransformer = (enumType: Record<string, string>): ComboOption<KeyValuePair>[] => {
  return Object.entries(enumType)
    .map<ComboOption<KeyValuePair>>(([ key, value ]) => ({
      value,
      display: key,
      item: { key: key.toString(), value }
    }))
}

export function mapToSelectOptions<T>(options: ComboOption<T>[]): XOptionType<T>[] {
  return options.map(item => ({
    value: item.value,
    label: item.display,
    item: item.item
  }))
}
