import { RcFile } from 'antd/es/upload';
import { FILE_PREFIX_PATH } from '../../App.constants';

export const readUrlAsync = (file: RcFile): Promise<string> => {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result as string);
  })
}

export const resolvePathFile = (filename: string): string => `${ FILE_PREFIX_PATH }/${ filename }`;
