import { Nullable } from '../type'

export const round = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;

export const resolveNumber = (
  number: Nullable<number>,
  options?: { changeNegativeToZero?: boolean }) => {
  let newValue = Number(number || 0)

  if(options?.changeNegativeToZero)
    newValue = newValue >= 0 ? newValue : 0

  return newValue
}

