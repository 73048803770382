import { translate } from '../../../core'
import { Section } from '../../../core/componet'
import { Tabs, TabsProps } from 'antd'
import { WebhookList } from './WebhookList'
import { JobsContainer } from './JobsContainer'

export const ShopSettingsContent = () => {
  const items: TabsProps['items'] = [
    {
      label: translate('webhooks'),
      key: 'shop_webhook',
      children: <WebhookList/>,
    },
    {
      label: translate('jobs'),
      key: 'jobs',
      children: <JobsContainer/>,
    },
  ]

  return (
    <Section noMargin>
      <Tabs
        defaultActiveKey="shop_webhook"
        items={items}
      />
    </Section>
  )
}
