import { ItemType } from '../type';
import { translate } from '../../../core';
import { ComboOption } from '../../../core/componet'
import { KeyValuePair } from '../../../core/type'

export const getItemTypeComboOptions = () =>
  Object.entries({
    [ItemType.Jewelery]: ItemType.Jewelery,
    [ItemType.Watch]: ItemType.Watch,
    [ItemType.Giftware]: ItemType.Giftware,
    [ItemType.Stone]: ItemType.Stone,
    [ItemType.Tableware]: ItemType.Tableware,
  })
    .map<ComboOption<KeyValuePair>>(([ key, value ]) => ({
      value: key,
      display: value,
      item: { key: key.toString(), value }
    }))
