import { ComboOption } from '../../../core/componet';
import { KeyValuePair } from '../../../core/type';
import { clarity, colors as baseColors, itemStoneNames } from './item-static-data';

export const stoneNames: ComboOption<KeyValuePair>[] = itemStoneNames.map<ComboOption<KeyValuePair>>(name => ({
  value: name,
  display: name,
  item: { key: name, value: name }
}))

export const stoneColors: ComboOption<KeyValuePair>[] = baseColors.map(({ key, value }) => ({
  value,
  display: key,
  item: { key, value }
}))

export const stoneClarityItems: ComboOption<KeyValuePair>[] = clarity.map(({ key, value }) => ({
  value,
  display: key,
  item: { key, value }
}))
