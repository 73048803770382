import { Contact, PhoneType, WebInfoType } from '../type/contact'

export const contactInitialize = (): Contact => {
  return {
    addresses: [
      {}
    ],
    phones: [
      {
        type: PhoneType.Cell,
      }
    ],
    webInfos: [
      {
        type: WebInfoType.EmailAddress,
      }
    ]
  } as Contact
}