import {Table, TableProps} from "antd";

import React, {FC, useEffect, useState} from 'react';
import styles from "./SalesByVendor.module.scss"
import {chunkArray} from "../../../../core/until/print.utils";
import {IGetSalesByVendor, ISalesByVendorReportData} from "../type/vendor-report";

const itemColumns : TableProps<ISalesByVendorReportData>['columns'] = [
    {
        title: 'Vendor Id',
        dataIndex: 'vendorId',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between'>
            <label>{row.label}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 6 : 1,
        }),
    },{
        title: 'Style No',
        dataIndex: 'style',
        width: '9%',
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'Category',
        dataIndex: 'category',
        width: '9%',
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },
    {
        title: 'Sub-Category',
        dataIndex: 'subcategory',
        width: '11%',
        render: (text, row) => <div className='d-flex flex-column'>
            <span>{text}</span>
            <span>Chain Length:{row.chainLength}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'Item No',
        dataIndex: 'sku',
        width: '9%',
        render: (text, row) => <div className='d-flex flex-column'>
            <span>{text}</span>
            <span>Finger Size:{row.fingerSize}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'DatePurchase',
        dataIndex: 'datePurchase',
        width: '9%',
        render: (text, row) => <div className='d-flex flex-column'>
            <span>{text}</span>
            <span>Stone:{row.stoneName}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },
    {
        title: 'Otv',
        dataIndex: 'quantity',
        width: '4%',
        align: 'center',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between'>
            <label className='m-auto'>{row.totalQuantity}</label>
        </div>,
    }
    ,{
        title: 'Ext.Cost',
        dataIndex: 'extendedCost',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between '>
            <label>{row.totalCost}</label>
        </div>,
    },
    {
        title: 'Ext.Price',
        dataIndex: 'extendedPrice',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between '>
            <label>{row.totalPrice}</label>
        </div>,
    },
    {
        title: 'Metal',
        dataIndex: 'metal',
        width: '4%',
    },
    {
        title: 'Order NO',
        dataIndex: 'orderNumber',
        width: '9%',
    }
]
const chinaColumns : TableProps<ISalesByVendorReportData>['columns'] = [
    {
        title: 'Vendor Id',
        dataIndex: 'vendorId',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between'>
            <label>{row.label}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 6 : 1,
        }),
    },{
        title: 'Style No',
        dataIndex: 'style',
        width: '9%',
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'Category',
        dataIndex: 'category',
        width: '9%',
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },
    {
        title: 'Sub-Category',
        dataIndex: 'subcategory',
        width: '11%',
        render: (text) => <div className='d-flex flex-column'>
            <span>{text}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'Item No',
        dataIndex: 'sku',
        width: '9%',
        render: (text) => <div className='d-flex flex-column'>
            <span>{text}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },{
        title: 'DatePurchase',
        dataIndex: 'datePurchase',
        width: '9%',
        render: (text) => <div className='d-flex flex-column'>
            <span>{text}</span>
        </div>,
        onCell: (text) => ({
            colSpan: text.label ? 0 : 1,
        }),
    },
    {
        title: 'Otv',
        dataIndex: 'quantity',
        width: '4%',
        align: 'center',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between'>
            <label className='m-auto'>{row.totalQuantity}</label>
        </div>,
    }
    ,{
        title: 'Ext.Cost',
        dataIndex: 'extendedCost',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between '>
            <label>{row.totalCost}</label>
        </div>,
    },
    {
        title: 'Ext.Price',
        dataIndex: 'extendedPrice',
        width: '9%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between '>
            <label>{row.totalPrice}</label>
        </div>,
    },
    {
        title: 'Order NO',
        dataIndex: 'orderNumber',
        width: '9%',
    }
]

interface IProps {
    data?: IGetSalesByVendor
    isChina?: boolean
}

const SalesByVendorReport: FC<IProps> = ({data, isChina, }) => {

    const [itemsTable, setItemsTable] = useState<any[]>([]);

    useEffect(() => {
        if(data) {
            const printData = data.salesByVendor;
            let flatData: any[] = [];
            printData.vendors.flatMap(vendor => {
               const vendors=  vendor.categories.flatMap(category => {
                    const categories = category.subcategories.flatMap(subCategory => {
                        flatData.push(...subCategory.lines)
                        flatData.push({
                            totalPrice: subCategory.totalPrice,
                            totalCost: subCategory.totalCost,
                            totalQuantity: subCategory.totalQuantity,
                            label: `Totals for SubCategory:   ${subCategory.subcategory || ''}`
                        })

                        return null;
                    })

                    flatData.push({
                        totalPrice: category.totalPrice,
                        totalCost: category.totalCost,
                        totalQuantity: category.totalQuantity,
                        label: `Totals for Category:   ${category.category || ''}`
                    });
                    return categories
                })
                flatData.push({
                    totalPrice: vendor.totalPrice,
                    totalCost: vendor.totalCost,
                    totalQuantity: vendor.totalQuantity,
                    label: `Totals for Vendor:   ${vendor.vendorId || ''}`
                });
                return vendors
            });
            flatData.push({
                totalPrice: printData.totalPrice,
                totalCost: printData.totalCost,
                totalQuantity: printData.totalQuantity,
                label: `Grand Totals:`
            });
            const chunks = chunkArray(flatData, 32);
            setItemsTable(chunks)
        }
    }, [data]);

    useEffect(() => {
        if (itemsTable.length > 0) {
             window.print()
             setItemsTable([])
        }
    }, [itemsTable])

    const Header = <header className='d-flex flex-column gap-2 report-header'>
        <div className='d-flex justify-content-center'>
            <span>Sales By Customer</span>
        </div>
        <Table
            dataSource={[]}
            columns={isChina ? chinaColumns : itemColumns}
            pagination={false}
        />
    </header>
    return(
        <div className="print-show print-page-report ">
            <div className={styles['report']}>
                {itemsTable.map((item) =>   {
                    return <main className='page-break'>
                        {Header}
                            <Table
                                dataSource={item as any}
                                columns={isChina ? chinaColumns : itemColumns}
                                pagination={false}
                                className='itemTable'
                            />
                    </main>
                })}
        </div>
        </div>
    );
};
export default SalesByVendorReport;



