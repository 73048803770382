import { Button, Divider, Form, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { AutoComplete } from '../../../core/componet'
import { useEffect, useState } from 'react'
import { CloseButton } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import { QueryPageableResponse } from '../../../core/type'
import { City, Country, State } from '../../shared/type'
import { GetAllCitiesAndCountriesAndStates } from '../../shared/graphql/location-queries.graphql'

export const ContactEntryAddresses = () => {
  const [ cities, setCities ] = useState<string[]>([])
  const [ countries, setCountries ] = useState<string[]>([])
  const [states, setStates] = useState<string[]>([])
  const [ getAllCitiesAndCountriesAndStates ] = useLazyQuery<{cityList: City[], countryList: Country[]} & QueryPageableResponse<State, 'statesFind'>>(GetAllCitiesAndCountriesAndStates)

  const fetch = async () => {
    const { data } = await getAllCitiesAndCountriesAndStates()
    const cityList = (data?.cityList ?? []).map(c => c.name)
    const countryList = (data?.countryList ?? []).map(c => c.name)
    const states = (data?.statesFind?.data ?? []).map(s => s.key)

    setCities(cityList)
    setCountries(countryList)
    setStates(states)

  }

  useEffect(() => {
    fetch()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Form.List name="addresses">
      { (fields, { add, remove }) => (
        <>
          <Divider
            orientation='left'
            className='fw-bold my-1'>
            <span>
              { translate('address') }
            </span>

            <Button
              className='ms-3'
              type='default'
              onClick={ () => add({ country: 'USA' }) }
              icon={ <PlusOutlined/> }>
              { translate('add', 'address') }
            </Button>
          </Divider>

          {
            fields.length
              ? <table className='table table-borderless mb-1'>
                <thead>
                <tr>
                  <td className='text-center py-0' style={ { width: '30%' } }>{ translate('street') }</td>
                  <td className='text-center py-0' style={ { width: '15%' } }>{ translate('line', '1') }</td>
                  <td className='text-center py-0' style={ { width: '15%' } }>{ translate('line', '2') }</td>
                  <td className='text-center py-0' style={ { width: '10%' } }>{ translate('city') }</td>
                  <td className='text-center py-0' style={ { width: '10%' } }>{ translate('state') }</td>
                  <td className='text-center py-0' style={ { width: '10%' } }>{ translate('zip_code') }</td>
                  <td className='text-center py-0' style={ { width: '10%' } }>{ translate('country') }</td>
                  <td></td>
                </tr>
                </thead>

                <tbody>
                { fields.map(({ key, name }) => (
                  <tr key={key}>
                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'street' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'line1' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'line2' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'city' ] }
                      >
                        <AutoComplete
                          style={ { width: '100%' } }
                          items={ cities }
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'state' ] }
                      >
                        <AutoComplete
                          style={ { width: '100%' } }
                          items={states}
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'zipCode' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'country' ] }
                      >
                        <AutoComplete
                          items={ countries }
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Button
                        icon={ <CloseButton/> }
                        shape='circle'
                        type='text'
                        onClick={ () => remove(name) }
                      />
                    </td>
                  </tr>
                )) }
                </tbody>
              </table>
              : null
          }
        </>
      ) }
    </Form.List>
  )
}
