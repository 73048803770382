export const translateDictionary: any = {
  by: 'By',
  the: 'The',
  of: 'Of',
  error: 'Error',
  done: 'Done',
  save: 'Save',
  reset: 'Reset',
  search: 'Search',
  sort: 'Sort',
  find: 'Find',
  rows: 'Rows',
  data: 'Data',
  value: 'Value',
  copy: 'Copy',
  copied: 'copied',
  created_at: 'Created at',
  updated_at: 'Updated at',
  status: 'Status',
  not_found: 'Not Found',
  current: 'Current',
  information_abbr: 'info',
  information: 'Information',
  save_success_message: 'saved successfully',
  is_required: 'is required',
  send: 'Send',
  new: 'New',
  add: 'Add',
  edit: 'Edit',
  view: 'View',
  remove: 'Remove',
  clear: 'Clear',
  more: 'More',
  sure_message: 'Are you sure?',
  remove_confirmation_message: 'Are you sure to remove',
  remove_success_message: 'removed successfully',
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  cancel: 'Cancel',
  exit: 'Exit',
  not_now: 'Not Now',
  continue: 'Continue',
  type: 'Type',
  select: 'Select',
  title: 'Title',
  description: 'Description',
  show: 'Show',
  settings: 'Settings',
  missing_field_message: 'Missing',
  wrong_message: 'Wrong',
  sure_to: 'Sure to',
  sure_to_cancel: 'Sure to cancel?',
  dashboard: 'Dashboard',
  appraisal: 'Appraisal',
  inventory: 'Inventory',
  order: 'Order',
  wedding_registry: 'Registry',
  wedding_number_abbr: 'Wedding No.',
  login_fail_message: 'Wrong Credentials',
  log_in: 'Log in',
  log_out: 'Log Out',
  login_by_google: 'log in with google',
  welcome_back: 'Welcome back!',
  name: 'Name',
  full_name: 'Full name',
  name_required_message: 'Please input your Name!',
  date: 'Date',
  detail: 'Detail',
  change_name: 'Change name',
  change_name_success_message: 'Your name changed successfully!',
  email: 'Email',
  password: 'Password',
  email_required_message: 'Please input your Email!',
  email_invalid_message: 'Email is invalid!',
  password_required_message: 'Please input your Password!',
  invalid_email_or_password: 'Invalid username or password',
  please_enter: 'Please enter',
  remember_me: 'Remember me',
  forgot_password: 'Forgot password',
  reset_password: 'Reset password',
  next: 'Next',
  previous: 'Previous',
  signup_fail_message: 'Wrong Signup',
  signup: 'Sign up',
  create_your_account: 'Create your account!',
  activate: 'Activate',
  activate_your_account_message: 'Please enter a code that sent to your email!',
  activation_success_message: 'Activation has done successfully',
  code: 'code',
  code_require_message: 'Please input your Code!',
  repeat_password: 'Repeat password',
  reset_password_fail_message: 'Wrong Reset password',
  reset_password_success_message: 'Your password changed successfully !',
  repeat_password_required_message: 'Please input your Repeat password!',
  password_and_repeat_password_are_not_equal:
    'Password and Repeat password are not equal',
  change_password: 'Change password',
  change_password_fail_message: 'Wrong change password',
  save_password: 'Save password',
  already_have_an_account: 'Already have an account ?',
  dont_have_an_account: 'Don’t have an account ?',
  profile: 'Profile',
  id: 'Id',
  prefix: 'Prefix',
  first_name: 'First name',
  last_name: 'Last name',
  prefix_required_message: 'Please select Prefix!',
  first_name_required_message: 'Please input First name!',
  last_name_required_message: 'Please input Last name!',
  products: 'Products',
  quantities: 'Quantities',
  reports: 'Reports',
  design: 'Design',
  entry_fail_message: 'Wrong',
  contact_entry_fail_message: 'Wrong Customer',
  remove_customer: 'Remove customer',
  remove_customer_success_question: 'Are your sure to remove customer?',
  remove_customer_success_message: 'The customer removed successfully',
  created_by: 'Created by',
  contact: 'Contact',
  contact_id: 'Contact ID',
  vendor: 'Vendor',
  customer: 'Customer',
  allow_contact: 'No Calendar',
  gender: 'Gender',
  company: 'Company',
  date_of_birth: 'Date of birth',
  anniversary: 'Anniversary',
  country_code: 'Country code',
  category: 'Category',
  subcategory: 'Sub Category',
  subcategory2: 'Sub Category 2',
  categories: 'Categories',
  phone: 'Phone',
  number: 'Number',
  number_abbr: 'No',
  address: 'Address',
  home_phone_short: 'Home',
  cell_phone_short: 'Cell',
  registry_number: 'Registry No',
  fax: 'Fax',
  extension_abbr: 'Ext',
  do_not_send_calendar: 'Do not send calendar',
  country: 'Country',
  state: 'State',
  city: 'City',
  street: 'Street',
  zip_code: 'Zip code',
  note: 'Note',
  notes: 'Notes',
  contact_group: 'Contact Group',
  contact_groups: 'Contact Groups',
  group: 'Group',
  group_id: 'Group ID',
  contact_category: 'Contact Category',
  contact_categories: 'Contact Categories',
  save_contact_category_success_message:
    'The Contact Category saved successfully',
  order_number_abbr: 'Order No',
  order_info: 'Order info',
  web_info: 'Web info',
  similar_contact_message:
    'The Contact is similar to the following contacts. Would you like to link them?',
  contact_links: 'Contact Links',
  ship: 'Ship',
  ship_to_same: 'Ship to same',
  item_category: 'Item category',
  item: 'Item',
  items: 'Items',
  item_number_abbr: 'Item No.',
  quantity_abbr: 'Qty',
  quantity_desired: 'Quantity Desired',
  price: 'Price',
  unit_price: 'Unit price',
  extended: 'Extended',
  quantity_available: 'Quantity Available',
  total_price: 'Total price',
  purchase: 'Purchase',
  purchase_order: 'Purchase order',
  receivables: 'Receivables',
  repair_abbr: 'REP',
  repair: 'Repair',
  take_with_abbr: 'T.W',
  back_order_abbr: 'B.O',
  amount: 'Amount',
  amounts: 'Amounts',
  freight: 'Freight',
  total: 'Total',
  total_payments_abbr: 'Total .Pay',
  balance: 'Balance',
  tax: 'Tax',
  last_pay: 'Last pay',
  last_pay_date: 'Last pay date',
  payment: 'Payment',
  special_instruction: 'Special Instruction',
  completion: 'Completion',
  pick: 'Pick',
  expected: 'Expected',
  disposition: 'Disposition',
  storage: 'Storage',
  version: 'Version',
  exact_match: 'Exact Match',
  web_site: 'Web Site',
  watch: 'Watch',
  jewelery: 'Jewelery',
  giftware: 'Giftware',
  sku: 'SKU',
  sku_not_found: 'The SKU was not found',
  style: 'Style',
  pattern: 'Pattern',
  catalog: 'Catalog',
  online: 'Online',
  mounting: 'Mounting',
  cost: 'Cost',
  list: 'List',
  store: 'Store',
  on_hand: 'On Hand',
  on_order: 'On Order',
  display: 'Display',
  back_order: 'Back Order',
  min: 'Min',
  max: 'Max',
  from: 'From',
  to: 'To',
  committed: 'Committed',
  quantity: 'Quantity',
  image: 'Image',
  images: 'Images',
  upload_image_message: 'Drag and drop images or Browse',
  upload_image_sub_message: 'Max 10MB each',
  upload: 'Upload',
  upload_more: 'Upload more',
  upload_empty_message: 'You have not uploaded anything yet',
  estate: 'Estate',
  weight_gr: 'Weight GR',
  weight_pw: 'Weight PW',
  labor_cost: 'Labor Cost',
  width: 'Width',
  length: 'Length',
  height: 'Height',
  ring_size: 'Ring size',
  serial_number_abbr: 'Serial No.',
  color: 'Color',
  case_color: 'Case color',
  case_number_abbr: 'Case No.',
  band: 'Band',
  face_color: 'Face Color',
  material: 'Material',
  movement: 'Movement',
  dial: 'Dial',
  shape: 'Shape',
  crystal: 'Crystal',
  china: 'China',
  discontinued: 'Discontinued',
  stone: 'Stone',
  stones: 'Stones',
  cert_number_abbr: 'Cert. No',
  parcel_number: 'Parcel no',
  average_weight: 'Average size',
  average_weight_abbr: 'Average wgt',
  total_weight: 'Total weight',
  total_weight_abbr: 'Total wgt',
  number_of_pieces: 'No of pieces',
  carats_uppr: 'CARATS',
  industry_color_code: 'Industry color code',
  color_code: 'Color code',
  cost_ct: 'Cost/Ct',
  price_ct: 'Price/Ct',
  industry_clarity_code: 'Industry clarity code',
  clarity: 'Clarity',
  clarity_code: 'Clarity code',
  rap: 'Rap',
  off: 'Off',
  memo: 'Memo',
  mount: 'Mount',
  sale: 'Sale',
  sales: 'Sales',
  salesman: 'SalesMan',
  year_to_date: 'Year to Date',
  last_year_to_date: 'Last Year',
  includes_the_related_groups: 'Includes the related groups?',
  account: 'Account',
  markup: 'Markup',
  report_file: 'Report file',
  report: 'Report',
  print: 'Print',
  designer: 'Designer',
  viewer: 'Viewer',
  percent_off: 'Percent off',
  promotion: 'Promotion',
  relations: 'Relations',
  relation: 'Relation',
  client: 'Client',
  appraiser: 'Appraiser',
  line: 'Line',
  parcel: 'Parcel',
  ours: 'Ours',
  in_set: 'In Set',
  component: 'Component',
  components: 'Components',
  lady: 'Lady',
  gentleman: 'Gentleman',
  chain_type: 'Chain type',
  clasp: 'Clasp',
  finger_size: 'Finger size',
  fore_color: 'For color',
  cut: 'Cut',
  make: 'Make',
  certificate: 'Certificate',
  certification: 'Certification',
  certification_abbr: 'Cert',
  carets: 'Carats',
  measurements: 'Measurements',
  lab: 'Lab',
  depht: 'Depht',
  table: 'Table',
  gridle: 'Gridle',
  culet: 'Culet',
  polish: 'Polish',
  synmetry: 'Symmetry',
  fluorecence: 'Fluorescence',
  vrn_package: 'VRN Package',
  discounted: 'Discontinued',
  tableware: 'Tableware',
  marketing: 'Marketing',
  many: 'Many',
  dollars: 'Dollars',
  sold: 'Sold',
  purchase_date_abbr: 'Date Pur.',
  pattern_number_abbr: 'Pattern No.',
  sequence: 'Sequence',
  mounts: 'Mounts',
  carats: 'Carats',
  dimensions: 'Dimensions',
  bride_groom: 'Bride/Groom',
  payment_method: 'MOP',
  card_number: 'Card No',
  card_expire_month: 'Exp. Month',
  card_expire_year: 'Exp. Year',
  authorization: 'Authorization',
  payments: 'Payments',
  city_state_zip: 'City/St/Zip',
  taxable: 'Taxable',
  number_not_found: 'The number not found',
  registry_no: 'Registry No',
  event_date: 'Event Date',
  pre_order: 'Pre_Order',
  customer_no: 'CustomerNo',
  Appraisal_no: 'AppraisalNo',
  Clerk: 'Clerk',
  spouse_2: 'Spouse # 2',
  NameFirst: 'Name-First',
  Last: 'Last',
  Phone: 'Phone',
  eMail: 'eMail',
  click_to_download: 'Click to download',
  in_stock_only: 'In Stock Only',
  show_pictures: 'Show Pictures ?',
  manufacture: 'Manufacture',
  management: 'Management',
  export_to_excel: 'Export to Excel',
  copy_item_confirmation: 'Are you sure to copy the current item ?',
  upload_images: 'Upload images',
  topic: 'Topic',
  shop: 'Shop',
  webhooks: 'Webhooks',
  logs: 'Logs',
  play: 'Play',
  pause: 'Pause',
  details: 'Details',
  printer: 'Printer',
  ip: 'IP',
  port: 'Port',
  request: 'Request',
  template_type: 'Template type',
  label: 'Label',
  created_on: 'Create on',
  processed_on: 'Processed on',
  finished_on: 'Finished on',
  update_items: 'Update items',
  attempts_made: 'attempts Made',
  input: 'Input',
  result: 'Result',
  retry: 'Retry',
  jobs: 'Jobs',
  ascending_abbr: 'ASC',
  descending_abbr: 'DESC',
  order_repair_item_description: 'REPAIR/ORDER',
  tag: 'Tag',
  new_print: 'New Print',
  new_report: 'New Report',
  clerk: 'Clerk',
  payment_type_report_title: 'Payment Type Detail Report',
  credit_card_report_title: 'Credit Card Detail Report',
  removing_repair_confirmation: 'Are you sure you want to remove the repair ?',
  work: 'Work',
  appointments: 'Appointments',
  when: 'When',
  additional_info: 'Additional Info',
}
