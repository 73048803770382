import { OrderEntry, OrderDto } from '../type'
import { resolveBoolean, resolveNumber } from '../../../core/until'

export const orderMapEntryToDto = (entry: OrderEntry): OrderDto => {
  return {
    date: entry.date,
    weddingRegistryId: entry.weddingRegistry?.id,
    customerId: entry.customer.id || 0,
    customerCell: entry.customerCell,
    customerHome: entry.customerHome,
    customerWork: entry.customerWork,
    customerEmail: entry.customerEmail,
    addresses: entry.addresses.map((add) => ({
      type: add.type,
      firstName: add.firstName,
      lastName: add.lastName,
      middleName: add.middleName,
      address1: add.address1,
      address2: add.address2,
      street: add.street,
      city: add.city,
      state: add.state,
      country: add.country,
      zipCode: add.zipCode
    })),
    theShipmentAddressIsTheSameAsBilling: Boolean(entry.theShipmentAddressIsTheSameAsBilling),
    brideGroomEnabled: Boolean(entry.brideGroomEnabled),
    lines: entry.lines.map((line) => ({
      itemId: line.item?.id || 0,
      isRepair: line.isRepair,
      repairVersion: line.repairVersion,
      quantity: resolveNumber(line.quantity),
      description: line.description,
      takeWithQuantity: resolveNumber(line.takeWithQuantity),
      backOrderQuantity: resolveNumber(line.backOrderQuantity),
      unitPrice: resolveNumber(line.unitPrice),
      taxable: resolveBoolean(line.taxable),
      onSale: resolveBoolean(line.onSale),
    })),
    freight: resolveNumber(entry.freight),
    specialInstruction: entry.specialInstruction || '',
    print: entry.print || false,
    repairs:
      entry.repairs?.map((e) => ({
        version: e.version,
        status: e.status,
        completionDate: e.completionDate,
        pickDate: e.pickDate,
        expectedDate: e.expectedDate,
        item: e.item,
        work: e.work,
        by: e.by,
        disposition: e.disposition,
        storage: e.storage,
        specialInstruction: e.specialInstruction || '',
      })) || [],
    payments:
      entry.payments?.map((e): typeof e => ({
        date: e.date,
        method: e.method,
        cardNumber: e.cardNumber,
        expireYear: resolveNumber(e.expireYear),
        expireMonth: resolveNumber(e.expireMonth),
        amount: resolveNumber(e.amount),
        authorizationCode: e.authorizationCode,
      })) || [],
    clerk: entry.clerk,
  }
}
