import { Item } from '../../inventory/type';

export const getOrderPrice = (item: Item): {price: number, onSale: boolean} => {
  const start = new Date(item.saleStartDate),
    end = new Date(item.saleEndDate),
    now = new Date()
  if (start) {
    if (now >= start && now <= end)
      return {price: item.oursSale || 0, onSale: true}
  }
  return {price: item.oursPrice || 0, onSale: false}
}
