import { Webhook } from '../type/webhook'
import { httpRequest } from '../../../core'
import { HttpMethod, PageableRequest, PageableResponse } from '../../../core/type'

export const useWebhookData = () => {
  return {
    get: (): Promise<Webhook[]> =>
      httpRequest.execute<Webhook[]>({
        method: HttpMethod.GET,
        url: 'shop/webhooks',
      }),
    activate: (id: number): Promise<void> =>
      httpRequest.execute<void>({
        url: `shop/webhooks/${id}/activate`,
        method: HttpMethod.PUT,
      }),
    deactivate: (id: number): Promise<void> =>
      httpRequest.execute<void>({
        url: `shop/webhooks/${id}/deactivate`,
        method: HttpMethod.PUT,
      }),
    logs: (request: PageableRequest) =>
      httpRequest.execute<PageableResponse<Webhook>>({
        method: HttpMethod.GET,
        url: 'shop/logs/webhook',
        params: request,
      }),
  }
}