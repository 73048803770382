import React from 'react';
import { Empty } from 'antd';

export const ShowIf = ({ children, condition, replacement }: ShowIfProps) => {
  return (
    <>
    {
      condition
        ? children
        : replacement || <Empty/>
    }
    </>
  )
}

export interface ShowIfProps {
  condition: boolean
  children: React.ReactNode
  replacement?: React.ReactNode
}
