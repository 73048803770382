import { Order, OrderAddress, OrderLine } from '../type'
import { AddressType } from '../../contact/type/contact'
import { DEFAULT_TAX_VALUE } from '../../../App.constants'

export const orderInitializeEntity = async (
  getNewNumber: () => Promise<number>
): Promise<Order> => {
  return {
    number: await getNewNumber(),
    date: new Date(),
    theShipmentAddressIsTheSameAsBilling: false,
    addresses: [ AddressType.Billing, AddressType.Shipping ].map<Partial<OrderAddress>>((type) => ({
      type,
    })),
    lines: [] as OrderLine[],
    tax: 0,
    freight: 0,
    taxRate: DEFAULT_TAX_VALUE
  } as Order
}
