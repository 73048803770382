import { translate } from '../../../core';
import { useState } from 'react';
import { Alert } from 'antd';
import { ResetPasswordEmailEntry } from './ResetPasswordEmailEntry';
import { ResetPasswordEntry } from './ResetPasswordPasswordEntry';
import { ResetPasswordSuccess } from './ResetPasswordSuccess';
import { useNavigate } from 'react-router-dom';

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [ view, setView ] = useState('emailEntry');
  const [ errorMessage, setErrorMessage ] = useState<string>('');

  return (
    <div className="flex-column align-items-center">
      <h1>{ translate('reset_password') }</h1>

      {
        errorMessage.length > 0 &&
        <Alert
          className="mt-5"
          message={ translate('reset_password_fail_message') }
          description={ errorMessage }
          type="error"
          showIcon
        />
      }

      <div className="mt-3">
        {
          view === 'emailEntry' &&
          <ResetPasswordEmailEntry
            onCompleted={ () => setView('passwordEntry') }
            setErrorMessage={ setErrorMessage }
          />
        }

        {
          view === 'passwordEntry' &&
          <ResetPasswordEntry
            onCompleted={ () => setView('success') }
            setErrorMessage={ setErrorMessage }
          />
        }

        {
          view === 'success' &&
          <ResetPasswordSuccess onCompleted={ () => navigate('/login') }/>
        }
      </div>
    </div>
  )
}
