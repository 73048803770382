import { translate } from '../../../core';
import { Button, Form, Input } from 'antd';
import styles from './Auth.module.scss';
import { BadRequestException, CompletionProps, SetErrorMessageProps } from '../../../core/type';
import { useState } from 'react';
import { userService } from '../../user/service';

export const ResetPasswordEntry = ({ onCompleted, setErrorMessage }: ResetPasswordEntryProps) => {
  const [ loading, setLoading ] = useState<boolean>(false);

  const onEnter = async ({ password, repeatPassword, code }: ResetPasswordEntryDto) => {
    if (password !== repeatPassword) {
      setErrorMessage(translate('password_and_repeat_password_are_not_equal'));
      return;
    }

    setLoading(true);

    try {
      await userService.changePasswordByCode({ code, newPassword: password });
      onCompleted();
    } catch (e) {
      if (e instanceof BadRequestException)
        setErrorMessage(e.message);

    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Form
        onFinish={ onEnter }
        layout="vertical"
        name="normal_login"
        className="login-form"
        initialValues={ { remember: true } }
      >
        <Form.Item
          label={ translate('code') }
          name="code"
          rules={ [ { required: true, message: translate('code_required_message') } ] }
        >
          <Input size="large"/>
        </Form.Item>

        <Form.Item
          label={ translate('password') }
          name="password"
          rules={ [ { required: true, message: translate('password_required_message') } ] }
        >
          <Input size="large"
                 type="password"
          />
        </Form.Item>

        <Form.Item
          label={ translate('repeat_password') }
          name="repeatPassword"
          rules={ [ { required: true, message: translate('repeat_password_required_message') } ] }
        >
          <Input size="large"
                 type="password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={ loading }
            block
            size="large"
            htmlType="submit"
            className={ styles.submitButton }>
            { translate('next') }
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

interface ResetPasswordEntryProps extends CompletionProps, SetErrorMessageProps {
}

interface ResetPasswordEntryDto {
  code: string;
  password: string;
  repeatPassword: string;
}

