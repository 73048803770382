import { FormEntryField, PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Item, UploadingStatus } from '../../inventory/type'
import React, { useContext, useState } from 'react'
import { AppraisalEntry } from '../type'
import { AppraisalPageContext } from './AppraisalPageContext'
import { Combo } from '../../../core/componet'
import { Button, Divider, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { ItemSelectNew } from '../../inventory/component/ItemSelectNew'
import { useLazyQuery } from '@apollo/client'
import { ItemFindRequest } from '../../inventory/type/item-query'
import { GetItemsForLookup } from '../../inventory/graphql/item.graphql'

export const AppraisalFormLinesItem = ({onChange, value, row}: FormEntryField<number> & { row: number }) => {
  const {form} = useContext<PageContextValue<any, AppraisalEntry>>(AppraisalPageContext)
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false)
  const [find] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForLookup)

  const onItemChanged = async (item: Item) => {
    form?.setFieldValue(['lines', row, 'amount'], item.oursPrice || 0)
    form?.setFieldValue(['lines', row, 'description'], item.catalogDescription)
    form?.setFieldValue(['lines', row, 'itemId'], item.id)

    if (!item.images?.length)
      return

    const [image] = item.images

    form?.setFieldValue(['lines', row, 'image'], {
      status: UploadingStatus.Uploaded,
      item: image,
      name: image.originalFilename,
      url: image.filename,
    })
  }

  return (
    <>
      <Combo<Item, number>
        value={value}
        onSelect={(_, e) => onItemChanged(e)}
        onChange={(value) => onChange!(value as number)}
        fetch={async ({request, value, search}) => {
          const {data} = await find({
            variables: {
              request: {
                id: value,
                sku: search,
                skip: request?.skip ?? 0,
                take: request?.take ?? 10,
                ...request,
                showNoEffects: true,
              },
            },
          })

          const list = data?.itemsFind.data ?? []

          return list.map(e => ({
            value: e.id || 0,
            display: e.sku,
            item: e || {},
          }))
        }}
        dropdownRender={menu => (
          <>
            <Space direction="vertical">
              <Button
                onClick={() => setOpenCreateDialog(true)}
                className="m-0"
                icon={<PlusOutlined/>}>
                {translate('new')}
              </Button>
            </Space>

            <Divider style={{margin: '8px 0'}}/>
            {menu}
          </>
        )}
      />
      <ItemSelectNew
        open={openCreateDialog}
        close={() => setOpenCreateDialog(false)}
        onSaveCompleted={async (id: number) => {
          onChange!(id)
          setOpenCreateDialog(false)
          const {data} = await find({
            variables: {
              request: {
                id,
                take: 1,
                skip: 0,
              },
            },
          })
          const [item] = data?.itemsFind.data ?? []
          await onItemChanged(item)
        }}
      />
    </>
  )
}
