import Enumerable from 'linq'
import { WeddingRegistry } from '../type'
import { OrderLine } from '../../order/type'

export const weddingCalculatePrice = (itemId: number, weddingRegistry: WeddingRegistry | null | undefined): number | null => {
  if (!weddingRegistry)
    return null

  if (!weddingRegistry.preOrdered)
    return null

  const registryItem = weddingRegistry?.lines?.find(e => e.item?.id === itemId)

  if (!registryItem)
    return null

  return registryItem.unitPrice || null
}

export const weddingReformOrderPrices = (weddingRegistry: WeddingRegistry, orderLines: OrderLine[]): void => {
  const orderLinesWhichHaveItem = orderLines.filter(ol => ol.item)

  if (!orderLinesWhichHaveItem.length)
    return

  Enumerable.from(weddingRegistry.lines)
    .join<OrderLine, number, { orderLine: OrderLine, price: number }>
    (orderLinesWhichHaveItem,
      wr => wr.item.id || 0,
      ol => ol.item?.id || 0,
      (wd, orderLine) => ({ orderLine, price: wd.unitPrice }))
    .forEach(({ orderLine, price }) => {
      orderLine.unitPrice = price
    })
}
