import {resolveNumber} from "../../../core/until"
import {SpouseType, WeddingRegistryDto, WeddingRegistryEntry, WeddingRegistrySpouse} from "../type"

export const weddingRegistryEntryToDto = (entry: WeddingRegistryEntry): WeddingRegistryDto => {
  const [billingAddress] = entry.addresses
  const {otherSpouse} = entry

  return {
    weddingDate: entry.weddingDate,
    orderDate: entry.orderDate,
    customerId: entry.customer.id,
    preOrdered: !!entry.preOrdered,
    spouses: [
      {
        type: SpouseType.Bride,
        firstName: billingAddress?.firstName,
        lastName: billingAddress?.lastName,
        middleName: billingAddress?.middleName,
        homePhone: entry.phone,
        email: entry.email,
      },
      {
        type: SpouseType.Groom,
        firstName: otherSpouse.firstName,
        lastName: otherSpouse.lastName,
        middleName: otherSpouse.middleName,
        homePhone: otherSpouse.homePhone,
        email: otherSpouse.email,
      },
    ] as WeddingRegistrySpouse[],
    addresses: entry.addresses.map(ad => ({
      type: ad.type,
      address1: ad.address1,
      address2: ad.address2,
      street: ad.street,
      city: ad.city,
      state: ad.state,
      country: ad.country,
      zipCode: ad.zipCode,
    })),
    lines: entry.lines.map((line) => ({
      itemId: line.item.id,
      quantity: resolveNumber(line.quantity),
      description: line.description,
      unitPrice: resolveNumber(line.unitPrice),
    })),
    showSpecialInstructions: true,
    showOnWeb: !!entry.showOnWeb
  }
}
