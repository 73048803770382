import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { Table, TableProps } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { DataGridProps, DataGridRef, DataGridType, DataGridItem, TableParameters } from './type';
import { initTableParameters, DATA_GRID_NEW_KEY } from './resolver';

export const DataGrid = forwardRef(
  <T extends DataGridItem>(props: DataGridProps<T>, ref: Ref<DataGridRef<T>>) => {
    const {
      fetch,
      columns,
      rowSelection,
      onSelect,
      rowClassName,
      components
    } = props;
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState<T[]>();
    const [ tableParameters, setTableParameters ] = useState<TableParameters>(initTableParameters());
    const [ forceRefresh, setForceRefresh ] = useState<boolean>(false);
    const tableProps: TableProps<T> = {};

    useImperativeHandle(ref, () => ({
      refresh() {
        setForceRefresh(true);
      },
      addRow() {
        const newRow = { key: DATA_GRID_NEW_KEY } as T;

        setData([
          newRow,
          ...data || [],
        ]);

        return newRow;
      },
      removeRow(key: React.Key) {
        setData([
          ...(data || []).filter(e => e.key !== key)
        ])
      }
    }));

    tableProps.loading = loading;
    tableProps.columns = columns;
    tableProps.dataSource = data;
    tableProps.pagination = tableParameters.pagination;
    tableProps.onChange = (pagination: TablePaginationConfig) => {
      setTableParameters({
        ...tableParameters,
        pagination
      });
    };
    tableProps.onRow = (record, index) => ({
      onClick: () => onSelect && onSelect(record as T, index || 0)
    });

    if (rowSelection)
      tableProps.rowSelection = {
        type: rowSelection.type || 'radio',
        onSelect: (_, __, selectedRows) => {
          rowSelection?.onSelect(selectedRows);
        }
      };

    if (rowClassName)
      tableProps.rowClassName = rowClassName;

    if (components)
      tableProps.components = components;

    const load = async () => {
      const { pagination } = tableParameters;

      setLoading(true);

      const result = await fetch({
        take: pagination?.pageSize || 10,
        skip: ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10)
      });

      setLoading(false);
      setData(result.data);
      setTableParameters({
        ...tableParameters,
        pagination: {
          ...tableParameters.pagination,
          total: result.count
        }
      });
      setForceRefresh(false);
    }

    useEffect(() => {
      load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ JSON.stringify(tableParameters), forceRefresh ]);

    return (
      <Table
        { ...tableProps }
      />
    )
  }
) as DataGridType;






