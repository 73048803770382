import { BadRequestException, HttpStatus, NotFoundException } from './type';
import { authService } from '../app/auth/service';

export const exceptionHandler = ({
                                   status,
                                   message,
                                   error
                                 }: { error: any, status: HttpStatus, message: string | string[] }): any => {
  let exception;

  if (status === HttpStatus.Unauthorized)
    authService.logout()

  if (status === HttpStatus.NotFound)
    exception = new NotFoundException();

  if (status === HttpStatus.BadRequest)
    exception = new BadRequestException(message);

  return exception || error
}
