import { gql } from '@apollo/client';

export const GetOrderItemHistoryDocument = gql`
    query GetOrderItemHistory($itemId: Int!) {
        orderItemHistory(itemId: $itemId)
    }
`

export const GetSalesByCustomerDocument = gql`
    query GetSalesByCustomer($request: OrderSalesByCustomerRequest!) {
        salesByCustomer(request: $request)
    }
`

export const GetOrderPaymentsDocuments = gql`
    query GetOrderPayments($request: OrderPaymentsReportRequest!) {
        orderPayments(request: $request)
    }
`

export const GetSalesByVendorDocument = gql`
    query getSalesByVendor($request: OrderSalesVendorReportRequest!) {
        salesByVendor(request: $request)
    }
`
