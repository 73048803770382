import {Table, TableProps} from "antd";

import React, {FC, useEffect, useState} from 'react';

import styles from "./OrderPayments.module.scss"
import {IGetOrderReport, IOrderReportData} from "../type/order-payments-report";
import {dateDisplay, dateFormatSlashed} from "../../../../core/until";
import {chunkArray} from "../../../../core/until/print.utils";

const itemColumns : TableProps<IOrderReportData>['columns'] = [
    {
        title: 'PayMet',
        dataIndex: 'paymentMethod',
        width: '10%',
        render: (text, row) => !row.total ? text : <div className='d-flex justify-content-between mb-3 '>
            <label>Payment Method: {row.paymentMethod}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.total ? 2 : 1,
        }),
    },  {
        title: 'CardNo',
        dataIndex: 'cardNumber',
        width: '30%',
        onCell: (text) => ({
            colSpan: text.total ? 0 : 1,
        }),
    },{
        title: 'AmountPay',
        dataIndex: 'amount',
        width: '20%',
        render: (text, row) => text ? text : <div className='d-flex justify-content-between mb-3 '>
            <label>Totals for Customer: {row.total}</label>
        </div>,
        onCell: (text) => ({
            colSpan: text.total ? 3 : 1,
        }),
    },
    {
        title: 'ApprovalCode',
        dataIndex: 'approvalCode',
        width: '30%',
        onCell: (text) => ({
            colSpan: text.total ? 0 : 1,
        }),
    },{
        title: 'OrderNo',
        dataIndex: 'orderNumber',
        width: '10%',
        onCell: (text) => ({
            colSpan: text.total ? 0 : 1,
        }),
    },
]



interface IProps {
    data?: IGetOrderReport
}

const OrderPaymentsReport: FC<IProps> = ({data}) => {

    const [itemsTable, setItemsTable] = useState<any[]>([]);

    useEffect(() => {
        if(data) {
            const printData = data.orderPayments;
            const flatArray = printData.flatMap(item => {
                return [...item.lines, {
                    total: item.total,
                    paymentMethod: item.paymentMethod
                }];
            });
            const chunks = chunkArray(flatArray, 40);
            setItemsTable(chunks)
        }
    }, [data]);

    useEffect(() => {
        if (itemsTable.length > 0) {
            window.print()
            setItemsTable([])
        }
    }, [itemsTable])

    const Header = <header className='d-flex flex-column gap-2 report-header'>
        <div className='d-flex justify-content-between'>
            <span>Order Payments</span>
            <span>Date: { dateDisplay(new Date(), dateFormatSlashed)}</span>
        </div>
        <Table
            dataSource={[]}
            columns={itemColumns}
            pagination={false}
        />
    </header>
    return(
        <div className="print-show print-page-report">
            <div className={styles['report']}>
            <main>
                {itemsTable.map((item) =>   {
                    return <main className='page-break'>
                        {Header}
                        <Table
                            key={'orderNumber'}
                            dataSource={item as any}
                            columns={itemColumns}
                            pagination={false}
                            rowKey='orderNumber'
                            className='itemTable'
                        />
                    </main>
                })}
            </main>
            </div>
        </div>
    );
};
export default OrderPaymentsReport;



