import { gql } from '@apollo/client'

export const GetContactCategories = gql`
    query GetContactCategories($request: ContactCategoryFindRequest!) {
        contactCategoriesFind(request: $request) {
            data {
                id
                title
            }
            count
        }
    }
`

export const GetContactCategoriesLookup = gql`
    query GetContactCategoriesLookup($request: ContactCategoryFindRequest!) {
        contactCategoriesFind(request: $request){
            data {
                id
                title
            }
        }
    }
`