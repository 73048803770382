import { Combo } from "../../../core/componet"
import {FormEntryField, QueryPageableRequest, QueryPageableResponse} from "../../../core/type"
import { User } from "../../user/type"
import { useLazyQuery } from '@apollo/client'
import {UserFindRequest} from '../../user/type/user-query'
import {GetUsers} from '../../user/graphql/user-queries.graphql'

export const AppraisalFormUser = ({ onChange, value, }: FormEntryField<number>) => {
  const [ getUsers ] = useLazyQuery<
    QueryPageableResponse<User, 'usersFind'>,
    QueryPageableRequest<UserFindRequest>>(GetUsers)

  return (
    <Combo<User, number>
      style={ { width: "8rem" } }
      value={ value }
      onChange={ (value) => onChange!(value as number) }
      fetch={ async ({ request, value, search }) => {
        const { data } = await getUsers({
          variables: {
            request: {
              take: request?.take ?? 100,
              skip: request?.skip ?? 0,
              name: search ?? '',
              id: value,
            }
          }
        })

        const list = data?.usersFind.data ?? []

        return list.map((e) => ({
          value: e.id ?? 0,
          display: e.name,
          item: e ?? {},
        }))
      } }
    />
  )
}
