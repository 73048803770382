import { FormEntryField } from '../../../core/type'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { confirm, translate } from '../../../core'

export const OrderFormRepairCheckBox = ({onChange, checked}: FormEntryField<boolean>) => {
  const handleChange = async (e: CheckboxChangeEvent) => {
    const {checked: updatedChecked} = e.target

    if(!e.target.checked) {
      const result = await confirm(
        translate('removing_repair_confirmation')
      )

      if(!result)
        return
    }

    onChange!(updatedChecked)
  }

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
    />
  )
}