import { Webhook } from '../../shop/type/webhook'
import { httpRequest } from '../../../core'
import { HttpMethod, PageableResponse } from '../../../core/type'
import { Printer, PrinterDto, PrinterEntry, PrinterPintDto } from '../type'

export const usePrinterData = () => {
  return {
    toEntry: (entity: Printer): PrinterEntry => ({
      ...entity,
      key: entity.id.toString(),
    }),
    toDto: (entry: PrinterEntry): PrinterDto => ({
      title: entry.title,
      ip: entry.ip,
      port: entry.port,
      templateType: entry.templateType,
    }),
    create: (dto: PrinterDto): Promise<void> =>
      httpRequest.execute<void>({
        url: `printers`,
        method: HttpMethod.POST,
        body: dto,
      }),
    update: (id: number, dto: PrinterDto): Promise<void> =>
      httpRequest.execute<void>({
        url: `printers/${id}`,
        method: HttpMethod.PUT,
        body: dto,
      }),
    remove: (id: number) =>
      httpRequest.execute<PageableResponse<Webhook>>({
        method: HttpMethod.DELETE,
        url: `printers/${id}`,
      }),

    print: (dto: PrinterPintDto) => httpRequest.execute<void>({
      url: `printers/print`,
      method: HttpMethod.POST,
      body: dto,
    }),
  }
}