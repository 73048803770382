import { MenuOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export const ToggleMenuButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      type='text'
      shape='circle'
      size='large'
      onClick={ onClick }>
      <MenuOutlined style={ { fontSize: 26 } }/>
    </Button>
  );
}
