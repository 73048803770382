import React, { useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { BadRequestException, QueryPageableRequest, QueryPageableResponse } from '../../../../core/type'
import { Printer, PrinterEntry, PrinterTemplateType } from '../../type'
import {
  Combo,
  DataGrid, DataGridEditableCell,
  DataGridRef,
  editableActions,
  editableColumn, enumToComboOptionTransformer,
  NumericInput,
  useDataGridEditable
} from '../../../../core/componet'
import { GetPrintersDocument } from '../../graphql/printer.query.graphql'
import { usePrinterData } from '../../hook/printer-data.hook'
import { DATA_GRID_NEW_KEY } from '../../../../core/componet/DateGrid/resolver'
import { notify, translate } from '../../../../core'
import { Button, Form, Input, Space, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { confirm } from '../../../../core'
import { PrinterSelector } from './PrinterSelector'

export const PrinterList = () => {
  const dataGridRef = useRef<DataGridRef<PrinterEntry>>(null)
  const [saving, setSaving] = useState<boolean>(false)
  const [find] = useLazyQuery<
    QueryPageableResponse<Printer, 'printersFind'>,
    QueryPageableRequest<{ search?: string }>>(GetPrintersDocument)
  const {toDto, toEntry, create, update, remove} = usePrinterData()
  const [openSelector, setOpenSelector] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const onSave = async (key: React.Key, row: PrinterEntry) => {
    setSaving(true)
    const dto = toDto(row)

    try {
      if (key === DATA_GRID_NEW_KEY)
        await create(dto)
      else
        await update(row.id, dto)

      notify.success(
        translate('printer'),
        translate('the', 'printer', 'save_success_message'))

      dataGridRef.current?.refresh()
      markAsCompleted()
    } catch (e) {
      if (e instanceof BadRequestException)
        notify.error(
          translate('wrong_message', 'printer'),
          [e.message].flat().join('<br/>')
        )
    } finally {
      setSaving(false)
    }
  }

  const handleRemove = async ({id}: PrinterEntry) => {
    const result = await confirm(
      translate('remove_confirmation_message', 'printer', '?')
    )

    if (!result)
      return

    await remove(id)

    notify.success(
      translate('remove', 'printer'),
      translate('printer', 'remove_success_message')
    )

    dataGridRef.current?.refresh()
  }

  const handleSearch = (term: string)=> {
    setSearchTerm(term)
    dataGridRef.current?.refresh()
  }

  const {
    form,
    editingKey,
    isEditing,
    cancel,
    edit,
    save,
    markAsCompleted
  } = useDataGridEditable<PrinterEntry>(dataGridRef, onSave)

  return (
    <div className='p-2'>
      <div className='d-flex'>
        <Space className='w-100 justify-content-start p-2'>
          <Input.Search
            allowClear
            onSearch={handleSearch}
            placeholder={translate('search')}
            style={{width: 200}}
          />
        </Space>
        <Space className='w-100 justify-content-end p-2'>
          <Tooltip title={translate('add')}>
            <Button
              icon={<PlusOutlined/>}
              onClick={() => {
                const row = dataGridRef.current?.addRow()
                edit(row ?? {})
              }}>
            </Button>
          </Tooltip>

          <Button onClick={() => setOpenSelector(true)}>
            List
          </Button>
        </Space>
      </div>

      <PrinterSelector
        open={openSelector}
        onClose={() => setOpenSelector(false)}
        onSelect={(item) => {
          alert(JSON.stringify(item, null, 2))
        }}
      />
      <Form form={form} component={false}>
        <DataGrid<PrinterEntry>
          ref={dataGridRef}
          columns={[
            {
              dataIndex: 'id',
              title: translate('id'),
              width: 100,
            },
            editableColumn({
              dataIndex: 'title',
              title: translate('title'),
              width: 250,
              editableComponent: <Form.Item name='title' rules={[{
                required: true,
                message: translate('missing_field_message', 'title')
              }]}>
                <Input style={{width: '90%'}}/>
              </Form.Item>,
              isEditing
            }),
            editableColumn({
              dataIndex: 'ip',
              title: translate('ip'),
              width: 200,
              editableComponent: <Form.Item name='ip' rules={[{
                required: true,
                message: translate('missing_field_message', 'ip')
              }]}>
                <Input style={{width: '90%'}}/>
              </Form.Item>,
              isEditing
            }),
            editableColumn({
              dataIndex: 'port',
              title: translate('port'),
              width: 150,
              editableComponent: <Form.Item name='port' rules={[{
                required: true,
                message: translate('missing_field_message', 'port')
              }]}>
                <NumericInput style={{width: '90%'}} decimalPlaces={0}/>
              </Form.Item>,
              isEditing
            }),
            editableColumn({
              dataIndex: 'templateType',
              title: translate('template_type'),
              width: 100,
              editableComponent: <Form.Item name='templateType'>
                <Combo
                  options={enumToComboOptionTransformer(PrinterTemplateType)}
                />
              </Form.Item>,
              isEditing
            }),
            editableActions(
              editingKey,
              saving,
              isEditing,
              cancel,
              edit,
              save,
              handleRemove,
            )
          ]}
          fetch={async (request) => {
            const {data} = await find({
              variables: {
                request: {
                  ...request,
                  search: searchTerm.trim() || undefined,
                },
              }
            })

            const list = data?.printersFind.data ?? []
            const count = data?.printersFind.count ?? 0

            return {
              data: list.map(toEntry),
              count,
            }
          }}
          components={{
            body: {
              cell: DataGridEditableCell
            }
          }}
        />
      </Form>
    </div>
  )
}
