import React from 'react';

export const Section = ({ children, header, noMargin }: SectionProps) => {
  return (
    <div className={ 'ps-2 pe-2 rounded ' + (noMargin ? '' : ' mt-4 ') }>
      <span className='fw-bold fs-6'>{ header }</span>
      { children }
    </div>)
}

export interface SectionProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  noMargin?: boolean;
}
