import React from 'react';
import { Spin } from 'antd';

export const Disable = ({ disable, children }: DisableProps) => {
  return (
    <Spin spinning={ disable } indicator={ <i></i> }>
      { children }
    </Spin>
  )
}

export interface DisableProps {
  disable: boolean;
  children: React.ReactNode;
}
