import { combineReducers } from '@reduxjs/toolkit';
import { currentUserReducer } from './currentUser';
import { appConfigurationReducer } from './appConfiguration';
import { reportViewReducer } from './reportViewState';

export const combinedReducer = combineReducers({
  currentUser: currentUserReducer,
  appConfiguration: appConfigurationReducer,
  reportViewState: reportViewReducer,
});

export { setCurrentUser } from './currentUser';
export { setSideBarMenuEnabled } from './appConfiguration'
export {setReportViewState} from './reportViewState'
