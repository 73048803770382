import { Button, Divider, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { translate } from '../../../core';
import { Combo, enumToComboOptionTransformer } from '../../../core/componet';
import { PhoneType } from '../type/contact';
import { CloseButton } from 'react-bootstrap';

export const ContactEntryPhones = () => {
  return (
    <Form.List name="phones">
      { (fields, { add, remove }) => (
        <>
          <Divider
            orientation='left'
            className='fw-bold my-1'>
            <span>
              { translate('phone') }
            </span>

            <Button
              className='ms-3'
              type="default"
              onClick={ () => add() }
              icon={ <PlusOutlined/> }>
              { translate('add', 'phone') }
            </Button>
          </Divider>

          {
            fields.length
              ? <table className='table table-borderless mb-1'>
                <thead>
                <tr>
                  <td className='text-center py-0' style={ { width: '25%' } }>{ translate('type') }</td>
                  <td className='text-center py-0' style={ { width: '25%' } }>{ translate('country_code') }</td>
                  <td className='text-center py-0' style={ { width: '40%' } }>{ translate('number') }</td>
                  <td className='text-center py-0' style={ { width: '10%' } }>{ translate('extension_abbr') }</td>
                  <td></td>
                </tr>
                </thead>

                <tbody>
                { fields.map(({ key, name, ...restField }) => (
                  <tr key={key}>
                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'type' ] }
                      >
                        <Combo
                          placeholder={ translate('select', 'type') }
                          size='middle'
                          style={ { width: '100%' } }
                          options={ enumToComboOptionTransformer(PhoneType) }
                        />
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'countryCode' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'number' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Form.Item
                        className='mb-1'
                        name={ [ name, 'extension' ] }
                      >
                        <Input/>
                      </Form.Item>
                    </td>

                    <td className='py-0'>
                      <Button
                        icon={ <CloseButton/> }
                        shape='circle'
                        type='text'
                        onClick={ () => remove(name) }
                      />
                    </td>
                  </tr>
                )) }
                </tbody>
              </table>
              : null
          }
        </>
      ) }
    </Form.List>
  )
}
