import React, { FC, useState } from 'react'
import { GetProp, TreeSelect, TreeSelectProps } from 'antd'
import { useQuery } from '@apollo/client'
import { FormEntryField, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ItemCategory, ItemCategoryFindRequest, ItemCategoryLevel } from '../type'
import { GetItemCategoriesForLookup } from '../graphql/item-category.graphql'
import Enumerable from 'linq'

type OptionType = GetProp<TreeSelectProps, 'treeData'>[number] & { level: ItemCategoryLevel }

export const ItemSearchCategory: FC<FormEntryField<{
  categoryId?: number[],
  subcategoryId?: number[],
  subcategory2Id?: number[]
}> & { disabled?: boolean }> = ({value, onChange, disabled}) => {
  const [list, setList] = useState<OptionType[]>()
  const {loading} = useQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategoriesForLookup, {
    variables: {
      request: {
        take: 10000,
        skip: 0,
      },
    },
    onCompleted: data => setList(
      data?.itemCategoriesFind.data.map<OptionType>(it => ({
        id: it.id,
        value: it.id,
        pId: it.parent?.id,
        title: it.title,
        level: it.level,
      }))
    )
  })

  const handleFilter = (input: string, treeNode: OptionType): boolean => {
    const term = input.toLowerCase()
    return (treeNode.title ?? '').toString().toLowerCase().includes(term)
  }

  const handleChange = (value: number[]) => {
    console.log(value)
    const newValue = Enumerable.from((list ?? []).filter(it => value.map(Number).includes(it.id)))
      .groupBy(
        it => it.level,
        it => it,
        (level: ItemCategoryLevel, items) => ({
          name: `${level.toLowerCase()}Id`,
          items: items.toArray()
        })
      ).toObject(
        it => it.name,
        it => it.items.map(e => e.id)
      )
    console.log(newValue
    )
    onChange!(newValue)
  }

  const handleDeselect = (deletedValue: string) => {
    const exp = (id: number) => id !== Number(deletedValue)
    onChange!({
      categoryId: (value?.categoryId ?? []).filter(exp),
      subcategoryId: (value?.subcategoryId ?? []).filter(exp),
      subcategory2Id: (value?.subcategory2Id ?? []).filter(exp),
    })
  }

  return (
    <TreeSelect<string[]>
      disabled={disabled}
      value={[
        ...value?.categoryId ?? [],
        ...value?.subcategoryId ?? [],
        ...value?.subcategory2Id ?? [],
      ].map(value => value.toString())}
      treeLine
      multiple
      loading={loading}
      className='w-100'
      treeDataSimpleMode
      treeData={list}
      allowClear
      showSearch
      filterTreeNode={handleFilter as any}
      onChange={handleChange as any}
      onDeselect={handleDeselect}
    />
  )
}