import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { memory } from '../index'
import { TokenResponse } from '../type'
import { AUTHENTICATION_TOKEN } from '../../App.constants'
import { DocumentNode } from '@apollo/client/link/core/types'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { exceptionHandler } from '../exception-handler'
import { authService } from '../../app/auth/service';

const httpLink = createHttpLink({
  uri: `${ process.env.REACT_APP_API_BASE_URL }/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = memory.get<TokenResponse>(AUTHENTICATION_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `${ token.token_type } ${ token.access_token }` : "",
    }
  }
})

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    for (const { message } of graphQLErrors) {
      if (message === 'Unauthorized') {
        authService.logout(true)
        return
      }
    }
})

export const client = new ApolloClient({
  link: from([ authLink, errorLink, httpLink ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
})

export const executeQuery = async ({
                                     documentNode,
                                     variables
                                   }: { documentNode: DocumentNode, variables: any }): Promise<any> => {
  try {
    const response: ApolloQueryResult<any> = await client.query({
      query: documentNode,
      variables
    })

    if (response.errors?.length)
      // eslint-disable-next-line no-throw-literal
      throw { graphQLErrors: response.errors }

    return response.data
  } catch (e: any) {
    const [ { extensions } ] = e.graphQLErrors
    const { response } = extensions

    throw exceptionHandler({
      status: response?.statusCode,
      message: response?.message,
      error: e
    })
  }
}



