import { UploadFile } from 'antd';
import { ItemImage } from './item';

export enum UploadingStatus {
  Uploaded = 'Uploaded',
  Pending = 'Pending'
}

export interface ImageEntry {
  status: UploadingStatus;
  file?: UploadFile;
  item?: ItemImage;
  name: string;
  url?: any;
}
