export interface ReportVariable {
  name: string;
  alias: string;
  category: ReportVariableCategory;
  value: any;
}

export enum ReportVariableCategory {
  General = 'general'
}

export type ReportViewState = {
  open: boolean
  fileName: string
  data: any
  loading: boolean
  title: string
}

export interface ReportConfiguration {
  variables: ReportVariable[]
}

export type ReportArgs = Omit<ReportViewState, 'open' | 'loading'>  & {
  loading?: boolean
}


