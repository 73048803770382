import Enumerable from 'linq'

export const getCodedPrice = (price: number, removeDecimals: boolean = false) => {
  const [integer, decimals] = (price ?? 0).toString().split('.')

  const codedInteger = integer.split('')
    .map(number => priceCodes[Number(number)])
    .join('')

  const codedDecimals = removeDecimals
    ? ''
    : `.${resolveDecimals(decimals)}`

  return `${codedInteger}${codedDecimals}`
}

const resolveDecimals = (decimals: string) =>
  Enumerable.from([
    ...(decimals ?? '').split(''),
    ...'00'.split(''),
  ]).take(2)
    .toArray()
    .map(number => priceCodes[Number(number)])
    .join('')

const priceCodes: Record<number, string> = {
  1: 'A',
  2: 'L',
  3: 'B',
  4: 'E',
  5: 'R',
  6: 'T',
  7: 'S',
  8: 'O',
  9: 'N',
  0: 'X',
}