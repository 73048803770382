import React, { useEffect, useState } from 'react'
import { Input, InputProps } from 'antd'

export const NumericInput = (props: NumericInputProps & InputProps) => {
  const [_value, _setValue] = useState<string>()
  const decimalPlaces = props.decimalPlaces ?? 2
  const formatter = new Intl.NumberFormat(
    'en-US',
    {
      minimumFractionDigits: decimalPlaces
    }
  )
  const format = (value: any): string => {
    const result = formatter.format(value)
    if (result === 'NaN') return ''
    return result
  }
  const [isMyChange, setIsMyChange] = useState<boolean>(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setIsMyChange(true)

    if (!/^-?\d*(\.\d*)?$/.test(value))
      return

    props.onChange!(value as unknown as number)
    _setValue(value.toString())
  }

  useEffect(() => {
    if (isMyChange)
      return setIsMyChange(false)

    _setValue(format(props.value))
  }, [props.value])

  return (
    <Input
      {...props}
      onChange={handleChange}
      value={_value}
      onBlur={(e) => {
        _setValue(format(props.value))
        typeof props.onBlur === 'function' && props.onBlur(e)
      }}
    />
  )
}

interface NumericInputProps {
  style?: React.CSSProperties
  value?: number
  onChange?: (value?: number) => void
  decimalPlaces?: number
  disabled?: boolean
}

