export const makeAnObjectEmpty = (obj: any) => {
  if (!obj)
    return null

  if (typeof obj === 'object') {
    if (Array.isArray(obj))
      return []

    Object.entries(obj).forEach(([ key, value ]) => {
      obj[key] = makeAnObjectEmpty(value)
    })

    return obj
  }

  return null
}
