import { QueryableOptionsMany, QueryableOptionsSingle } from './type';
import { gql } from '@apollo/client';
import { Arguments, GqlArguments, resolveData, resolveQuery } from './resolver';
import { executeQuery } from './client';
import { PageableResponse } from '../type';

export class GqlQuery {
  async single<TEntity, TResponse = TEntity>(params: QuerySingleParameters<TEntity>): Promise<TResponse> {
    const { entity, method, options, args, variables } = params;
    const query = gql(`${ resolveQuery({
      method: method ? method : `${ entity }Single`,
      args: args ? args : [ Arguments.where, Arguments.orderBy ],
      fields: options.select
    }) }`);

    const result = await executeQuery({
      documentNode: query,
      variables: {
        where: options.where,
        orderBy: options.orderBy,
        ...variables
      }
    });

    return resolveData<TResponse>(result);
  }

  async list<TEntity, TData = TEntity>(params: QueryListParameters<TEntity>): Promise<PageableResponse<TData>> {
    const { entity, method, options, args, variables } = params;
    const query = gql(`${ resolveQuery({
      method: method ? method : `${ entity }List`,
      args: args ? args : [ Arguments.take, Arguments.skip, Arguments.where, Arguments.orderBy ],
      fields: {
        data: options.select,
        count: options.count || undefined
      }
    }) }`);

   // console.log(query)

    const result = await executeQuery({
      documentNode: query,
      variables: {
        take: options.take,
        skip: options.skip,
        where: options.where,
        orderBy: options.orderBy,
        ...variables
      }
    });

    return resolveData<PageableResponse<TData>>(result);
  }

  async position({ entity, variable, query }: QueryPositionParameters): Promise<number> {
    const method = `${ entity }Position`;
    const documentNode = gql(query ? query : `
      query($id: Int!) {
        ${ method }(id: $id)
      }
   `);

    const result = await executeQuery({
      documentNode,
      variables: { ...variable }
    });

    return resolveData<number>(result);
  }

  async custom<TEntity, TResponse = TEntity>(params: QuerySingleParameters<TEntity>): Promise<TResponse> {
    const { method, options, args, variables } = params;
    const query = gql(`${ resolveQuery({
      method: method || '',
      args: args ? args : [ Arguments.where, Arguments.orderBy ],
      fields: options.select
    }) }`);

    const result = await executeQuery({
      documentNode: query,
      variables: {
        where: options.where,
        orderBy: options.orderBy,
        ...variables
      }
    });

    return resolveData<TResponse>(result);
  }
}

export interface QuerySingleParameters<TEntity> {
  entity?: string;
  method?: string;
  args?: GqlArguments[];
  variables?: any;
  options: QueryableOptionsSingle<TEntity>;
}

export interface QueryListParameters<TEntity> extends Omit<QuerySingleParameters<TEntity>, 'options'> {
  options: QueryableOptionsMany<TEntity>;
}

export interface QueryPositionParameters {
  entity: string;
  variable: any;
  query?: string;
}
