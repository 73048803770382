import { Item } from './item'
import { Nullable, OmitIdentityFields } from '../../../core/type'

type ItemEntryOmitFields = 'createdBy'
  | 'vendor'
  | 'isComponent'
  | 'group'
  | 'components'
  | 'categories'
  | 'category'
  | 'subcategory'
  | 'subcategory2'

export interface ItemRelation {
  type: RelationType
  item: Item
  quantity: number
}

export enum RelationType {
  Parent = 'Parent',
  Child = 'Child'
}

export interface ItemEntry extends Omit<Item, OmitIdentityFields | ItemEntryOmitFields> {
  vendorId: number
  groupId: number
  categories?: number[]
  category: {
    categoryId: Nullable<number>
    subcategoryId: Nullable<number>
    subcategory2Id: Nullable<number>
  }
  relations: ItemRelation[]
}


