import { Checkbox, Col, Form, Input, Row } from 'antd'
import { translate } from '../../translate'
import React, { useState } from 'react'
import { SearchMode, SearchOptions } from './type'

export type SearchParameterItemProps = {
  index: number
  option: SearchOptions<any>
  mode: SearchMode
}

export const SearchParameterItem = ({index, option, mode}: SearchParameterItemProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  return (
    <Row gutter={[16, 16]} className='mt-1'>
      <Col span={8}>
        <Form.Item
          noStyle
          name={[index, 'isActive']}
          valuePropName="checked"
        >
          <Checkbox
            onChange={e => setIsActive(e.target.checked)}
            style={{fontSize: 12}}
          >
            {option.label}
          </Checkbox>
        </Form.Item>
      </Col>

      <Col span={9}>
        <Form.Item
          noStyle
          name={[index, 'value']}
          rules={[
            {required: isActive && mode === SearchMode.Search, message: translate(option.label, 'required')}
          ]}>
          {
            option.render
              ? option.render({isActive})
              :
              <Input
                disabled={!isActive}
              />
          }
        </Form.Item>
      </Col>

      <Col span={7} hidden={option.hideExactMatch}>
        <Form.Item
          noStyle
          name={[index, 'exactMatch']}
          valuePropName="checked"
        >
          <Checkbox
            style={{fontSize: 12}}
            disabled={!isActive}
          >
            {translate('exact_match')}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  )
}