import {authService} from '../service'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {memory} from '../../../core'
import {TokenResponse, UnauthorizedException} from '../../../core/type'
import {AUTHENTICATION_TOKEN} from '../../../App.constants'
import {setCurrentUser} from '../../../store/redux/reducer'
import {User} from '../../user/type'
import {useLazyQuery} from '@apollo/client'
import {GetUserAuthenticated} from '../../user/graphql/user-queries.graphql'

export const useCheckAuthentication = (): () => Promise<void> => {
  const dispatch = useDispatch()
  const [getUserAuthenticated] = useLazyQuery<{ userAuthenticated: User }>(GetUserAuthenticated)

  const {currentUser} = useSelector(state => state) as { currentUser: User }
  const navigate = useNavigate()

  const goToLogin = () => {
    authService.persistCurrentURL()
    navigate('/login')
  }

  return async (): Promise<void> => {
    if (currentUser.id)
      return

    const token = memory.get<TokenResponse>(AUTHENTICATION_TOKEN)

    if (!token) {
      goToLogin()
      return
    }

    try {
      const {data} = await getUserAuthenticated()
      const authenticatedUser = data?.userAuthenticated

      if (!authenticatedUser)
        goToLogin()

      dispatch(setCurrentUser(authenticatedUser || {}))
    } catch (e) {
      if (e instanceof UnauthorizedException)
        goToLogin()
    }
  }
}
