import { useEffect, useMemo, useState } from 'react'
import { Container, ErrorMessage, getURL, SinglePageable, SinglePageableResponse } from '../../../core/componet'
import { Button, Empty, Form } from 'antd'
import { confirm, notify, translate } from '../../../core'
import {
  CloseOutlined,
  DeleteOutlined,
  HistoryOutlined,
  PlusOutlined,
  PrinterOutlined,
  SaveFilled,
  SearchOutlined,
  StopOutlined,
} from '@ant-design/icons'
import {
  BadRequestException,
  EntryMode,
  PageableRequest,
  PageContextValue,
  QueryPageableRequest,
  QueryPageableResponse,
} from '../../../core/type'
import { Order, OrderEntry, OrderFindRequest } from '../type'
import { OrderForm } from './OrderForm'
import { OrderSearch } from './OrderSearch'
import styles from './Order.module.scss'
import { OrderPageContext } from './OrderPageContext'
import { useLazyQuery, useQuery } from '@apollo/client'
import { EnvelopIcon } from '../../../component/icon'
import { useOrderData } from '../hook/order-data.hook'
import { GetOrderPage, GetOrders } from '../graphql/order-queries.graphql'
import OrderPrint from './OrderPrint'
import { useParams } from 'react-router-dom'

export const OrderPage = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [isRefetch, setIsRefetch] = useState<boolean>(false)
  const [entity, setEntity] = useState<OrderEntry>()
  const [page, setPage] = useState<number | null>()
  const [errors, setErrors] = useState<string[]>([])
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [form] = Form.useForm<OrderEntry>()
  const [find] = useLazyQuery<QueryPageableResponse<Order, 'ordersFind'>, QueryPageableRequest<OrderFindRequest>>(GetOrders)
  const [search, setSearch] = useState<OrderFindRequest>()
  const [entryMode, setEntryMode] = useState<EntryMode>(EntryMode.Nothing)
  const {init, toEntry, toDto, create, update, remove} = useOrderData()
  const [isFormChanged, setIsFormChanged] = useState<boolean>(false)

  const {number} = useParams<{ number?: string }>()

  useQuery<{ orderPage: number }, { number: number }>(GetOrderPage, {
    variables: {
      number: Number(number),
    },
    onCompleted: data => fetch({}, data.orderPage),
    skip: !number
  })


  useEffect(() => {
    setIsFormChanged(false)
  }, [entity])

  const add = async () => {
    try {
      setLoading(true)
      setEntryMode(EntryMode.New)
      const entity = await init()
      setEntity(toEntry(entity))
      form.resetFields()
      form.setFieldsValue(toEntry(entity) as any)
    } finally {
      setLoading(false)
    }
  }

  const save = async (data: OrderEntry) => {
    setSending(true)
    setErrors([])
    const dto = toDto(data)
    const id = entity?.id

    try {
      if (id) await update(id, dto)
      else {
        const result = await create(dto)
        if (entity) entity.id = result.id
      }

      notify.success(
        translate('order'),
        translate('save_success_message'),
      )
      setEntryMode(EntryMode.Nothing)
      setIsFormChanged(false)
    } catch (e) {
      if (e instanceof BadRequestException) setErrors(e.message)
    } finally {
      setSending(false)
      setIsRefetch(true)

    }
  }

  const handleRemove = async () => {
    if (!entity) return

    const result = await confirm(translate('remove_confirmation_message', 'order', '?'))

    if (!result) return

    await remove(entity.id)

    notify.success(
      translate('remove', 'order'),
      translate('the', 'order', 'remove_success_message'),
    )

    setEntity(undefined)
  }

  const onChange = async (request: PageableRequest, page: number): Promise<SinglePageableResponse<Order>> => {
    setLoading(true)

    try {
      const {data} = await find({
        variables: {
          request: {
            ...search,
            take: request.take || 1,
            skip: request.skip || 0,
          },
        },
      })

      const [entity] = data?.ordersFind.data ?? []
      setErrors([])
      const entry = entity && toEntry(entity)
      setEntity(entry)
      form.setFieldsValue(entry as any)
      setEntryMode(EntryMode.Modify)

      if (!page) setPage(1)

      return {count: data?.ordersFind.count ?? 0}
    } finally {
      setLoading(false)
    }
  }

  const fetch = (search: any, page?: number) => {
    setSearch(search)
    setPage(0)
    setTimeout(() => setPage(page), 500)
  }

  const refresh = async () => {
    const {data} = await find({
      variables: {
        request: {
          id: entity?.id ?? 0,
          take: 1,
          skip: 0,
        },
      },
    })

    const [row] = data?.ordersFind.data ?? []
    setEntity(toEntry(row))
    setEntryMode(EntryMode.Modify)
  }

  const sendEmail = () => {
    const email = form.getFieldValue('customerEmail')

    if (!email)
      return

    window.open(getURL(email), '_blank')
  }

  const printDocument = () => {
    window.print();
  };

  const contextValue = useMemo<PageContextValue<any, any>>(() => ({
    form,
    save,
    entity,
    refresh,
    fetch,
    onFormChange: () => setIsFormChanged(true),
  }), [form, save, entity, refresh, fetch])

  return (
    <OrderPageContext.Provider value={contextValue}>
      <Container
        leftClassName="action-container"
        paddingClass="0"
        loading={loading}
        toolbarItems={{
          start: [
            <Button key={'orderPage-search'} onClick={() => setSearchOpen(true)} icon={<SearchOutlined/>}>
              {translate('search')}
            </Button>,

            <Button key={'orderPage-add'} onClick={add} icon={<PlusOutlined/>}>
              {translate('add')}
            </Button>,

            <Button
              key={'orderPage-save'}
              loading={sending}
              onClick={() => form.submit()}
              disabled={!entity}
              type={isFormChanged ? 'primary' : 'default'}
              icon={<SaveFilled/>}
            >
              {translate('save')}
            </Button>,

            <Button
              key={'orderPage-remove'}
              danger
              disabled={!(entity?.id)}
              onClick={handleRemove}
              icon={<DeleteOutlined/>}
            >
              {translate('remove')}
            </Button>,

            <Button
              key={'orderPage-clear'}
              disabled={entryMode === EntryMode.Nothing}
              onClick={async () => {
                if (entryMode === EntryMode.New) await add()
                if (entryMode === EntryMode.Modify) await refresh()
              }}
              icon={<StopOutlined/>}
            >
              {translate('clear')}
            </Button>,

            <Button key={'orderPage-send-email'} onClick={sendEmail} icon={<EnvelopIcon/>}>
              {translate('email')}
            </Button>,

            <Button
              key={'orderPage-history'}
              disabled={!entity?.id}
              icon={<HistoryOutlined/>}>
              {translate('history')}
            </Button>,

            <Button
              onClick={() => entity && printDocument()}
              icon={<PrinterOutlined/>}
            >
              {translate('print')}
            </Button>,

            <Button
              key={'orderPage-exit'}
              disabled={!entity}
              onClick={() => {
                setEntity(undefined)
                setEntryMode(EntryMode.Nothing)
              }}
              icon={<CloseOutlined/>}>
              {translate('exit')}
            </Button>,
          ],
          end: [<SinglePageable key={'order-page-pagination'} onChange={onChange} defaultPage={page}/>],
        }}
      >
        <OrderSearch
          open={searchOpen}
          closeMe={() => setSearchOpen(false)}
        />

        {entity ? (
          <>
            <div className={['print-hidden mt-2 bg-gray', styles['orderEntryForm']].join(' ')}>
              <ErrorMessage title={translate('entry_fail_message', 'order')} message={errors}/>

              <OrderForm/>
            </div>
            <OrderPrint orderId={entity.id} isRefetch={isRefetch} fetched={() => setIsRefetch(false)}/>
          </>
        ) : (
          <Empty className="mt-4"/>
        )}
        <div className="loadFont">font</div>
      </Container>
    </OrderPageContext.Provider>
  )
}
