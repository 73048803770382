import { Image } from 'antd';
import { resolvePathFile } from '../../until';

export const ImageViewer = ({ imageURL, width, height }: ImageViewerProps) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
      <Image
        onClick={ e => e.stopPropagation() }
        width={ width }
        height={ height }
        src={ resolvePathFile(imageURL) }
      />
    </div>
  )
}

interface ImageViewerProps {
  imageURL: string
  width: number | string
  height: number | string
}
