import { Form, Input } from "antd"
import { translate } from "../../../core"

export const OrderEntryPhones = () => {
  return (
    <Form.Item label={ translate('phone') } name="customerWork" className="phone">
      <Input/>
    </Form.Item>
  )
}
