import { HttpMethod, Identity } from '../../../core/type'
import { httpRequest } from '../../../core'
import { ContactGroupDto } from '../type/contact-group'

export class ContactGroupService {
  create(dto: Partial<ContactGroupDto>): Promise<Identity> {
    return httpRequest.execute({
      method: HttpMethod.POST,
      url: 'sales/contact-groups',
      body: dto
    })
  }

  update(id: number, dto: Partial<ContactGroupDto>): Promise<void> {
    return httpRequest.execute({
      method: HttpMethod.PUT,
      url: `sales/contact-groups/${ id }`,
      body: dto
    })
  }

  remove(id: number): Promise<void> {
    return httpRequest.execute({
      method: HttpMethod.DELETE,
      url: `sales/contact-groups/${ id }`,
    })
  }
}
