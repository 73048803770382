import { Report } from './report'
import { DESIGNER_CONTAINER, VIEWER_CONTAINER } from './constants'
import { useEffect, useState } from 'react'
import { Button, Modal, Space, Switch } from 'antd'
import { translate } from '../../translate'
import { loadScripts } from './load-scripts'
import { Container } from '../Container'
import { ShowIf } from '../ShowIf';
import { PrinterOutlined } from '@ant-design/icons'
import { useReport } from './report.hook'
import { ReportVariableCategory } from './type'

export const ReportViewer = ({fileName, data, title, loading: externalLoading, open, onClose}: ReportViewerProps) => {
  const [loading, setLoading] = useState<boolean>()
  const [designMode, setDesignMode] = useState<boolean>(false)
  const {print} = useReport()

  const init = async () => {
    await loadScripts(setLoading)

    if (!open)
      return

    const report = fileName
      ? Report.load(fileName)
      : Report.create()

    report.setData(data)
    report.addVariable({
      name: 'title',
      alias: 'title',
      value: title,
      category: ReportVariableCategory.General,
    })

    if (designMode)
      report.createDesigner()
    else
      report.createViewer()
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, designMode])

  return (
    <Modal
      maskClosable={false}
      title={<Space>
        <label>{title}</label>
        <ShowIf
          condition={process.env.NODE_ENV === 'development'}
          replacement={<></>}>
          <Switch
            title={translate('design')}
            checked={designMode}
            onChange={setDesignMode}
          />

          <Button
            shape='circle'
            type='text'
            icon={<PrinterOutlined/>}
            onClick={() => print({
              fileName: fileName ?? '',
              data,
              title: title ?? '',
              loading,
            })}
          />
        </ShowIf>


      </Space>}
      closable={true}
      onCancel={onClose}
      open={open}
      width={designMode ? '100%' : '70%'}
      okButtonProps={{
        style: {display: 'none'}
      }}
      cancelButtonProps={{
        style: {display: 'none'}
      }}
    >
      {open
        ?
        <Container loading={loading || externalLoading}>
          {
            designMode
              ? <div id={DESIGNER_CONTAINER}>
                {DESIGNER_CONTAINER}
              </div>
              : <div id={VIEWER_CONTAINER}>
                {VIEWER_CONTAINER}
              </div>
          }
        </Container>
        : <></>
      }
    </Modal>
  )
}

export interface ReportViewerProps {
  title?: string
  fileName: string | undefined
  open?: boolean | undefined
  onClose?: () => void
  data: any
  loading?: boolean
}
