import { translate } from '../../../core';
import { Section } from '../../../core/componet';
import { Tabs, TabsProps } from 'antd';
import { ContactGroupList } from './ContactGroupList';
import { ContactCategoryList } from './ContactCategoryList';

export const ContactSettingsContent = () => {
  const items: TabsProps['items'] = [
    {
      label: translate('contact_groups'),
      key: 'contact_groups',
      children: <ContactGroupList/>
    },
    {
      label: translate('contact', 'categories'),
      key: 'contact_categories',
      children: <ContactCategoryList/>
    }
  ];

  return (
    <Section noMargin>
      <Tabs
        defaultActiveKey='contact_groups'
        items={ items }
      />
    </Section>
  )
}
