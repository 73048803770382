import { TableParameters } from './type';

export function initTableParameters(): TableParameters {
  return {
    pagination: {
      defaultCurrent: 1,
      defaultPageSize: 10,
      pageSizeOptions: [ 5, 10, 20, 50 ],
      showSizeChanger: true,
      position: [ 'bottomCenter' ]
    }
  }
}

export const DATA_GRID_NEW_KEY = 'DATA_GRID_NEW_KEY';




