import { MenuItem } from '../core/type'
import { translate } from '../core'
import { AppraisalIcon, OnlineIcon, WarehouseIcon, WeddingIcon } from '../component/icon'
import {
  BarChartOutlined,
  CloudUploadOutlined,
  ContactsOutlined,
  ScheduleOutlined,
  SettingFilled,
  ShoppingOutlined
} from '@ant-design/icons'
import { ItemReportInventoryByPatternRequestButton } from '../app/inventory/hook/report-inventory-by-pattern.hook'
import { MenuLink } from '../core/until'
import { userMenuItem } from '../app/shared/component/protectedLayout/UserDisplay'
import { InventoryByManufactureBottom } from '../app/inventory/hook/report-inventory-by-manufacture.hook'
import { AppointmentMenuItem } from '../app/appointment/component/AppointmentMenuItem'

export const menuItems: MenuItem[] = [
  userMenuItem,
  {
    label: translate('appraisal'),
    key: '2',
    path: '/appraisal',
    icon: <AppraisalIcon size={20}/>,
  },
  {
    label: translate('inventory'),
    key: '3',
    path: '',
    icon: <WarehouseIcon size={20}/>,
    children: [
      {
        label: <MenuLink
          title={translate('inventory')}
          to='/inventory'/>,
        key: '3-1',
        path: ''
      },
      {
        label: <ItemReportInventoryByPatternRequestButton/>,
        key: '3-2',
        path: ''
      },
      {
        label: <InventoryByManufactureBottom/>,
        key: '3-3',
        path: ''
      },
    ]
  },
  {
    label: translate('contact'),
    key: '4',
    path: '/contact',
    icon: <ContactsOutlined style={{fontSize: 20, color: '#a3bde3'}}/>,
  },
  {
    label: translate('order'),
    key: '6',
    path: '/order',
    icon: <ShoppingOutlined style={{fontSize: 23, color: '#a3bde3'}}/>,
  },
  {
    label: translate('online','order'),
    key: '13',
    path: '/online-orders',
    icon: <OnlineIcon size={25}/>,
  },
  {
    label: translate('wedding_registry'),
    key: '7',
    path: '/wedding-registery',
    icon: <WeddingIcon size={23}/>,
  },
  {
    label: translate('upload_images'),
    key: '11',
    path: '/upload-images',
    icon: <CloudUploadOutlined style={{fontSize: 20, color: '#a3bde3'}}/>
  },
  {
    label: translate('reports'),
    key: '11',
    icon: <BarChartOutlined  style={{fontSize: 20, color: '#a3bde3'}}/>,
    children: [
      {
        label: <MenuLink
            title={translate('order', 'payments')}
            to='/reports/order-payments'/>,
        key: '11-1',
        path: '',
      },
      {
        label: <MenuLink
            title={translate('sales', 'by', 'customer')}
            to='/reports/sales-by-customer'/>,
        key: '11-2',
        path: ''
      },
      {
        label: <MenuLink
            title={translate('sales', 'by', 'vendor')}
            to='/reports/sales-by-vendor'/>,
        key: '11-3',
        path: ''
      },
      {
        label: <MenuLink
          title={translate('stone', 'inventory', 'management')}
          to='/reports/stone-inventory-management'/>,
        key: '11-3',
        path: ''
      }
    ]
  },
  {
    label: <AppointmentMenuItem/>,
    key: '12',
    icon: <ScheduleOutlined style={{fontSize: 23, color: '#a3bde3'}}/>
  },
  {
    label: translate('purchase_order'),
    key: '8',
    path: 'purchase-order',
  },
  {
    label: translate('receivables'),
    key: '9',
    path: 'receivables',
  },
  {
    label: translate('settings'),
    key: '10',
    path: '/settings',
    icon: <SettingFilled style={{fontSize: 20, color: '#a3bde3'}}/>
  },
]
