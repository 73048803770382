export const GmailMailTo = ({ email }: { email: string }) => {
  return (
    <a
      href={ getURL(email) }
      target="_blank" rel="noreferrer"
    >
      { email }
    </a>
  );
}

export const getURL = (email: string) =>
  `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${ email }`

/*
* su=Welcome Gann
* */
