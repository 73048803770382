import * as XLSX from 'xlsx'
import {saveAs} from 'file-saver'

export type ExportToExcelArguments = {
    data: any[]
    fileName: string
}

export const exportToExcel = ({data, fileName}: ExportToExcelArguments) => {
    const [first] = data
    const worksheet = XLSX.utils.json_to_sheet(data)
    const workbook = XLSX.utils.book_new()

    XLSX.utils.sheet_add_aoa(worksheet, [Object.keys(first)], { origin: "A1" });
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName)

    const workbookOut = XLSX.write(workbook, {bookType: 'xlsx', bookSST: true, type: 'binary'})

    saveAs(new Blob([s2ab(workbookOut)],{type:'application/octet-stream'}), `${fileName}.xlsx`)
}

const s2ab = (s: any ) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF
    return buf
}

