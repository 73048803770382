import {gql} from '@apollo/client'

export const GetUserAuthenticated = gql`
    query GetUserAuthenticated {
        userAuthenticated {
            id
            name
            name
            status
        }
    }
`

export const GetUsers = gql`
    query GetUsers($request: UserFindRequest!) {
        usersFind(request: $request) {
            data {
                id
                name
            }
        }
    }
`