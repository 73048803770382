import { Modal } from 'antd'

export interface WebhookJsonViewerProps {
  open: boolean
  content: any
  onClose: () => void
}

export const JsonViewer = ({open, content, onClose}: WebhookJsonViewerProps) => {
  return (
    <Modal
      title={''}
      closable
      open={open}
      footer={[]}
      width='40%'
      onCancel={onClose}
    >
      <div className='pt-4'>
        <pre style={{fontWeight: 'bold', color: '#1049e9'}}>
            {JSON.stringify(content, null, 2)}
          </pre>
      </div>
    </Modal>
  )
}