import { ReportViewState } from '../../../core/componet/Report'
import { createSlice } from '@reduxjs/toolkit'

const initialState: ReportViewState = {
  open: false,
  data: null,
  fileName: '',
  loading: false,
  title: ''
}

export const reportViewSlice = createSlice({
  name: 'reportView',
  initialState,
  reducers: {
    setReportViewState: (state: Partial<ReportViewState>, {payload}: {payload: Partial<ReportViewState>}) => {
      if (!payload) return

      state.open = payload.open
      state.data = payload.data
      state.fileName = payload.fileName
      state.loading = payload.loading
      state.title = payload.title
    },
  }
})

export const { setReportViewState } = reportViewSlice.actions;
export const reportViewReducer = reportViewSlice.reducer;
