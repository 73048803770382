import { Select } from "antd"
import { useEffect, useState } from "react"
import { SelectProps } from "antd/es/select"
import { ComboProps, XOptionType } from "./type"
import { mapToSelectOptions } from "./utils"

export const Combo = <TEntity extends object, TKey>({
  loading,
  options,
  style,
  className,
  bordered,
  onSelect,
  onBound,
  onChange,
  onFilter,
  fetch,
  value,
  size,
  placeholder,
  dropdownRender,
  disabled,
  allowMultiple,
  allowClear = true,
  onSearch,
}: ComboProps<TEntity, TKey>) => {
  const [data, setData] = useState<XOptionType<TEntity>[]>([])
  const [internalLoading, setInternalLoading] = useState<boolean>(false)
  const [ignoreFetchByValue, setIgnoreFetchByValue] = useState<boolean>(false)

  useEffect(() => {
    fetchByValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const defaultFilterOption = (input: string, option: any): boolean =>
    option.label?.toString().toLowerCase().includes(input) as boolean

  const fetchByValue = async () => {
    if (ignoreFetchByValue) {
      setIgnoreFetchByValue(false)
      return
    }

    if (!value) return

    if (!fetch) return

    setInternalLoading(true)

    try {
      const items = await fetch({ value })
      onBound && onBound(items.map((e) => e.item) as TEntity[])

      setData(mapToSelectOptions(items))
    } finally {
      setInternalLoading(false)
    }
  }

  const selectProps: SelectProps = {
    style,
    size,
    showArrow: true,
    value,
  }

  if (allowMultiple) selectProps.mode = "multiple"

  if (onSelect)
    selectProps.onSelect = (value, option) =>
      onSelect(value, (option as XOptionType<TEntity>).item as TEntity)

  if (onChange)
    selectProps.onChange = (value, optionType) => {
      if (!value) {
        onChange(value)
        onSelect && onSelect(value, optionType as TEntity)
        return
      }

      const item = Array.isArray(optionType)
        ? optionType.map<TEntity>((e) => e["item"])
        : (optionType["item"] as TEntity)

      setIgnoreFetchByValue(true)
      onChange(value, item)
    }

  if (options) {
    selectProps.options = mapToSelectOptions(options)
    selectProps.filterOption = (input, option) =>
      onFilter
        ? onFilter(input, (option as XOptionType<TEntity>)?.item as TEntity)
        : defaultFilterOption(input, option)
  }

  if (fetch) {
    const load = async (value?: string): Promise<void> => {
      setInternalLoading(true)

      try {
        const result = await fetch({
          request: { take: 10, skip: 0 },
          search: value,
        })
        setData(mapToSelectOptions<TEntity>(result))
      } finally {
        setInternalLoading(false)
      }
    }

    selectProps.showSearch = true
    selectProps.filterOption = false
    selectProps.options = data
    selectProps.onDropdownVisibleChange = (open) => open && load()
    selectProps.onSearch = onSearch ?? load
  }

  return (
    <Select
      loading={internalLoading || loading}
      showSearch={true}
      disabled={disabled}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      placeholder={placeholder}
      bordered={bordered}
      className={className}
      allowClear={allowClear}
      dropdownRender={dropdownRender}
      {...selectProps}
    />
  )
}
