import React, { useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { translate } from '../../../core'
import { PageContextValue} from '../../../core/type'
import { Order, OrderEntry, OrderFindRequest } from '../type'
import { OrderPageContext } from './OrderPageContext'
import { GetOrderPage } from '../graphql/order-queries.graphql'
import { SearchModal, SearchProps } from '../../../core/componet/Search'

export const OrderSearch = ({open, closeMe}: SearchProps) => {
  const {fetch} = useContext<PageContextValue<Order, OrderEntry>>(OrderPageContext)
  const [getPage, {loading}] = useLazyQuery<{ orderPage: number }, {
    number: number
  }>(GetOrderPage, {errorPolicy: 'all'})

  return (
    <SearchModal<OrderFindRequest>
      title={translate('search', 'order')}
      open={open}
      closeMe={closeMe}
      onComplete={(request, page) => {
        fetch!(request, page)
        closeMe()
      }}
      idLabel={translate('order', 'number_abbr')}
      searchOptions={[
        {
          key: 'customerId',
          label: translate('contact_id'),
          setValue: (item, request) => {
            request.customerId = item.value
            request.customerIdExactMatch = item.exactMatch
          },
        },
        {
          key: 'phone',
          label: translate('phone', 'number_abbr'),
          setValue: (item, request) => {
            request.phone = item.value
            request.phoneExactMatch = item.exactMatch
          },
        },
        {
          key: 'lastName',
          label: translate('last_name'),
          setValue: (item, request) => {
            request.lastName = item.value
            request.lastNameExactMatch = item.exactMatch
          },
        },
        {
          key: 'firstName',
          label: translate('first_name'),
          setValue: (item, request) => {
            request.firstName = item.value
            request.firstNameExactMatch = item.exactMatch
          },
        }
      ]}
      findPage={async id => {
        const {data, error} = await getPage({
          variables: {
            number: Number(id),
          },
        })
        return {
          page: data?.orderPage ?? 0,
          error: error?.graphQLErrors,
        }
      }}
      findPageLoading={loading}
      sort={{
        fields: [
          {value: 'date', display: translate('date')},
          {value: 'customerId', display: translate('customer', 'number_abbr')},
          {value: 'lastName', display: translate('last_name')},
          {value: 'firstName', display: translate('first_name')},
        ],
        defaults: [
          {field: 'date', direction: 'desc'}
        ]
      }}
    />
  )
}


