import { Button, Collapse, Form, Input, Tooltip } from "antd";
import { translate } from "../../../core";
import { useContext } from "react";
import { PageContextValue } from "../../../core/type";
import { Item, ItemEntry } from "../type";
import { ItemPageContext } from "./ItemPageContext";
import { EyeOutlined } from "@ant-design/icons";
import { NumericInput } from "../../../core/componet";

const { Panel } = Collapse;

export const ItemFormVrnPackage = () => {
  return (
    <Collapse ghost>
      <Panel
        key={1}
        className="p-0"
        header={<span className="fw-bold">{translate("vrn_package")}</span>}
      >
        <Form.List name="vrnPackages">
          {(fields) => (
            <>
              {
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <td className="text-center" style={{ width: "12%" }}>
                        {translate("parcel_number")}
                      </td>
                      <td className="text-center" style={{ width: "12%" }}>
                        {translate("sku")}
                      </td>
                      <td className="text-center" style={{ width: "13%" }}>
                        {translate("average_weight_abbr")}
                      </td>
                      <td className="text-center" style={{ width: "7%" }}>
                        {translate("quantity_abbr")}
                      </td>
                      <td className="text-center" style={{ width: "10%" }}>
                        {translate("total_weight_abbr")}
                      </td>
                      <td className="text-center" style={{ width: "10%" }}>
                        {translate("shape")}
                      </td>
                      <td className="text-center" style={{ width: "10%" }}>
                        {translate("color")}
                      </td>
                      <td className="text-center" style={{ width: "10%" }}>
                        {translate("clarity")}
                      </td>
                      <td className="text-center" style={{ width: "10%" }}>
                        {translate("cost_ct")}
                      </td>
                      <td className="text-center" style={{ width: "5%" }}>
                        {translate("")}
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {fields.map(({ name }, index) => (
                      <tr key={index}>
                        <td className="p-0">
                          <Form.Item noStyle name={[name, "group", "title"]}>
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "sku"]}>
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item
                            noStyle
                            name={[name, "stone", "averageWeight"]}
                          >
                            <NumericInput
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item
                            noStyle
                            name={[name, "quantity", "onHand"]}
                          >
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item
                            noStyle
                            name={[name, "stone", "totalWeight"]}
                          >
                            <NumericInput
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "stone", "shape"]}>
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "stone", "color"]}>
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "stone", "clarity"]}>
                            <Input
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "stone", "costCT"]}>
                            <NumericInput
                              disabled={true}
                              className="input-disable-clear"
                            />
                          </Form.Item>
                        </td>

                        <td className="p-0">
                          <Form.Item noStyle name={[name, "id"]}>
                            <View />
                          </Form.Item>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </>
          )}
        </Form.List>
      </Panel>
    </Collapse>
  );
};

const View = ({ value }: { value?: number }) => {
  const { fetch } =
    useContext<PageContextValue<Item, ItemEntry>>(ItemPageContext);

  return (
    <Tooltip title={translate("view")}>
      <Button
        icon={<EyeOutlined />}
        shape="circle"
        type="text"
        onClick={() => fetch?.({ id: value })}
      />
    </Tooltip>
  );
};
