import { OrderEntry, OrderLineEntry } from '../type'
import Enumerable from 'linq'
import { Alphabet } from '../../../core/until'
import { translate } from '../../../core'
import { FormInstance } from 'antd'

export const initNewRepairLine = (form?: FormInstance<OrderEntry>, orderLine?: OrderLineEntry): string => {
  const {lines} = form?.getFieldsValue() ?? {} as OrderEntry
  const orderLineInstance = orderLine ?? {} as OrderLineEntry

  const theLastHasRepair = Enumerable.from(lines)
    .select((e) => e || {})
    .where((e) => !!e.repairVersion)
    .distinct((e) => e.repairVersion)
    .orderByDescending((e) => e.repairVersion)
    .firstOrDefault()

  orderLineInstance.isRepair = true
  orderLineInstance.repairVersion = theLastHasRepair
    ? Alphabet.nextLetter(theLastHasRepair.repairVersion)
    : Alphabet.firstLetter()
  orderLineInstance.description = translate('order_repair_item_description')
  orderLineInstance.quantity = 1
  orderLineInstance.unitPrice = 0
  orderLineInstance.takeWithQuantity = 1
  orderLineInstance.backOrderQuantity = 0

  if(!orderLine)
    lines.push(orderLineInstance)

  form?.setFieldsValue({
    lines,
    repairs: [
      ...form?.getFieldValue('repairs') ?? [],
      {
        version: orderLineInstance.repairVersion,
      }
    ]
  })

  return orderLineInstance.repairVersion
}