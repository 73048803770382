import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpStatus, RequestOptions, TokenResponse } from './type';
import { memory } from './index';
import { AUTHENTICATION_TOKEN } from '../App.constants';
import { exceptionHandler } from './exception-handler';

export class HttpRequest {
  get baseURL(): string {
    return `${ process.env.REACT_APP_API_BASE_URL }/v1`
  }

  async execute<T>(requestOptions: RequestOptions): Promise<T> {
    try {
      const result: AxiosResponse<T> = await axios(this.getConfig(requestOptions));
      return result.data;
    } catch (e: unknown) {
      const error = e as AxiosError<{ message: string }>
      const status = error.response?.status as HttpStatus;

      throw exceptionHandler({
        status,
        message: error.response?.data.message as string | string[],
        error: e
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private getConfig<TBody>({ method, allowAnonymous, body, url, params, headers }: RequestOptions): AxiosRequestConfig {
    const config: AxiosRequestConfig = {
      method,
      url: `${ this.baseURL }/${ url }`,
      headers: {
        'Content-Type': 'application/json',
      }
    };

    if(!headers)
      config.headers = {
        'Content-Type': 'application/json',
      };
    else
      config.headers = headers;

    if (body)
      config.data = body;

    if (params)
      config.params = params;

    if (!allowAnonymous) {
      const token = memory.get<TokenResponse>(AUTHENTICATION_TOKEN);

      // @ts-ignore
      config.headers['Authorization'] = `${ token.token_type } ${ token.access_token }`;
    }

    return config;
  }
}
