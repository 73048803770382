import { DataGridItem } from '../../../core/componet'
import { ItemCategory } from '../../inventory/type'

export interface Webhook {
  id?: number
  topic?: string
  enabled?: boolean
  name?: string
  display?: string
  isActive?: boolean
  delivery_url?: string
  endpoint?: string
  status?: WebhookStatus
}

export enum WebhookStatus {
  Active = "active",
  Disabled = "disabled",
  Paused = "paused"
}

export interface WebhookEntry extends DataGridItem, Webhook {
}
