import {contactInitialize} from '../model-mapper/contact-initialize'
import {contactToEntry} from '../model-mapper/contact-to-entry'
import {contactEntryToDto} from '../model-mapper/contact-entry-to-dto'
import {ContactDto} from '../type/contact-dto'
import {httpRequest} from '../../../core'
import {HttpMethod, Identity} from '../../../core/type'

export const useContactData = () => {
  return {
    init: contactInitialize,
    toEntry: contactToEntry,
    toDto: contactEntryToDto,
    create: (dto: ContactDto) => httpRequest.execute<Identity>({
      method: HttpMethod.POST,
      url: 'sales/contacts',
      body: dto,
    }),
    update: (id: number, dto: ContactDto) => httpRequest.execute<void>({
      method: HttpMethod.PUT,
      url: `sales/contacts/${id}`,
      body: dto
    }),
    remove: (id: number) => httpRequest.execute<void>({
      method: HttpMethod.DELETE,
      url: `sales/contacts/${id}`,
    }),
    removeRelation: (id: number) => httpRequest.execute({
      method: HttpMethod.DELETE,
      url: `sales/contacts/${ id }/relations`,
    })
  }
}
