import { createSlice } from '@reduxjs/toolkit'
import { User } from '../../../app/user/type'

const initialState: Partial<User> = {
  id: undefined,
  email: "",
  name: ""
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state: Partial<User>, { payload }: { payload: Partial<User> }) => {
      if (!payload) return;

      state.id = payload.id;
      state.email = payload.email;
      state.name = payload.name;
      state.status = payload.status;
    },
  },
});

export const { setCurrentUser } = currentUserSlice.actions;
export const currentUserReducer = currentUserSlice.reducer;
