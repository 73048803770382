import { gql } from '@apollo/client';

export const GetInventoryByPatternGqlDocuments = gql`
    query GetInventoryByPatternQuery($request: InventoryByPatternReportRequest) {
        inventoryByPattern(request: $request)
    }
`

export const GetInventoryByManufactureWatchDocuments = gql`
    query GetInventoryByManufacture($request: InventoryByManufactureWatchRequest) {
        inventoryByManufacture(request: $request)
    }
`

export const GetStoneInventoryManagementDocuments = gql`
    query GetStoneInventoryManagement($request: StoneInventoryManagementReportRequest!) {
        stoneInventoryManagement(request: $request)
    }
`

