import { weddingRegistryInitializeEntity } from '../model-mapper/wedding-registry-initialize-entity'
import { weddingRegistryEntityToEntry } from '../model-mapper/wedding-registry-entity-to-entry'
import { weddingRegistryEntryToDto } from '../model-mapper/wedding-registry-entry-to-dto'
import { useLazyQuery } from '@apollo/client'
import { GetWeddingRegistryNewNumber } from '../graphql/wedding-registry-queries'
import { httpRequest } from '../../../core'
import { HttpMethod, Identity } from '../../../core/type'
import { WeddingRegistry,WeddingRegistryDto } from '../type'
import { useSelector } from 'react-redux'
import { User } from '../../user/type'

export const useWeddingRegistryData = () => {
  const {currentUser} = useSelector(state => state) as { currentUser: User }
  const [findNewNumber] = useLazyQuery<{ weddingRegistryNewNumber: number }>(GetWeddingRegistryNewNumber)
  const getNewNumber = async () => {
    const {data} = await findNewNumber()
    return data?.weddingRegistryNewNumber ?? 0
  }

  return {
    init: () => weddingRegistryInitializeEntity(getNewNumber, currentUser),
    toEntry: (entity: WeddingRegistry) => weddingRegistryEntityToEntry(entity),
    toDto: weddingRegistryEntryToDto,
    create: (dto: WeddingRegistryDto) =>
      httpRequest.execute<Identity>({
        method: HttpMethod.POST,
        url: "sales/wedding-registries",
        body: dto,
      }),
    update: (id: number, dto: WeddingRegistryDto) =>
      httpRequest.execute<void>({
        method: HttpMethod.PUT,
        url: `sales/wedding-registries/${id}`,
        body: dto,
      }),
    remove: (id: number) =>
      httpRequest.execute<void>({
        method: HttpMethod.DELETE,
        url: `sales/wedding-registries/${id}`,
      }),
  }
}