import { Moment } from 'moment'

export enum OrderPaymentType {
  CreditCard = 'CreditCard',
  All = 'All',
}

export type OrderPaymentRequest = {
  type: OrderPaymentType,
  minDate: Moment,
  maxDate: Moment,
}

export type OrderPaymentVariables = {
  type: OrderPaymentType,
  minDate: string,
  maxDate: string,
}