import { gql } from '@apollo/client'

export const GetWeddingRegistries = gql`
    query weddingRegistryFind($request: WeddingRegistryFindRequest) {
        weddingRegistryFind(request: $request) {
            data {
                createdBy {
                  name
                }
                id
                number
                orderDate
                weddingDate
                preOrdered
                showOnWeb
                showSpecialInstructions
                customer {
                    id
                    company
                    firstName
                    lastName
                }

                spouses {
                    firstName
                    middleName
                    lastName
                    email
                    homePhone
                    cellPhone
                    workPhone
                }
                addresses {
                    type

                    street
                    city
                    country
                    state
                    zipCode
                    address1
                    address2
                }

                lines {
                    item {
                        id
                        sku
                    }

                    quantity
                    description
                    quantityAvailable
                    quantityTaken
                    unitPrice
                }
                totalPrice
            }
            count
        }
    }
`

export const GetWeddingRegistriesForLookup = gql`
    query GetWeddingRegistries($request: WeddingRegistryFindRequest!) {
        weddingRegistryFind(request: $request) {
            data {
                id
                number
                preOrdered
                lines {
                    item {
                        id
                    }
                    unitPrice
                }
            }
        }
    }
`
export const GetWeddingRegistryPage = gql`
    query GetWeddingRegistryPage($number: Int!) {
        weddingRegistryPage(number: $number)
    }
`;
export const GetWeddingRegistryNewNumber = gql`
    query weddingRegistryNewNumberQuery {
        weddingRegistryNewNumber
    }
`;
