import React, { useContext, useEffect, useState } from 'react'
import { Input, Space, Switch } from 'antd'
import { translate } from '../../../core'
import { ShowIf } from '../../../core/componet'
import { ContactFindRequest } from '../type/contact.query'
import { useLazyQuery } from '@apollo/client'
import { GetPage } from '../graphql/contact.queries.graphql'
import { SearchModal, SearchProps } from '../../../core/componet/Search'
import { ContactPageContext } from './ContactPageContext'
import { FormEntryField, PageContextValue } from '../../../core/type'

type PhoneValue = {
  phone: string,
  includesRelatedGroupsHavingTheSamePhoneNumber: boolean
}
const SearchPhone = ({isActive, value, onChange}: { isActive: boolean } & FormEntryField<PhoneValue>) => {
  const [phone, setPhone] = useState<string>('')
  const [includesRelatedGroupsHavingTheSamePhoneNumber, setIncludesRelatedGroupsHavingTheSamePhoneNumber] = useState<boolean>(false)

  useEffect(() => {
    onChange!({
      phone,
      includesRelatedGroupsHavingTheSamePhoneNumber
    })
  }, [
    phone,
    includesRelatedGroupsHavingTheSamePhoneNumber,
  ])

  useEffect(() => {
    setPhone(value?.phone ?? '')
    setIncludesRelatedGroupsHavingTheSamePhoneNumber(!!value?.includesRelatedGroupsHavingTheSamePhoneNumber)
  }, [value])

  return (
    <Space direction='vertical' className='w-100'>
      <Input
        disabled={!isActive}
        onChange={e => setPhone(e.target.value)}
      />
      <ShowIf
        condition={isActive && !!phone}
        replacement={<></>}>
        <Switch onChange={checked => setIncludesRelatedGroupsHavingTheSamePhoneNumber(checked)}/>
        <span className='ms-2'>{translate('includes_the_related_groups')}</span>
      </ShowIf>
    </Space>
  )
}

export const ContactSearch = ({open, closeMe}: SearchProps) => {
  const {fetch} = useContext<PageContextValue<any, any>>(ContactPageContext)
  const [findPage, {loading}] = useLazyQuery<{ contactPage: number }, {
    contactId: string
  }>(GetPage, {errorPolicy: 'all'})

  return (
    <SearchModal<ContactFindRequest>
      open={open}
      closeMe={closeMe}
      onComplete={(request, page) => {
        fetch!(request, page)
        closeMe()
      }}
      title={translate('search', 'contact')}
      idLabel={translate('contact_id')}
      searchOptions={[
        {
          key: 'company',
          label: translate('company', 'name'),
          setValue: (item, findRequest) => {
            findRequest.company = item.value
            findRequest.companyExactMatch = item.exactMatch
          }
        },
        {
          key: 'lastName',
          label: translate('last_name'),
          setValue: (item, findRequest) => {
            findRequest.lastName = item.value
            findRequest.lastNameExactMatch = item.exactMatch
          }
        },
        {
          key: 'firstName',
          label: translate('first_name'),
          setValue: (item, findRequest) => {
            findRequest.firstName = item.value
            findRequest.firstNameExactMatch = item.exactMatch
          }
        },
        {
          key: 'group',
          label: translate('group'),
          setValue: (item, findRequest) => {
            findRequest.group = item.value
            findRequest.groupExactMatch = item.exactMatch
          }
        },
        {
          key: 'phones',
          label: translate('phone', 'number'),
          render: ({isActive}) =>
            <SearchPhone
              isActive={isActive}
            />,
          setValue: (item, findRequest) => {
            const value: PhoneValue = item.value
            findRequest.phone = value.phone
            findRequest.phoneExactMatch = item.exactMatch
            findRequest.includesRelatedGroupsHavingTheSamePhoneNumber = value.includesRelatedGroupsHavingTheSamePhoneNumber
          },
        },
        {
          key: 'addresses',
          label: translate('address', 'information_abbr'),
          setValue: (item, findRequest) => {
            findRequest.addressInfo = item.value
            findRequest.addressInfoExactMatch = item.exactMatch
          }
        },
        {
          key: 'city',
          label: translate('city'),
          setValue: (item, findRequest) => {
            findRequest.city = item.value
            findRequest.cityExactMatch = item.exactMatch
          }
        },
        {
          key: 'webInfos',
          label: translate('email', '/', 'web_site'),
          setValue: (item, findRequest) => {
            findRequest.webInfo = item.value
            findRequest.webInfoExactMatch = item.exactMatch
          }
        },
      ]}
      findPage={async id => {
        const {data, error} = await findPage({
          variables: {
            contactId: id.toString(),
          }
        })

        return {
          page: data?.contactPage ?? 0,
          error: error?.graphQLErrors,
        }
      }}
      findPageLoading={loading}
    />
  )
}



