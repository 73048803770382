import { Button, Space, Spin, Tooltip, Typography } from 'antd'
import { LeftOutlined, RightOutlined, VerticalLeftOutlined, VerticalRightOutlined } from '@ant-design/icons'
import styles from './SinglePageable.module.scss'
import { translate } from '../../translate'
import { PageableRequest } from '../../type'
import { useEffect, useState } from 'react'
import { ShowIf } from '../ShowIf';
import { NumericInput } from '../NumericInput'

export const SinglePageable = <T extends object>({
                                                   onChange,
                                                   defaultPage,
                                                   disabled,
                                                   size,
                                                   rowsCaption,
                                                   hideLastButton,
                                                   hideFirstButton,
                                                   hideCountCaption,
                                                   hideInputPageNumber
                                                 }: SinglePageableProps<T>) => {
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [nextDisable, setNextDisable] = useState<boolean>(false)
  const [previousDisable, setPreviousDisable] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const SINGLE_PAGE_SIZE = 1

  useEffect(() => {
    setPageNumber(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const checkWhichIsDisabled = (page: number, count: number) => {
    setNextDisable(false)
    setPreviousDisable(false)

    if (page === 1) {
      setPreviousDisable(true)

      if (count <= 1)
        setNextDisable(true)

      return
    }

    if (page === count)
      setNextDisable(true)
  }

  const init = async () => {
    if (!defaultPage)
      return

    const newPage = defaultPage

    const result = await onChange({take: SINGLE_PAGE_SIZE, skip: newPage - 1}, newPage)

    setPage(newPage)
    setCount(result.count)
    checkWhichIsDisabled(newPage, result.count)
  }

  const next = async () => {
    let newPage = page
    newPage++

    const result = await onChange({take: SINGLE_PAGE_SIZE, skip: newPage - 1}, newPage)

    setPage(newPage)
    setCount(result.count)
    checkWhichIsDisabled(newPage, result.count)
  }

  const previous = async () => {
    let newPage = page
    newPage--

    const result = await onChange({take: SINGLE_PAGE_SIZE, skip: newPage - 1}, newPage)

    setPage(newPage)
    setCount(result.count)

    checkWhichIsDisabled(newPage, result.count)
  }

  const last = async () => {
    const firstPageForGettingCountOnly = 1
    const {count: lastPage} = await onChange({
      take: SINGLE_PAGE_SIZE,
      skip: firstPageForGettingCountOnly - 1,
    }, firstPageForGettingCountOnly)

    const {count} = await onChange({take: SINGLE_PAGE_SIZE, skip: lastPage - 1}, lastPage)
    setPage(count)
    setCount(count)

    checkWhichIsDisabled(lastPage, count)
  }

  const first = async () => {
    const firstPageForGettingCountOnly = 1
    const {count} = await onChange({
      take: SINGLE_PAGE_SIZE,
      skip: 0,
    }, firstPageForGettingCountOnly)

    setPage(firstPageForGettingCountOnly)
    setCount(count)

    checkWhichIsDisabled(firstPageForGettingCountOnly, count)
  }

  const handleChangePageManually = async () => {
    if (!pageNumber)
      return

    const {count: resultCount} = await onChange({take: SINGLE_PAGE_SIZE, skip: pageNumber - 1}, pageNumber)
    setPage(pageNumber > resultCount ? page : pageNumber)
    setCount(resultCount)

    checkWhichIsDisabled(pageNumber, count)
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPage])

  return (
    <Spin spinning={disabled || false} indicator={<></>}>
      <Space>
        <ShowIf
          condition={!hideInputPageNumber}
          replacement={<></>}
        >
          <NumericInput
            value={pageNumber}
            size="middle"
            style={{width: 100}}
            onChange={value => setPageNumber(value as number)}
            onKeyDown={e => {
              if (e.key !== 'Enter')
                return
              return handleChangePageManually()
            }}
            decimalPlaces={0}
          />
        </ShowIf>

        <ShowIf
          condition={!hideCountCaption}
          replacement={<></>}
        >
          <Typography.Text
            type="secondary"
            style={{fontSize: 11}}
            className='text-nowrap'
          >
            <span className="ms-1">of</span>
            <span className="ms-1">{count}</span>
            <span className="ms-1">{rowsCaption || translate('rows')}</span>
          </Typography.Text>
        </ShowIf>

        <ShowIf
          condition={!hideFirstButton}
          replacement={<></>}>
          <Tooltip title={translate('first')}>
            <Button
              disabled={(page && count) ? (page === 1) : false}
              onClick={first}
              className={styles.ButtonStyle}
              icon={<VerticalRightOutlined/>}
              size={size || 'middle'}
            />
          </Tooltip>
        </ShowIf>

        <Tooltip title={translate('previous')}>
          <Button
            disabled={previousDisable}
            onClick={previous}
            className={styles.ButtonStyle}
            icon={<LeftOutlined/>}
            size={size || 'middle'}
          />
        </Tooltip>

        <Tooltip title={translate('next')}>
          <Button
            disabled={nextDisable}
            onClick={next}
            className={styles.ButtonStyle}
            icon={<RightOutlined/>}
            size={size || 'middle'}
          />
        </Tooltip>

        <ShowIf
          condition={!hideLastButton}
          replacement={<></>}>
          <Tooltip title={translate('last')}>
            <Button
              disabled={(page && count) ? (page === count) : false}
              onClick={last}
              className={styles.ButtonStyle}
              icon={<VerticalLeftOutlined/>}
              size={size || 'middle'}
            />
          </Tooltip>
        </ShowIf>
      </Space>
    </Spin>
  )
}

export interface SinglePageableProps<T> {
  onChange: (request: PageableRequest, page: number) => Promise<SinglePageableResponse<T>>
  defaultPage?: number | null
  disabled?: boolean
  size?: 'small' | 'middle'
  rowsCaption?: string
  hideLastButton?: boolean
  hideFirstButton?: boolean
  hideCountCaption?: boolean
  hideInputPageNumber?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface SinglePageableResponse<T> {
  count: number
}
