import { Button } from 'antd';
import { GoogleIcon } from '../../../component/icon';
import { httpRequest, translate } from '../../../core';

export const GoogleButton = () => {
  const onClick = () => {
    const callbackURL = `${ window.location.origin }/auth`;
    const url = new URL(`${ httpRequest.baseURL }/auth/google`);
    url.searchParams.append('callbackUrl', callbackURL);
    window.open(url.href, '_self');
  }

  return (
    <Button
      onClick={ onClick }
      type="default"
      block
      icon={ <GoogleIcon size={ 20 }/> }
      size="large"
    >
      { translate('login_by_google') }
    </Button>
  )
}
