import Enumerable from 'linq'
import React, { useEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Card, Col, Form, Input, Modal, Radio, Row, Space } from 'antd'
import { SearchParameterList } from './SearchParameterList'
import { SearchAndFind, SearchContainerProps, SearchMode } from './type'
import { translate } from '../../translate'
import { ErrorMessage } from '../ErrorMessage'
import { Disable } from '../Disable'
import { NOT_FOUND_MESSAGE } from '../../../App.constants'
import { SortList } from './SortList'
import { SortableRequest } from '../../type'

export const SearchModal = <TRequest extends object>(props: SearchContainerProps<TRequest>) => {
  const {
    open,
    closeMe,
    searchOptions,
    title,
    idLabel,
    findPage,
    findPageLoading,
    onComplete,
    sort,
  } = props
  const [mode, setMode] = useState<SearchMode>(SearchMode.Find)
  const [errors, setErrors] = useState<string[]>([])
  const [form] = Form.useForm<SearchAndFind>()

  useEffect(() => {
    form.setFieldsValue({
      parameters: searchOptions.map(so => ({
        key: so.key,
        isActive: false,
        exactMatch: false,
      })),
      sorts: sort?.defaults ?? []
    })
  }, [])

  const handleConfirm = async () => {
    try {
      await form.validateFields({validateOnly: true, recursive: true})
      setErrors([])
      form.submit()
    } catch (e: any) {
      const errors = Enumerable.from(e.errorFields)
        .selectMany((e: any) => e.errors)
        .toArray() as string[]
      setErrors(errors)
    }
  }

  const handleOnFinish = async (data: SearchAndFind) => {
    if (mode === SearchMode.Find) {
      const {page, error} = await findPage(data.id)

      if (error) {
        const [{message}] = error
        if (message === NOT_FOUND_MESSAGE)
          setErrors([translate(idLabel, 'not_found')])

        return
      }

      return onComplete(null, page)
    }

    const request = {} as TRequest

    Enumerable.from(data.parameters.filter(pr => pr.isActive))
      .groupJoin(
        searchOptions,
        op => op.key,
        pr => pr.key,
        (params, optionsEnumerable) => {
          const option = optionsEnumerable.first()
          return {
            key: option.key,
            setValue: option.setValue,
            params,
          }
        }
      )
      .forEach((it => {
        it.setValue(
          it.params,
          request,
        )
      }))

    if (sort) {
      (request as SortableRequest).sort = data.sorts?.map(st => ({field: st.field, sortOrder: st.direction}))
    }

    onComplete(request, 1)
  }

  return (
    <Modal
      maskClosable={false}
      title={<Space>
        <SearchOutlined style={{fontSize: 20}}/>
        {title}
      </Space>}
      closable={false}
      open={open}
      onOk={handleConfirm}
      onCancel={closeMe}
      confirmLoading={findPageLoading}
      width='40%'
    >
      <ErrorMessage
        title={translate(title, 'message')}
        message={errors}
        onClose={() => setErrors([])}
      />

      <Form
        name='form'
        form={form}
        onFinish={handleOnFinish}
      >
        <div className='mt-3 d-block w-100'>
          <Row>
            <Col span={2} className='d-flex flex-column justify-content-center'>
              <Radio checked={mode === SearchMode.Find}
                     onClick={() => setMode(SearchMode.Find)}
              >
                {translate('')}
              </Radio>
            </Col>
            <Col span={22}>
              <Disable disable={mode !== SearchMode.Find}>
                <Card className='mt-1' bordered={false}>
                  <Row>
                    <Col span={8}>
                      <label>{idLabel}</label>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        noStyle
                        name='id'
                        rules={[
                          {required: mode === SearchMode.Find, message: translate(idLabel, 'is_required')}
                        ]}
                      >
                        <Input/>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Disable>
            </Col>
          </Row>

          <Row>
            <Col span={2} className='d-flex flex-column justify-content-center'>
              <Radio checked={mode === SearchMode.Search}
                     onClick={() => setMode(SearchMode.Search)}
              >
                {translate('')}
              </Radio>
            </Col>

            <Col span={22}>
              <Disable disable={mode !== SearchMode.Search}>
                <Card bordered={false}>
                  <SearchParameterList
                    mode={mode}
                    form={form}
                    options={searchOptions}
                  />
                </Card>
              </Disable>
            </Col>
          </Row>

          {
            sort &&
              <Row className='mt-3'>
                  <Col span={2}></Col>
                  <Col span={22}>
                      <Disable disable={mode === SearchMode.Find}>
                          <Card bordered={false}>
                              <SortList fieldList={sort.fields}/>
                          </Card>
                      </Disable>
                  </Col>
              </Row>
          }
        </div>
      </Form>
    </Modal>
  )
}