import { WeddingRegistryEntry, WeddingRegistry, WeddingRegistryLine, WeddingRegistryAddressEntry } from '../type'
import Enumerable from 'linq'
import {
  resolveNumber,
  toMoment,
} from '../../../core/until'

export const weddingRegistryEntityToEntry = (entity: WeddingRegistry): WeddingRegistryEntry => {
  const [mainSpouse, otherSpouse] = entity.spouses ?? []

  const entry: WeddingRegistryEntry = {
    ...entity,
    clerk: entity.createdBy.name,
    weddingDate: toMoment(entity.weddingDate),
    orderDate: toMoment(entity.orderDate),
    email: mainSpouse?.email,
    phone: mainSpouse?.homePhone,
    otherSpouse,
    addresses: entity.addresses?.map(ad => ({
      type: ad.type,
      firstName: mainSpouse?.firstName,
      lastName: mainSpouse?.lastName,
      middleName: mainSpouse?.middleName,
      address1: ad.address1,
      address2: ad.address2,
      street: ad.street,
      city: ad.city,
      state: ad.state,
      country: ad.country,
      zipCode: ad.zipCode,
    })),
    lines: entity.lines?.map(line => {
      const lineEntry = {...line}
      mapEntryLine(lineEntry)
      return lineEntry
    }),
  }

  mapEntry(entry)
  return entry
}

export const mapEntryLine = (line: WeddingRegistryLine) => {
  line.extendedPrice = resolveNumber(line.quantity) * resolveNumber(line.unitPrice)
}

export const mapEntry = (entry: WeddingRegistryEntry) => {
  const {lines} = entry
  entry.totalPrice = Enumerable.from(lines).sum((e) => e.extendedPrice)
}

export const addressesChangeStatus = (addressIsTheSame: boolean, addresses: WeddingRegistryAddressEntry[]) => {
  const [first, second] = addresses

  const baseAddress = addressIsTheSame ? first : {} as WeddingRegistryAddressEntry

  second.firstName = baseAddress?.firstName
  second.lastName = baseAddress?.lastName
  second.middleName = baseAddress?.middleName
  second.address1 = baseAddress.address1
  second.address2 = baseAddress.address2
  second.street = baseAddress.street
  second.city = baseAddress.city
  second.state = baseAddress.state
  second.country = baseAddress.country
  second.zipCode = baseAddress.zipCode
}
