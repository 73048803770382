import { FormEntryField, Image } from '../../../core/type';
import uploadImageSvg from '../../../assets/upload-image.svg';
import { useState } from 'react';
import { Button, Carousel, Space, Tooltip } from 'antd';
import { ImageViewer } from '../../../core/componet/Upload/ImageViewer';
import { ImageUploaderButton } from '../../../core/componet/Upload';
import { DeleteOutlined } from '@ant-design/icons';
import { translate } from '../../../core';

export const ItemFormImages = ({
  value,
  onChange,
}: FormEntryField<Image[]>) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <Space direction='vertical'>
      <Space className='d-flex flex-row justify-content-center'>
        <ImageUploaderButton
          onUploaded={(image) => onChange!([...(value ?? []), image])}
        />
        <Tooltip title={translate('remove', 'current', 'image')}>
          <Button
            shape='circle'
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              onChange!(value?.filter((e) => e !== value[currentIndex]))
            }
          />
        </Tooltip>
      </Space>
      <div>
        {value && value.length > 0 ? (
          <Carousel afterChange={setCurrentIndex} dotPosition='right' style={{width: 300, height: 200}}>
            {value.map(({ filename }, index) => (
              <div className='bg-dark' key={Date.now()}>
                <ImageViewer imageURL={filename} width={250} height={200} />
              </div>
            ))}
          </Carousel>
        ) : (
          <EmptyImage />
        )}
      </div>
    </Space>
  );
};

const EmptyImage = () => (
  <div className='w-100 d-flex flex-row justify-content-center'>
    <img
      src={uploadImageSvg}
      alt='upload'
      style={{ width: 164, height: 164 }}
    />
  </div>
);
