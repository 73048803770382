import { ItemCategory, ItemCategoryDto, ItemCategoryEntry } from '../type'

export const itemCategoryInitialize = (): ItemCategory => {
  return {
    title: ''
  } as ItemCategory
}

export const itemCategoryEntityToEntry = (entity: ItemCategory): ItemCategoryEntry => {
  return {
    id: entity.id,
    key: (entity.id || 0).toString(),
    title: entity.title,
    parent: entity.parent,
    level: entity.level,
  }
}

export const itemCategoryEntryToDto = (entry: ItemCategoryEntry): ItemCategoryDto => {
  return {
    title: entry.title || '',
    parentId: entry.parent?.id,
  }
}
