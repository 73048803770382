import React, { useState } from 'react'
import { translate } from '../../../core'
import { Button, Form, Input, Modal, Space } from 'antd'
import { InventoryByManufactureRequest, ItemType } from '../type'
import { useItemReport } from './item-report.hook'
import { Combo } from '../../../core/componet'
import { getItemTypeComboOptions } from '../func/type-options'

export const InventoryByManufactureBottom = () => {
  const {show, component} = useInventoryByManufacture()
  return (
    <>
       <span onClick={show}>
        {translate('inventory', 'by', 'manufacture')}
      </span>
      {component}
    </>
  )
}

const useInventoryByManufacture = () => {
  const [open, setOpen] = useState<boolean>(false)
  const {report} = useItemReport()
  const [form] = Form.useForm<InventoryByManufactureRequest>()

  const handleOK = (data: InventoryByManufactureRequest) => {
    setOpen(false)
    return report.inventoryByManufactureWatch(data)
  }

  return {
    component: <>
      <Modal
        maskClosable={false}
        title={translate('inventory', 'by', 'manufacture')}
        closable={true}
        open={open}
        onCancel={() => setOpen(false)}
        width='30%'
        footer={[
          <Button
            onClick={() => form.resetFields()}
          >
            {translate('clear')}
          </Button>,
          <Button
            type='primary'
            onClick={form.submit}
          >
            {translate('ok')}
          </Button>,
        ]}
      >
        <Form
          layout='horizontal'
          form={form}
          onFinish={handleOK}
        >
          <Form.Item
            name='type'
            label={translate('type')}
          >
            <Combo<any, ItemType>
              allowMultiple
              size="middle"
              options={getItemTypeComboOptions()}
            />
          </Form.Item>

          <Space className='w-100'>
            <span>
              {translate('manufacture', 'or', 'vendor')}
            </span>

            <Form.Item
              name='vendorContactIdFrom'
              label={translate('from')}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name='vendorContactIdTo'
              label={translate('to')}
            >
              <Input/>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>,
    show: () => setOpen(true),
  }
}


