import React, { useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { translate } from '../../../core'
import { WeddingRegistry, WeddingRegistryEntry, WeddingRegistryFindRequest } from '../type'
import { GetWeddingRegistryPage } from '../graphql/wedding-registry-queries'
import { SearchProps, SearchModal } from '../../../core/componet/Search'
import { PageContextValue } from '../../../core/type'
import { WeddingRegistryPageContext } from './WeddingRegistryPageContext'

export const WeddingRegistrySearch = ({open, closeMe}: SearchProps) => {
  const {fetch} =
    useContext<PageContextValue<WeddingRegistry, WeddingRegistryEntry>>(WeddingRegistryPageContext);
  const [getPage, {loading}] = useLazyQuery<{ weddingRegistryPage: number }, { number: number }>(
    GetWeddingRegistryPage, {errorPolicy: 'all'})

  return (
    <SearchModal<WeddingRegistryFindRequest>
      open={open}
      closeMe={closeMe}
      title={translate('wedding_registry', 'search')}
      findPage={async id => {
        const {data, error} = await getPage({
          variables: {
            number: Number(id),
          }
        })

        return {
          page: data?.weddingRegistryPage ?? 0,
          error: error?.graphQLErrors,
        }
      }}
      findPageLoading={loading}
      searchOptions={[
        {
          key: 'customerId',
          label: translate('customer', 'number_abbr'),
          setValue: (params, request) => {
            request.customerId = params.value
            request.customerIdExactMatch = params.exactMatch
          },
        },
        {
          key: 'phone',
          label: translate('phone', 'number_abbr'),
          setValue: (params, request) => {
            request.phone = params.value
            request.phoneExactMatch = params.exactMatch
          },
        },
        {
          key: 'lastName',
          label: translate('last_name'),
          setValue: (params, request) => {
            request.lastName = params.value
            request.lastNameExactMatch = params.exactMatch
          },
        },
        {
          key: 'firstName',
          label: translate('first_name'),
          setValue: (params, request) => {
            request.firstName = params.value
            request.firstNameExactMatch = params.exactMatch
          },
        },
      ]}
      idLabel={translate('wedding_registry', 'number_abbr')}
      onComplete={(request, page) => {
        fetch!(request, page)
        closeMe()
      }}
    />
  )
}
