import { ContactCategory, ContactCategoryDto, ContactCategoryEntry } from '../type/contact-category'

export const contactCategoryEntityToEntry = (entity: ContactCategory): ContactCategoryEntry => {
  return {
    id: entity.id,
    title: entity.title,
    key: entity.id.toString(),
  } as ContactCategoryEntry
}

export const contactCategoryEntryToDto = (entry: ContactCategoryEntry): ContactCategoryDto => {
  return {
    title: entry.title
  }
}
