import { httpRequest } from '../../../core'
import { HttpMethod, PageableResponse } from '../../../core/type'
import { Job, JobMetaData, JobPageableRequest } from '../type/job'

export const useJobData = () => {
  return {
    getMetaData: () =>
      httpRequest.execute<JobMetaData[]>({
        method: HttpMethod.GET,
        url: 'jobs/meta-data',
      }),
    get: (request: JobPageableRequest): Promise<PageableResponse<Job>> =>
      httpRequest.execute<PageableResponse<Job>>({
        method: HttpMethod.GET,
        url: 'jobs',
        params: request,
      }),
    retry: (id: number) => httpRequest.execute({
      method: HttpMethod.POST,
      url: `jobs/${id}/retry`,
    }),
  }
}