import Enumerable from 'linq'
import { AddressType, Contact, ContactAddress } from '../../contact/type/contact'
import { WeddingRegistryAddressEntry, WeddingRegistryEntry } from '../type'

export const weddingRegistryCreateAddress = (entry: WeddingRegistryEntry, customer: Contact) => {
  entry.addresses = Enumerable
    .from([AddressType.Billing, AddressType.Shipping])
    .groupJoin<ContactAddress, AddressType, WeddingRegistryAddressEntry>(
      customer?.addresses ?? [],
      ad => ad,
      ca => ca.type,
      (type, customerAddresses) => {
        const address = customerAddresses.firstOrDefault() ?? {} as ContactAddress
        return {
          type,
          firstName: customer.firstName,
          lastName: customer.lastName,
          middleName: '',
          address1: address.line1,
          address2: address.line2,
          street: address.street,
          city: address.city,
          state: address.state,
          country: address.country,
          zipCode: address.zipCode,
        }
      },
    )
    .toArray()
}

