import React, { useContext, useEffect, useState } from 'react'
import { Checkbox, Col, DatePicker, Form, Input, Row } from 'antd'
import { InputCard } from '../../inventory/component/InputCard'
import { translate } from '../../../core'
import { PageContextValue } from '../../../core/type'
import styles from './WeddingRegistry.module.scss'
import { Contact, PhoneType, WebInfoType } from '../../contact/type/contact'
import { dateFormat, resolveNumber } from '../../../core/until'
import { WeddingRegistryFormSpouse } from './WeddingRegistryFormSpouse'
import { WeddingRegistryLines } from './WeddingRegistryFormLines'
import { WeddingRegistry, WeddingRegistryAddressEntry, WeddingRegistryEntry } from '../type'
import { addressesChangeStatus, mapEntry } from '../model-mapper/wedding-registry-entity-to-entry'
import { weddingRegistryCreateAddress } from '../model-mapper/wedding-registry-create-address'
import { ContactSelect } from '../../contact/component/ContactSelect'
import { WeddingRegistryFormAddresses } from './WeddingRegistryFormAddresses'
import { WeddingRegistryPageContext } from './WeddingRegistryPageContext'

export const WeddingRegistryForm = () => {
  const {
    form,
    save,
    entity,
    onFormChange
  } = useContext<PageContextValue<WeddingRegistry, WeddingRegistryEntry>>(WeddingRegistryPageContext)
  const [customer, setCustomer] = useState<Contact>()
  const [totalPrice, setTotalPrice] = useState<number>(0)

  useEffect(() => {
    setTotalPrice(entity?.totalPrice ?? 0)
  }, [entity])

  const onAmountsChanged = () => {
    const entry = form?.getFieldsValue()
    mapEntry(entry as WeddingRegistryEntry)
    form?.setFieldsValue(entry as WeddingRegistryEntry)
    setTotalPrice(resolveNumber(entry?.totalPrice))
  }

  const onCustomerChanged = (customer: Contact) => {
    const entry = form?.getFieldsValue()
    weddingRegistryCreateAddress(entry as WeddingRegistryEntry, customer)
    form?.setFieldsValue(entry as WeddingRegistryEntry)

    const phone = customer.phones.find(ph => ph.type === PhoneType.Office)
    form?.setFieldValue('phone', phone?.number)

    const email = customer.webInfos.find(wi => wi.type === WebInfoType.EmailAddress)
    form?.setFieldValue('email', email?.value)
  }

  const handleAddressIsTheSameChange = (value: boolean) => {
    const addresses = form?.getFieldValue('addresses') as WeddingRegistryAddressEntry[]
    addressesChangeStatus(value, addresses)
    form?.setFieldValue('addresses', addresses)
  }

  return (
    <Form
      onFieldsChange={onFormChange}
      layout='vertical'
      name='contentEntry'
      form={form}
      onFinish={save}
      className={styles['form']}
    >
      <Row
        gutter={[16, 16]}
        className='my-2'
        style={{
          width: '1072px',
          paddingInline: '1rem',
          alignContent: 'center',
        }}
      >
        <Col span={8}>
          <Form.Item
            label={translate('registry_no')}
            name='number'
            className='mb-0'
          >
            <Input disabled={true} style={{color: 'unset'}}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={translate('event_date')}
            name='weddingDate'
            className='mb-0'
          >
            <DatePicker format={dateFormat} style={{width: '100%'}}/>
          </Form.Item>
        </Col>
        <Col span={8} className='d-flex flex-row justify-content-end align-items-end' >
          <Form.Item name='preOrdered' valuePropName='checked'>
            <Checkbox>{translate('pre_order')}</Checkbox>
          </Form.Item>
          <Form.Item name='showOnWeb' valuePropName='checked'>
            <Checkbox>{translate('show', 'on', 'web')}</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <InputCard className={styles['customerInfoCardWrapper']}>
        <Row align='bottom' className={styles['customerInfoCard']}>
          <Form.Item
            label={translate('order_number_abbr')}
            name='number'
            className='order-no'
          >
            <Input disabled={true} style={{color: 'unset'}}/>
          </Form.Item>
          <Form.Item
            label={translate('contact_id')}
            name={['customer', 'id']}
            rules={[
              {
                required: true,
                message: translate('missing_field_message', 'customer'),
              },
            ]}
            className='customer-no'
          >
            <ContactSelect
              width={250}
              onCustomerChangedOrBound={(customer, isBound) => {
                setCustomer(customer)
                if (isBound) return
                onCustomerChanged(customer)
              }}
            />
          </Form.Item>
          <Form.Item
            label={translate('Clerk')}
            name='clerk'
            className='clerk'
          >
            <Input
              className='input-disable-clear'
              disabled
            />
          </Form.Item>
          <Form.Item
            label={translate('date')}
            name='orderDate'
            rules={[
              {
                required: true,
                message: translate('missing_field_message', 'date'),
              },
            ]}
            className='date'
          >
            <DatePicker format={dateFormat} style={{width: '100%'}}/>
          </Form.Item>

          <Form.Item
            label={translate('phone')}
            name='phone'
            className='phone'
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label={translate('email')}
            name='email'
            className='email'
          >
            <Input/>
          </Form.Item>
        </Row>
      </InputCard>

      <WeddingRegistryFormAddresses
        isTheSameAddressChanged={handleAddressIsTheSameChange}
      />

      <WeddingRegistryFormSpouse/>

      <WeddingRegistryLines
        totalPrice={totalPrice}
        onAmountsChanged={onAmountsChanged}
      />
    </Form>
  )
}
