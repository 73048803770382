import { Identity } from "../../../core/type"
import { User } from "../../user/type"
import { ContactCategory } from './contact-category'
import { ContactGroup } from './contact-group'

export interface ContactAddress {
  type: AddressType
  country: string
  state: string
  city: string
  street: string
  zipCode: string
  line1: string
  line2: string
}

export interface ContactPhone {
  type: PhoneType
  countryCode: string
  extension: string
  number: string
}

export interface ContactWebInfo {
  type: WebInfoType
  value: string
}

export interface ShortContactView extends Identity {
  contactId: string
  company: string
  firstName: string
  lastName: string
}

export interface Contact extends Identity {
  createdBy: User
  isCustomer: Boolean
  isVendor: Boolean
  allowContact: Boolean
  noEmailMarketing: Boolean
  contactId: string
  company: string
  designer: string
  firstName: string
  lastName: string
  gender: string
  note: string
  dateOfBirth: Date
  anniversary: Date
  category: ContactCategory
  group: ContactGroup
  phones: ContactPhone[]
  addresses: ContactAddress[]
  webInfos: ContactWebInfo[]
  accountNumber: string
  promotion: string
  markupNumber: number
  percentOff: number
  relations: Contact[]
}

export enum Gender {
  Male = "Male",
  Female = "Female",
}

export enum PhoneType {
  Cell = "Cell",
  Home = "Home",
  Office = "Office",
  Fax = "Fax",
  CustomerService = "CustomerService",
}

export enum WebInfoType {
  WebSite = "WebSite",
  EmailAddress = "EmailAddress",
}

export enum AddressType {
  Billing = "Billing",
  Shipping = "Shipping",
}
