import React, { useRef, useState } from 'react'
import {
  DataGrid,
  DataGridEditableCell,
  DataGridRef,
  editableActions,
  editableColumn,
  useDataGridEditable,
} from '../../../core/componet'
import { DATA_GRID_NEW_KEY } from '../../../core/componet/DateGrid/resolver'
import { confirm, notify, translate } from '../../../core'
import { BadRequestException, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Button, Form, Input, Space, Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ItemCategory, ItemCategoryEntry, ItemCategoryFindRequest } from '../type'
import { useLazyQuery } from '@apollo/client'
import { useItemCategoryData } from '../hook/item-category-data-hook'
import { GetItemCategories } from '../graphql/item-category.graphql'

export const ItemCategoryList = () => {
  const dataGridRef = useRef<DataGridRef<ItemCategoryEntry>>(null)
  const [saving, setSaving] = useState<boolean>(false)
  const [find] = useLazyQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategories)
  const {create, update, remove, init, toEntry, toDto} = useItemCategoryData()

  const onSave = async (key: React.Key, row: ItemCategoryEntry) => {
    setSaving(true)

    const dto = toDto(row)

    try {
      if (key === DATA_GRID_NEW_KEY)
        await create(dto)
      else
        await update(row.id || 0, dto)

      notify.success(
        translate('item_category'),
        translate('save_success_message')
      )

      dataGridRef.current?.refresh()
      markAsCompleted()
    } catch (e) {
      if (e instanceof BadRequestException)
        notify.error(
          translate('wrong_message', 'item_category'),
          [e.message].flat().join('<br/>'),
        )
    } finally {
      setSaving(false)
    }
  }

  const handleRemove = async (entity: ItemCategoryEntry) => {
    if (!entity)
      return

    const result = await confirm(translate('remove_confirmation_message', 'item_category', '?'))

    if (!result)
      return

    await remove(entity.id || 0)

    notify.success(
      translate('remove', 'item_category'),
      translate('remove_success_message'),
    )

    dataGridRef.current?.refresh()
  }

  const {
    form,
    editingKey,
    isEditing,
    cancel,
    edit,
    save,
    markAsCompleted,
  } = useDataGridEditable<ItemCategoryEntry>(dataGridRef, onSave)

  return (
    <div className="p-2">
      <Space className="w-100 justify-content-end p-2">
        <Tooltip title={translate('add')}>
          <Button
            icon={<PlusOutlined/>}
            onClick={() => {
              const row = dataGridRef.current?.addRow()
              edit(row ?? init())
            }}>
          </Button>
        </Tooltip>
      </Space>


      <Form form={form} component={false}>
        <DataGrid<ItemCategoryEntry>
          ref={dataGridRef}
          columns={[
            {
              dataIndex: 'id',
              title: translate('id'),
              width: 200,
            },
            editableColumn({
              dataIndex: 'title',
              title: translate('title'),
              editableComponent: <Form.Item name="title" rules={[
                {
                  required: true,
                  message: translate('missing_field_message', 'title'),
                },
              ]}>
                <Input/>
              </Form.Item>,
              isEditing,
            }),
            editableActions(
              editingKey,
              saving,
              isEditing,
              cancel,
              edit,
              save,
              handleRemove,
            ),
          ]}
          fetch={async (request) => {
            const {data} = await find({
              variables: {
                request,
              },
            })

            const list = data?.itemCategoriesFind.data || []
            const count = data?.itemCategoriesFind.count || 0

            return {
              data: list.map(toEntry),
              count,
            }
          }}
          components={{
            body: {
              cell: DataGridEditableCell,
            },
          }}
        />
      </Form>
    </div>
  )
}
