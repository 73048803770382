import { useLazyQuery } from '@apollo/client'
import { itemInitializeEntity } from '../model-mapper/item-initialize-entity'
import { itemEntityToEntry } from '../model-mapper/item-entity-to-entry'
import { itemEntryToDto } from '../model-mapper/item-entry-to-dto'
import { HttpMethod, Identity } from '../../../core/type'
import { httpRequest } from '../../../core'
import { GetItemNewSku } from '../graphql/item.graphql'
import { ItemDto } from '../type/item-dto'
import { ItemImage } from '../type'

export const useItemData = () => {
  const [getNewNumber] = useLazyQuery<{ itemNewSku: string }>(GetItemNewSku)

  const getNewSkuFunc = async () => {
    const {data} = await getNewNumber()
    return data?.itemNewSku || ''
  }

  return {
    init: () => itemInitializeEntity(getNewSkuFunc),
    toEntry: itemEntityToEntry,
    toDto: itemEntryToDto,
    create: (dto: ItemDto) => httpRequest.execute<Identity>({
      method: HttpMethod.POST,
      url: 'inventory/items',
      body: dto,
    }),
    update: (id: number, dto: ItemDto) => httpRequest.execute<void>({
      method: HttpMethod.PUT,
      url: `inventory/items/${id}`,
      body: dto,
    }),
    remove: (id: number) => httpRequest.execute<void>({
      method: HttpMethod.DELETE,
      url: `inventory/items/${id}`,
    }),
    duplicate: (id: number) => httpRequest.execute<Identity>({
      method: HttpMethod.POST,
      url: `inventory/items/${id}/duplicate`,
    }),
    removeComponent: (id: number, componentId: number) => httpRequest.execute({
      method: HttpMethod.DELETE,
      url: `inventory/items/${id}/components/${componentId}`,
    }),
    updateImages: (id: number, dto: { images: ItemImage[] }) => httpRequest.execute({
      method: HttpMethod.PUT,
      url: `inventory/items/${id}/images`,
      body: dto,
    }),
  }
}
