import { Divider } from 'antd';
import { UserChangeName } from './UserChangeName';
import { translate } from '../../../core';
import { UserChangePassword } from './UserChangePassword';
import { Container } from '../../../core/componet';

export const Profile = () => {
  return (
    <Container>
      <div className="p-5">
        <div>
          <h4 className='font-weight-600'>{ translate('change_name') }</h4>

          <div className="mt-3">
            <UserChangeName/>
          </div>
        </div>
        <Divider/>
        <div>
          <h4 className='font-weight-600'>{ translate('change_password') }</h4>

          <div className="mt-3">
            <UserChangePassword/>
          </div>
        </div>
      </div>
    </Container>
  )
}
