import {gql} from '@apollo/client'

export const GetAppraisalForPage = gql`
    query GetAppraisalForPage($request: AppraisalFindRequest) {
        appraisalsFind(request: $request) {
            data {
                id
                number
                date
                appraiser {
                    id
                    name
                }
                numberOfItems
                customerKey
                customer {
                    id
                }
                customerFirstname
                customerLastname
                customerNumber
                customerEmail
                customerAddress {
                    street
                    state
                    country
                    city
                    zipCode
                    description
                    line1
                    line2
                }
                lines {
                    key
                    item {
                        id
                    }
                    amount
                    description
                    image
                }
            }
            count
        }
    }
`

export const GetAppraisalNewNumber = gql`
    query GetAppraisalNewNumber {
        appraisalNewNumber
    }
`

export const GetAppraisalPage = gql`
    query GetAppraisalPage($number: String!) {
        appraisalPage(number: $number)
    }
`

export const GetAppraisalPrint = gql`
    query GetAppraisalPrint($id: Int!) {
        appraisalPrint(id: $id)
    }
`