import Enumerable from 'linq';
import { KeyValuePair } from '../../../core/type';

export const itemStoneNames = [
  'DIAMOND',
  'AMETHYST',
  'AQUA',
  'BK TAHITIAN PEARL',
  'BL TOPAZ',
  'BLANK',
  'CAMEO',
  'CHALCEDONY, DYED BL',
  'CITRINE',
  'DYED BLACK PEARL',
  'EMERALD',
  'GARNET',
  'IOLITE',
  'JADE',
  'LAPIS',
  'MABE PEARL',
  'MALACHITE',
  'ONYX',
  'OPAL',
  'PEARL',
  'PERIDOT',
  'ROSE QUARTZ',
  'RUBY',
  'SAPPHIRE',
  'SYN EMERALD',
  'SYN SAPPHIRE',
  'SYN STAR RUBY',
  'SYN STAR SAPPHIRE',
  'TANZANITE',
  'TIGER EYE',
  'TOURMALINE,GREEN',
  'TOURMALINE,PINK',
  'TURQUOISE',
  'WH TAHITIAN PEARL',
  'ZIRCON,BLUE',
  'ZIRCON,WHITE',
]

export const colors: KeyValuePair[] = Enumerable.from(
  [
    ...[ 'D', 'E', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'W', 'X', 'Y', 'Z' ]
      .map(ch => ({
        key: ch,
        value: ch
      })),
    { key: 'Fancy Light (FL)', value: 'FL' },
    { key: 'F', value: 'F' },
    { key: 'Vivid (V)', value: 'V' },
    { key: 'Fancy Deep (FD)', value: 'FD' }
  ]
)
  .orderBy(e => e.value)
  .toArray()

export const clarity: KeyValuePair[] = Enumerable.from(
  [
    ...[ 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'SI3', 'I1', 'I2', 'I3' ]
      .map(ch => ({
        key: ch,
        value: ch
      })),
    { key: 'Internally Flawless (IF)', value: 'IF' },
  ]
)
  .orderBy(e => e.value)
  .toArray()
