import { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { translate } from '../../../core';
import { BadRequestException, CompletionProps, SetErrorMessageProps } from '../../../core/type';
import styles from './Auth.module.scss';
import { userService } from '../../user/service';
import { SignupDto } from '../../user/type';

export const SignupEntry = ({ onCompleted, setErrorMessage }: SignupEntryProps) => {
  const [ loading, setLoading ] = useState<boolean>(false);

  const onSignup = async ({ email, password, name, repeatPassword }: SignupEntryDto) => {
    setErrorMessage('');

    if (password !== repeatPassword) {
      setErrorMessage(translate('password_and_repeat_password_are_not_equal'));
      return;
    }

    setLoading(true);

    try {
      await userService.signup({ email, password, name });
      onCompleted();
    } catch (e) {
      if (e instanceof BadRequestException)
        setErrorMessage(e.message);

    } finally {
      setLoading(false);
    }
  }

  return (
    <Form
      onFinish={ onSignup }
      layout="vertical"
      name="normal_login"
      className="login-form"
      initialValues={ { remember: true } }
    >
      <Form.Item
        label={ translate('name') }
        name="name"
        rules={ [ { required: true, message: translate('name_required_message') } ] }
      >
        <Input size="large"/>
      </Form.Item>

      <Form.Item
        label={ translate('email') }
        name="email"
        rules={ [ { required: true, message: translate('email_required_message') }, {
          type: 'email',
          message: translate('email_invalid_message')
        } ] }
      >
        <Input size="large"/>
      </Form.Item>
      <Form.Item
        label={ translate('password') }
        name="password"
        rules={ [ { required: true, message: translate('password_required_message') } ] }
      >
        <Input size="large"
               type="password"
        />
      </Form.Item>

      <Form.Item
        label={ translate('repeat_password') }
        name="repeatPassword"
        rules={ [ { required: true, message: translate('repeat_password_required_message') } ] }
      >
        <Input size="large"
               type="password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          size="large"
          htmlType="submit"
          loading={ loading }
          className={ styles.submitButton }>
          { translate('signup') }
        </Button>
      </Form.Item>
    </Form>
  )
}

interface SignupEntryDto extends SignupDto {
  repeatPassword: string;
}

interface SignupEntryProps extends CompletionProps, SetErrorMessageProps {
}

