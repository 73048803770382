import { Form, FormInstance } from 'antd'
import React from 'react'
import { SearchAndFind, SearchMode, SearchOptions } from './type'
import { SearchParameterItem } from './SearchParameterItem'

export type SearchParameterListProps = {
  form: FormInstance<SearchAndFind>
  options: SearchOptions<any>[]
  mode: SearchMode
}

export const SearchParameterList = ({options, form, mode}: SearchParameterListProps) => {

  return (
    <Form.List name="parameters">
      {(items) => (
        <>
          {items.map((item) => {
            const {key} = form.getFieldValue(['parameters', item.name])
            const option = options.find(op => op.key === key) as SearchOptions<any>

            return <SearchParameterItem
              mode={mode}
              index={item.name}
              option={option}
            />
          })}
        </>
      )}
    </Form.List>
  )
}