import { AppraisalEntry, AppraisalDto } from '../type'

export const entryToDto = (entry: AppraisalEntry): AppraisalDto => {
  return {
    appraiserId: entry.appraiserId,
    date: entry.date,
    customerKey: entry.customerKey,
    customerId: entry.customer?.id || 0,
    customerFirstname: entry.customerFirstname,
    customerLastname: entry.customerLastname,
    customerNumber: entry.customerNumber,
    customerEmail: entry.customerEmail,
    customerAddress: entry.customerAddress,
    lines: entry.lines.map(e => ({
      itemId: e.item?.id,
      key: Number(e.key),
      amount: Number(e.amount),
      description: e.description,
      image: e.image?.item?.filename
    }))
  }
}
