import { Navigate } from 'react-router-dom';
import { queryString } from '../../../core/until';
import { TokenResponse } from '../type';
import { authService } from '../service';
import { userService } from '../../user/service';

export const AuthCallback = () => {
  const { parameters } = queryString.parse<AuthCallbackParameters>(window.location.href);
  const { result } = parameters;

  if (result === 'fail')
    return <Navigate to={ '/login' }/>

  userService.onLoginCompleted({
    access_token: parameters.access_token,
    token_type: parameters.token_type
  });

  const returnURL = authService.resolveReturnURL();
  return <Navigate to={ returnURL }/>
}

interface AuthCallbackParameters extends TokenResponse {
  result: 'fail' | 'success';
}
