import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
import * as Enumerable from 'linq';

export const Arguments = {
  take: {
    key: 'take',
    type: 'Int'
  },
  skip: {
    key: 'skip',
    type: 'Int'
  },
  where: {
    key: 'where',
    type: 'JSON'
  },
  orderBy: {
    key: 'orderBy',
    type: 'JSON'
  },
};

export const resolveQuery = (params: ResolverQueryParameters): string => {
  const { method, fields, args } = params;
  const argsEnumerable = Enumerable.from(args);

  const queryArgs = argsEnumerable
    .select(arg => ({ key: arg.key, value: arg.type }))
    .toObject(a => a.key, a => a.value);

  const methodArgs = argsEnumerable
    .select(arg => ({ key: arg.key, value: value(arg) }))
    .toObject(a => a.key, a => a.value);

  const query = {
    query: {
      __variables: queryArgs,
      [method]: {
        __args: methodArgs,
        ...fields
      }
    }
  };

  /*TODO*/
//  console.log(jsonToGraphQLQuery(query, { pretty: true }));
  return jsonToGraphQLQuery(query, { pretty: true });
}

export const resolveData = <TResult>(data: { _: TResult }): TResult => {
  const [ result ] = Object.entries(data).map(([ _, value ]) => value);
  return result;
}

const value = ({ key }: { key: string }) => new VariableType(key);

export interface ResolverQueryParameters {
  method: string;
  fields?: any;
  args: GqlArguments[];
}

export type GqlArguments = { key: string, type: string };


