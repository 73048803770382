import { PrinterOutlined } from '@ant-design/icons'
import { List, Modal, Space } from 'antd'
import { translate } from '../../../../core'
import { useQuery } from '@apollo/client'
import { Printer } from '../../type'
import { QueryPageableRequest, QueryPageableResponse } from '../../../../core/type'
import { GetPrintersDocument } from '../../graphql/printer.query.graphql'
import style from './Printer.module.scss'

export interface PrinterSelectorProps {
  open: boolean
  onClose: () => void
  onSelect: (printer: Printer) => void
}

export const PrinterSelector = ({open, onClose, onSelect}: PrinterSelectorProps) => {
  const {
    data,
    loading,
  } = useQuery<QueryPageableResponse<Printer, 'printersFind'>, QueryPageableRequest>(GetPrintersDocument,
    {
      variables: {
        request: {
          take: 10,
          skip: 0,
        }
      }
    }
  )

  const handleSelect = (item: Printer) => {
    /*onClose()
    onSelect(item)*/
  }

  return (
    <Modal
      maskClosable={false}
      open={open}
      onCancel={onClose}
      closable
      footer={[]}
      title={<Space>
        <PrinterOutlined/>
        <label>{translate('printer')}</label>
      </Space>}
    >
      <List<Printer>
        loading={loading}
        size='small'
        dataSource={data?.printersFind.data ?? []}
        renderItem={item => (
          <List.Item
            onClick={() => handleSelect(item)}
            key={item.id}
            className={style.selectorListItem}>
            {item.title}
          </List.Item>
        )}
        bordered
      />
    </Modal>
  )
}