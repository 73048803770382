import Icon from '@ant-design/icons';
import { IconProps } from '../../core/type'

export const XIcon = ({ src, alt, size, style }: XIconProps) => {
  return <Icon component={ () => <img src={ src } alt={ alt } style={ { width: size || 16, ...style } }/> }/>
}

export type XIconProps = {
  src: string | undefined;
  alt: string | undefined;
  size?: number
} & IconProps
