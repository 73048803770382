import { httpRequest, memory } from '../../../core'
import { HttpMethod, TokenResponse } from '../../../core/type'
import { AUTHENTICATION_TOKEN } from '../../../App.constants'
import {
  ActivateDto,
  ChangePasswordByCodeDto,
  ForgotPasswordDto,
  LoginDto,
  SignupDto,
  UserChangePasswordDto,
  UserUpdateDto
} from '../type'

export class UserService {
  async login(dto: LoginDto): Promise<void> {
    const result = await httpRequest.execute<TokenResponse>({
      url: 'login',
      method: HttpMethod.POST,
      allowAnonymous: true,
      body: dto
    })

    this.onLoginCompleted(result)
  }

  onLoginCompleted(token: TokenResponse): void {
    memory.set(AUTHENTICATION_TOKEN, token)
  }

  signup(dto: SignupDto): Promise<void> {
    return httpRequest.execute<void>({
      url: 'signup',
      method: HttpMethod.POST,
      allowAnonymous: true,
      body: dto
    })
  }

  activate(dto: ActivateDto): Promise<void> {
    return httpRequest.execute<void>({
      url: 'users/activate',
      method: HttpMethod.POST,
      allowAnonymous: true,
      body: dto
    })
  }

  forgotPassword(dto: ForgotPasswordDto): Promise<void> {
    return httpRequest.execute<void>({
      url: 'users/forgot-password',
      method: HttpMethod.POST,
      allowAnonymous: true,
      body: dto
    })
  }

  changePasswordByCode(dto: ChangePasswordByCodeDto): Promise<void> {
    return httpRequest.execute<void>({
      url: 'users/change-password-by-code',
      method: HttpMethod.POST,
      allowAnonymous: true,
      body: dto
    })
  }

  update(id: number, dto: UserUpdateDto): Promise<void> {
    return httpRequest.execute({
      url: `users/${ id }`,
      method: HttpMethod.PUT,
      body: dto
    })
  }

  changePassword(dto: UserChangePasswordDto): Promise<void> {
    return httpRequest.execute({
      url: 'users/change-password',
      method: HttpMethod.PUT,
      body: dto
    })
  }
}
