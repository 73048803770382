import React from 'react';
import { Button, Menu } from 'antd';
import { menuItems } from '../../../../config/Menu';
import companyLogo from '../../../../assets/logo.svg';
import styles from './ProtectedLayout.module.scss';
import { LogoutIcon } from '../../../../component/icon';
import { translate } from '../../../../core';
import { resolveMenuItem } from '../../../../core/until';
import { authService } from '../../../auth/service';
import { useNavigate } from 'react-router-dom';

export const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const items = menuItems.map(item => ({ ...item, label: resolveMenuItem(item) }));
  const pathname = window.location.pathname || '';
  const selectedItem = menuItems.find(e => e.path === pathname)?.key as string;

  const logout = () => {
    authService.logout();
    navigate('/login');
  }

  return (
    <div className="d-flex flex-column">
      <div className={'bg-white d-flex p-4 justify-content-center ' + styles.menuLogo} style={ { height: 100 } }>
        <img src={companyLogo} alt={ '' } className="w-75"/>
      </div>

      <Menu
        className={ styles.menuSider }
        defaultSelectedKeys={ [ '1' ] }
        defaultOpenKeys= { [ 'sub1' ] }
        mode="inline"
        theme="dark"
        inlineCollapsed={ false }
        items={ items }
        selectedKeys={ [ selectedItem ] }
        />

      <div className={ styles.menuSiderFooter }>
        <Button
          onClick={ logout }
          className="text-white"
          block
          type="text"
          icon={ <LogoutIcon/> }>
          { translate('log_out') }
        </Button>
      </div>
    </div>
  )
}
