import { HttpMethod, Identity } from '../../../core/type'
import { httpRequest } from '../../../core'
import { ContactCategoryDto } from '../type/contact-category'

export class ContactCategoryService {
  create(dto: Partial<ContactCategoryDto>): Promise<Identity> {
    return httpRequest.execute({
      method: HttpMethod.POST,
      url: 'sales/contact-categories',
      body: dto
    })
  }

  update(id: number, dto: Partial<ContactCategoryDto>): Promise<void> {
    return httpRequest.execute({
      method: HttpMethod.PUT,
      url: `sales/contact-categories/${ id }`,
      body: dto,
    })
  }

  remove(id: number): Promise<void> {
    return httpRequest.execute({
      method: HttpMethod.DELETE,
      url: `sales/contact-categories/${ id }`,
    })
  }
}
