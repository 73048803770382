import { FormEntryField, QueryPageableRequest, QueryPageableResponse, } from '../../../core/type'
import { Combo, ComboOption } from '../../../core/componet'
import React, { FC, useState } from 'react'
import { Button, Divider, Flex, Input, Space } from 'antd'
import { useQuery } from '@apollo/client'
import { GetItemCategoriesForLookup } from '../graphql/item-category.graphql'
import { useItemCategoryData } from '../hook/item-category-data-hook'
import { ItemCategory, ItemCategoryFindRequest, ItemCategoryLevel, ItemEntry } from '../type'
import { translate } from '../../../core'
import { PlusOutlined } from '@ant-design/icons'

type Category = ItemEntry['category']

export const ItemFormCategory: FC<FormEntryField<Category>> = ({value, onChange}) => {
  const {categoryId, subcategoryId, subcategory2Id} = value ?? {} as Category

  const {data: categoryData, refetch: refetchCategory} = useQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategoriesForLookup, {
    variables: {
      request: {
        take: 1000,
        skip: 0,
        level: ItemCategoryLevel.Category,
      }
    },
  })

  const {data: subcategoryData, refetch: refetchSubcategory} = useQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategoriesForLookup, {
    variables: {
      request: {
        take: 1000,
        skip: 0,
        level: ItemCategoryLevel.Subcategory,
      }
    }
  })

  const {data: subcategory2Data, refetch: refetchSubcategory2} = useQuery<
    QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>,
    QueryPageableRequest<ItemCategoryFindRequest>>(GetItemCategoriesForLookup, {
    variables: {
      request: {
        take: 1000,
        skip: 0,
        level: ItemCategoryLevel.Subcategory2,
      }
    }
  })

  const mapToOptions = (data?: QueryPageableResponse<ItemCategory, 'itemCategoriesFind'>): ComboOption<ItemCategory>[] => {
    return (data?.itemCategoriesFind.data ?? []).map(e => ({
      value: e.id || 0,
      display: e.title || '',
      item: e,
    }))
  }

  const categoryOptions = mapToOptions(categoryData)
  const subcategoryOptions = mapToOptions(subcategoryData).filter(e => e.item?.parent?.id === categoryId)
  const subcategory2Options = mapToOptions(subcategory2Data).filter(e => e.item?.parent?.id === subcategoryId)

  const {create} = useItemCategoryData()

  return (
    <Space>
      <Flex vertical>
        <label>{translate('category')}</label>
        <Combo<ItemCategory, number>
          value={categoryId}
          size='middle'
          style={{width: 200}}
          options={categoryOptions}
          onChange={newValue => onChange!({
            categoryId: [newValue].flat()[0],
            subcategoryId: null,
            subcategory2Id: null,
          })}
          dropdownRender={menu => (
            <CategoryDropdown
              menu={menu}
              onSave={async title => {
                const {id} = await create({title})

                await refetchCategory()

                onChange!({
                  categoryId: id,
                  subcategoryId: null,
                  subcategory2Id: null,
                })
              }}
            />
          )}
        />
      </Flex>

      <Flex vertical>
        <label>{translate('subcategory')}</label>
        <Combo<ItemCategory, number>
          disabled={!categoryId}
          value={subcategoryId}
          size='middle'
          style={{width: 200}}
          options={subcategoryOptions}
          onChange={newValue => onChange!({
            categoryId,
            subcategoryId: [newValue].flat()[0],
            subcategory2Id: null,
          })}
          dropdownRender={menu => (
            <CategoryDropdown
              menu={menu}
              onSave={async title => {
                const {id} = await create({
                  title,
                  parentId: categoryId,
                })

                await refetchSubcategory()

                onChange!({
                  categoryId,
                  subcategoryId: id,
                  subcategory2Id: null,
                })
              }}
            />
          )}
        />
      </Flex>

      <Flex vertical>
        <label>{translate('subcategory2')}</label>
        <Combo<ItemCategory, number>
          disabled={!subcategoryId}
          value={subcategory2Id}
          size='middle'
          style={{width: 200}}
          options={subcategory2Options}
          onChange={newValue => onChange!({
            ...(value ?? {} as Category),
            subcategory2Id: [newValue].flat()[0],
          })}
          dropdownRender={menu => (
            <CategoryDropdown
              menu={menu}
              onSave={async title => {
                const {id} = await create({
                  title,
                  parentId: subcategoryId,
                })

                await refetchSubcategory2()

                onChange!({
                  categoryId,
                  subcategoryId,
                  subcategory2Id: id,
                })
              }}
            />
          )}
        />
      </Flex>
    </Space>
  )
}

const CategoryDropdown: FC<{
  menu: React.ReactElement
  onSave: (title: string) => Promise<void>
}> = ({menu, onSave}) => {
  const [title, setTitle] = useState<string>()
  const [saving, setSaving] = useState<boolean>(false)

  return <>
    <Space style={{padding: '0 8px 4px'}}>
      <Input
        value={title}
        onChange={e => setTitle(e.target.value)}
        placeholder={translate('please_enter')}
      />
      <Button
        disabled={!(title?.length && title.length >= 3)}
        type="text"
        shape='circle'
        icon={<PlusOutlined/>}
        loading={saving}
        onClick={async () => {
          if (!title)
            return

          setSaving(true)

          try {
            await onSave(title)
            setTitle(undefined)
          } finally {
            setSaving(false)
          }
        }}
      />
    </Space>
    <Divider style={{margin: '8px 0'}}/>
    {menu}
  </>
}
