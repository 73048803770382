import { WeddingRegistry, WeddingRegistryLine } from '../type'
import { AddressType } from '../../contact/type/contact'
import { User } from '../../user/type'

export const weddingRegistryInitializeEntity = async (getNewNumber: () => Promise<number>, user: User): Promise<WeddingRegistry> => {
  return {
    number: await getNewNumber(),
    weddingDate: new Date(),
    addresses: [AddressType.Billing, AddressType.Shipping].map(type => ({
      type,
    })),
    lines: [] as WeddingRegistryLine[],
    createdBy: user,
  } as WeddingRegistry
}

