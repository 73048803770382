import {gql} from '@apollo/client'

export const GetContacts = gql`
    query GetContacts($request: ContactFindRequest!) {
        contactsFind(request: $request) {
            data {
                id
                contactId
                gender
                isCustomer
                isVendor
                allowContact
                noEmailMarketing
                designer
                company
                firstName
                lastName
                note
                dateOfBirth
                anniversary
                accountNumber
                markupNumber
                promotion
                category {
                    id
                    title
                },
                group {
                    id
                    title
                },
                phones {
                    type
                    countryCode
                    number
                    extension
                },
                addresses {
                    type
                    country
                    state
                    city
                    street
                    zipCode
                    line1
                    line2
                },
                webInfos {
                    type
                    value
                },
                relations {
                    id
                    contactId
                    firstName
                    lastName
                    addresses {
                        city
                        street
                        line1
                        line2
                    },
                    phones {
                        number
                    }
                }
            }
            count
        }
    }
`

export const GetContactsToLookForGroups = gql`
    query GetContactsToLookForGroups($request: ContactFindRequest!) {
        contactsFind(request: $request){
            data {
                group {
                    id
                }
            }
        }
    }
`

export const GetVendorsForLookup = gql`
    query GetContactsForLookup($request: ContactFindRequest!) {
        contactsFind(request: $request) {
            data {
                id
                contactId
                company
            }
        }
    }
`

export const GetPage = gql`
    query GetPage($contactId: String!) {
        contactPage(contactId: $contactId)
    }
`

export const GetSalesByCustomer = gql`
query salesByCustomer ($request: OrderSalesByCustomerRequest!) {
  salesByCustomer (request: $request)
}
`;
