import React from 'react'
import { Button, Col, Form, Row, Space, Tooltip } from 'antd'
import { translate } from '../../translate'
import { PlusCircleOutlined } from '@ant-design/icons'
import { Combo } from '../Combo'
import { SortDirection } from './SortDirection'
import { CloseButton } from 'react-bootstrap'
import { SortField } from './type'

export type SortListProps = {
  fieldList: SortField[]
}

export const SortList = ({fieldList}: SortListProps) => {
  return (
    <Row>
      <Col span={6}>
        {translate('sort')}
      </Col>
      <Col span={16}>
        <Space direction='vertical' size='small'>
          <Form.List name='sorts'>
            {((fields, {add, remove}) => (
              <>
                {fields.map(({name}, index) => (
                  <Row gutter={[16, 16]} className='mt-1'>
                    <Col span={1}>
                      <div style={{width: 10}}>{index + 1}</div>
                    </Col>

                    <Col span={10}>
                      <Form.Item
                        noStyle
                        name={[name, 'field']}
                      >
                        <Combo
                          allowClear={false}
                          style={{width: 170}}
                          options={fieldList.map(field => ({...field, item: field}))}
                        />
                      </Form.Item>
                    </Col>


                    <Col span={10} className='d-flex justify-content-end'>
                      <Form.Item
                        noStyle
                        name={[name, 'direction']}
                      >
                        <SortDirection/>
                      </Form.Item>
                    </Col>

                    <Col span={1} className='d-flex justify-content-start'>
                      <Form.Item
                        noStyle
                      >
                        <Button
                          icon={<CloseButton/>}
                          shape='circle'
                          type='text'
                          onClick={() => remove(index)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
                <Tooltip title={translate('add')}>
                  <Button
                    onClick={() => add({direction: 'asc'})}
                    type='text'
                    shape='circle'
                    icon={<PlusCircleOutlined style={{fontSize: 20, color: '#2F54EB'}}/>}
                  />
                </Tooltip>
              </>
            ))}
          </Form.List>
        </Space>
      </Col>
    </Row>
  )
}