import { AxiosRequestHeaders } from 'axios';

export enum HttpMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE'
}

export enum HttpStatus {
  Unauthorized = 401,
  NotFound = 404,
  BadRequest = 400
}

export interface RequestOptions {
  url?: string;
  method?: HttpMethod;
  allowAnonymous?: boolean;
  body?: any;
  params?: any;
  headers?: AxiosRequestHeaders;
}
