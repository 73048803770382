import styles from './OrderPrint.module.scss'
import {Descriptions, DescriptionsProps, Divider, Image, Table, TableProps} from "antd";
import GannImage from "../../../assets/gann-print-logo.png";
import React, {FC, useEffect, useState} from 'react';
import {useLazyQuery} from "@apollo/client";
import {GetOrderPrint} from "../graphql/order-queries.graphql";
import {dateDisplay, dateFormatSlashed} from "../../../core/until";
import {chunkArray, formatPhoneNumber, maskNumber} from "../../../core/until/print.utils";


interface DataType {
    key: React.Key;
    orderNo: string,
    customerNo: string,
    clerk: string,
    orderDate: string,
    todayDate: string,
}
const columns:  TableProps<DataType>['columns'] = [
    {
        title: 'Order No.',
        dataIndex: 'orderNo',
    },
    {
        title: 'Customer No.',
        dataIndex: 'customerNo',
    },
    {
        title: 'Sales Person',
        dataIndex: 'clerk',
    },
    {
        title: 'Order Date',
        dataIndex: 'orderDate',
    },
    {
        title: 'Today`s Date',
        dataIndex: 'todayDate',
    },
];

interface DataType2 {
    amount: number,
    backOrderQuantity: number,
    description: string,
    itemNumber: string,
    quantity: number,
    takeWithQuantity: number,
    unitPrice: number
}

const itemColumns : TableProps<DataType2>['columns'] = [
    {
    title: 'Item No.',
    dataIndex: 'itemNumber',
    width: '100px',
    align: 'center'
}, {
    title: 'QTY',
    dataIndex: 'quantity',
    width: '30px',
    align: 'center'
},{
    title: 'DESCRIPTION',
    dataIndex: 'description',
},{
    title: 'TW',
    dataIndex: 'takeWithQuantity',
    width: '30px',
    align: 'center'

},{
    title: 'BO',
    dataIndex: 'backOrderQuantity',
    width: '30px',
    align: 'center'

},{
    title: 'UNIT PRICE',
    dataIndex: 'unitPrice',
    width: '100px',
    align: 'right',
    render: text => text.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
    })
},{
    title: 'AMOUNT',
    dataIndex: 'amount',
    width: '100px',
    align: 'right',
     render: text => text.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
     })
},
]

interface IOrderPrint {
    orderId: number;
    fetched: ()=> void;
    isRefetch: boolean;
}

interface IGetOrderPrint {
    soldToName?: string;
    shipToName?: string;
    soldToAddress?: string;
    shipToAddress?: string;
    soldToCityStateZipCode?: string;
    shipToCityStateZipCode?: string;
    specialInstructions?: string;
    lastPayDate?: string;
    lastPayMethod?: string;
    lastPayCartNumber?: string;
    lastPayAuthenticationCode?: string;
    customerNumber?: string;
    soldToPhone?: string;
    canShowSpecialInstructions?: boolean
}

const OrderPrint: FC<IOrderPrint> = ({orderId, isRefetch, fetched}) => {
    const [getOrderPrint] = useLazyQuery<{ orderPrint: any }, { id: number }>(GetOrderPrint)

    const [invoiceTable, setInvoiceTable] = useState<DataType[]>([]);
    const [itemsTable, setItemsTable] = useState<DataType2[][]>([]);
    const [orderData, setOrderData] = useState<IGetOrderPrint>();
    const [totalData, setTotalData] = useState<DescriptionsProps['items']>();

    const getData = async () => {
        const {data} = await getOrderPrint({
            variables: {
                id: orderId,
            },
        })
        if(data){
            const orderPrint = data.orderPrint;
            const data1: DataType[] = [
                {
                    key: '1',
                    orderNo: orderPrint.number,
                    customerNo: orderPrint.customerNumber,
                    clerk: orderPrint.clerk,
                    orderDate: orderPrint.date,
                    todayDate: dateDisplay(new Date(), dateFormatSlashed),
                },
            ];
            setInvoiceTable(data1);
            setOrderData(orderPrint);
            const chunks = chunkArray(orderPrint.lines, 7, {
                amount: '',
                backOrderQuantity: '',
                description: "",
                itemNumber: "",
                quantity: '',
                takeWithQuantity: '',
                unitPrice: ''
            });
            setItemsTable(chunks)

            const items: DescriptionsProps['items'] = [
                {
                    key: '1',
                    label: 'Sub Total',
                    children: orderPrint.subTotal.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },
                {
                    key: '2',
                    label: 'Sale Tax',
                    children:  orderPrint.tax.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                }, {
                    key: '3',
                    label: 'Ship/Handling',
                    children: orderPrint.ship.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },{
                    key: '4',
                    label: 'Total',
                    children: orderPrint.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },{
                    key: '5',
                    label: 'Total Deposit',
                    children: (orderPrint.totalDeposit - orderPrint.currentPayment).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },{
                    key: '6',
                    label: 'Current Payment',
                    children: orderPrint.currentPayment.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },
               {
                    key: '7',
                    label: 'Balance Due',
                    children: orderPrint.balanceDue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                },

            ];

            setTotalData(items)
        }

    }

    useEffect(() => {
        (async()=> {
            if(orderId) {
               await getData();
            }
        })()
    }, [orderId]);

    useEffect(() => {
        (async()=> {
            if(isRefetch) {
               await getData();
                fetched();
            }
        })()
    }, [isRefetch]);

    const Header = <header className={styles["header"]}>
        <div className='d-flex w-full justify-content-between'>
            <Image src={GannImage}  height={80} style={{ width: 'auto'}}  preview={false} />
            <div className={ styles["invoice-wrapper"] }>
                <Table
                    className='invoice'
                    dataSource={invoiceTable}
                    columns={columns}
                    pagination={false}
                    title={() => "INVOICE"}
                />
            </div>
        </div>
        <div className={ styles["address-wrapper"] }>
            <label>Sold TO: {orderData?.soldToName}</label>
            <label>Ship TO: {orderData?.shipToAddress &&  orderData?.shipToName}</label>
        </div>
        <div className={ styles["address-wrapper"] } >
            <span>{orderData?.soldToAddress}</span>
            <span>{orderData?.shipToAddress && orderData?.shipToAddress}</span>
        </div>
        <div className={ styles["address-wrapper"] } >
            <span>{orderData?.soldToCityStateZipCode}</span>
            <span>{orderData?.shipToAddress &&  orderData?.shipToCityStateZipCode}</span>
        </div>
        <div className={ styles["address-wrapper"] } style={{ marginBottom:'4px'}}>
            <span>{formatPhoneNumber(orderData?.soldToPhone || "")}</span>
        </div>
    </header>
    const Footer =  <footer className={styles['footer']}>
        <div className='w-100 d-flex justify-content-between'>
            <div className='w-100 d-flex h-100 p-1'>
                <div className='position-relative pl-1 d-flex flex-column w-50 justify-content-between' >
                    <div className="flex flex-col">
                        {orderData?.canShowSpecialInstructions && <>
                            <label>Special Instructions:</label>
                            <p style={{whiteSpace: 'pre-line'}}>{orderData?.specialInstructions}</p>
                        </>}
                    </div>

                    <label style={{ width: 50}}>Customer's Signature:</label>
                    <Divider className={styles["divider-dashed"] } dashed={true}/>
                </div>
                <Divider type="vertical" className={styles["divider"] }/>
                <div className='pl-1 pt-1 d-flex flex-column w-50'>
                    <label className={"lh-sm privacy"}>RETURN POLICY: <br/>
                       Items may be returned for a full refund to original purchaser with receipt within 14 business days of purchase. Refunds on credit cards must be credited to the original credit card. Items received as a gift may be exchanged for merchandise or store credit within 14 business days of purchase. Refunds cannot be given to the gift receiver. No returns on special order items, sized or engraved items. </label>
                    <div className='container p-1'>
                        <div className="row">
                            <label className="col pl-1 pr-0 py-0 text-center text-nowrap">Last Pay Date</label>
                            <label className="col px-0 text-center">MOP</label>
                            <label className="col px-0 text-center text-nowrap">Credit Card No.</label>
                            <label className="col px-0 text-center text-nowrap">Auth. Code</label>
                        </div>
                        <div className="row">
                            <label className="col px-0 text-center">{orderData?.lastPayDate}</label>
                            <label className="col px-0 text-center">{orderData?.lastPayMethod}</label>
                            <label className="col px-0 text-center" >{maskNumber(orderData?.lastPayCartNumber || "")}</label>
                            <label className="col px-0 text-center">{orderData?.lastPayAuthenticationCode}</label>
                        </div>
                    </div>
                </div>
            </div>
            <Descriptions bordered items={totalData} column={1}/>
        </div>

        <label className='text-center w-100 pt-1'><b> DIAMONDS - JEWELRY - WATCHES - WEDDING REGISTRY - TABLEWARE - GIFTS </b></label>
    </footer>
    return(
      <div className="print-show print-page-order">
          {itemsTable.map((item) =>   {
              return <div className={ styles["order-report"] }>
                  {Header}
                  <div className={ styles["order-main"] } style={{width: '100%'}}>
                        <Table
                            key={'itemNumber'}
                          dataSource={item as any}
                          columns={itemColumns}
                          pagination={false}
                          rowKey='itemColumns'
                          className='itemTable'
                      />
                      {Footer}
                  </div>

              </div>
      })}

      </div>
    );
};
export default OrderPrint;



