import { useLazyQuery } from '@apollo/client'
import { orderInitializeEntity } from '../model-mapper/order-initialize-entity'
import { orderMapEntityToEntry } from '../model-mapper/order-map-entity-to-entry'
import { orderMapEntryToDto } from '../model-mapper/order-map-entry-to-dto'
import { OrderDto } from '../type'
import { httpRequest } from '../../../core'
import { HttpMethod, Identity } from '../../../core/type'
import { GetOrderNewNumber } from '../graphql/order-queries.graphql'

export const useOrderData = () => {
  const [ getNewNumberQuery ] = useLazyQuery<{ orderNewNumber: number }>(GetOrderNewNumber)

  const getNewNumber = async () => {
    const { data } = await getNewNumberQuery()
    return data?.orderNewNumber || 0
  }

  return {
    init: () => orderInitializeEntity(getNewNumber),
    toEntry: orderMapEntityToEntry,
    toDto: orderMapEntryToDto,
    create: (dto: OrderDto): Promise<Identity> => {
      return httpRequest.execute({
        method: HttpMethod.POST,
        url: 'sales/orders',
        body: dto
      })
    },
    update: (id: number, dto: OrderDto) => {
      return httpRequest.execute({
        method: HttpMethod.PUT,
        url: `sales/orders/${ id }`,
        body: dto
      })
    },
    remove: (id: number) => {
      return httpRequest.execute({
        method: HttpMethod.DELETE,
        url: `sales/orders/${ id }`,
      })
    },
  }
}

