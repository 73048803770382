import { useJobData } from '../hook/job-data.hook'
import { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { translate } from '../../../core'
import { JobList } from './JobList'
import { JobMetaData } from '../type/job'

export const JobsContainer = () => {
  const {getMetaData} = useJobData()
  const [metaData, setMetaData] = useState<JobMetaData[]>([])

  const fetch = async () => {
    setMetaData(await getMetaData())
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Tabs
      tabPosition='left'
      defaultActiveKey={metaData[0]?.name}
      items={
        metaData.map(md => ({
          key: md.name,
          label: translate(md.alias),
          children: <JobList meta={md}/>,
        }))
      }
    />
  )
}