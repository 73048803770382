import React from 'react';
import { Button, Form, Modal } from 'antd';
import { translate } from '../../translate';

type ReportRequestFormProps = {
  children: React.ReactNode
  open: boolean
  onClose: () => void
  onComplete: (request: any) => void
  width?: number | string
  title: string
}

export const ReportRequestForm = ({
                                    children,
                                    onComplete,
                                    onClose,
                                    open,
                                    title,
                                    width
                                  }: ReportRequestFormProps) => {
  const [form] = Form.useForm()

  const handleConfirm = async (request: any) => {
    onClose()
    onComplete(request)
  }

  return (
    <Modal
      maskClosable={false}
      title={title}
      closable={true}
      open={open}
      onCancel={onClose}
      width={width || '30%'}
      footer={[
        <Button
          onClick={() => form.resetFields()}
        >
          {translate('clear')}
        </Button>,
        <Button
          type='primary'
          onClick={form.submit}
        >
          {translate('ok')}
        </Button>,
      ]}
    >
      <Form
        layout='vertical'
        form={form}
        onFinish={handleConfirm}
      >
        {children}
      </Form>

    </Modal>
  )
}
