import { useDispatch, useSelector } from 'react-redux';
import { ReportViewState } from './type';
import { ReportViewer } from './ReportViewer';
import { setReportViewState } from '../../../store/redux/reducer';

export const ReportComponent = () => {
  const {reportViewState} = useSelector(s => s) as {reportViewState: ReportViewState}
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setReportViewState({
      ...reportViewState,
      open: false,
    }))
  }

  return (
    <ReportViewer
      loading={reportViewState.loading}
      title={reportViewState.title}
      fileName={reportViewState.fileName}
      data={reportViewState.data}
      open={reportViewState.open}
      onClose={handleClose}
     />
  )
}
