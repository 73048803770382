import { useContext, useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input, Tooltip } from 'antd'
import { Combo, ComboFetchFuncParameters, NumericInput } from '../../../core/componet'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { isNullOrEmpty, resolveNumber } from '../../../core/until'
import styles from './Order.module.scss'
import { Item } from '../../inventory/type'
import { Order, OrderEntry, OrderLine, OrderLineEntry, OrderRepair } from '../type'
import { getOnHandQuantity, mapEntryLine } from '../model-mapper/order-map-entity-to-entry'
import { PageContextValue, QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { OrderPageContext } from './OrderPageContext'
import { WeddingRegistry } from '../../wedding-registry/type'
import {
  weddingCalculatePrice,
  weddingReformOrderPrices,
} from '../../wedding-registry/method/wedding-registry-pricing-order'
import { getOrderPrice } from '../method/order-pricing';
import { ITEM_NUMBERS_NOT_HAVING_EFFECTS } from '../../../App.constants';
import { useLazyQuery } from '@apollo/client'
import { ItemFindRequest } from '../../inventory/type/item-query'
import { GetItemsForLookup } from '../../inventory/graphql/item.graphql'
import { OrderFormRepairCheckBox } from './OrderFormRepairCheckBox'
import { initNewRepairLine } from '../method/initNewRepairLine'

interface WeddingRegistryEntryLinesProps {
  onAmountsChanged: () => void
  onSelectRepair: (version: string) => void
  id?: number
  weddingRegistry: WeddingRegistry | null | undefined
}

export const OrderFormLines = ({
                                  onAmountsChanged,
                                  onSelectRepair,
                                  weddingRegistry,
                                }: WeddingRegistryEntryLinesProps) => {
  const {form, entity} = useContext<PageContextValue<Order, OrderEntry>>(OrderPageContext)
  const [forceUpdate, setForceUpdate] = useState<boolean>(false)
  const [itemsFind] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForLookup)

  useEffect(() => {
    const entry = form?.getFieldsValue()
    const lines = entry?.lines

    if (!(lines && lines.length > 0))
      return

    if (!weddingRegistry)
      return

    weddingReformOrderPrices(weddingRegistry, lines)
    form?.setFieldsValue(entry as any)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingRegistry])
  const itemFetch = async ({
                             request,
                             value,
                             search,
                           }: ComboFetchFuncParameters<number>) => {
    const {data} = await itemsFind({
      variables: {
        request: {
          search,
          id: value,
          take: request?.take ?? 10,
          skip: request?.skip ?? 0,
          showNoEffects: true,
        },
      },
    })

    const list = data?.itemsFind.data ?? []

    return list.map((e) => ({
      value: e.id || 0,
      display: e.sku,
      item: e || {},
    }))
  }

  const removeRepair = (version: string) => {
    const repairs = (form?.getFieldValue('repairs') ?? []) as OrderRepair[]
    form?.setFieldValue('repairs', repairs.filter(r => r.version !== version))
  }
  const onIsRepairChanged = (index: number, checked: boolean) => {
    const line = form?.getFieldValue(['lines', index]) as OrderLineEntry

    if (!checked) {
      form?.setFieldValue(['lines', index, 'repairVersion'], null)
      removeRepair(line.repairVersion)
      setForceUpdate(true)
      return
    }

    if (!line.repairVersion) {
      initNewRepairLine(form, line)

      form?.setFieldValue(['lines', index, 'repairVersion'], line.repairVersion)
      form?.setFieldValue(['lines', index, 'description'], line.description)
      form?.setFieldValue(['lines', index, 'quantity'], line.quantity)
      form?.setFieldValue(['lines', index, 'unitPrice'], line.unitPrice)
      form?.setFieldValue(['lines', index, 'takeWithQuantity'], line.takeWithQuantity)
    }

    onSelectRepair(line.repairVersion)
    onAmountsChanged()
  }

  const onSelectItem = (index: number) => (_: any, item: Item) => {
    const {price, onSale} = calculatePrice(item)
    const fieldPath = ['lines', index]

    form?.setFieldValue([...fieldPath,'item'], item)
    form?.setFieldValue([...fieldPath, 'description'], item.description)
    form?.setFieldValue([...fieldPath, 'unitPrice'], price)
    form?.setFieldValue([...fieldPath, 'onSale'], onSale)
    form?.setFieldValue([...fieldPath, 'onHandQuantity'], getOnHandQuantity(item))
    form?.setFieldValue([...fieldPath, 'taxable'], true)
    form?.setFieldValue([...fieldPath, 'sku'], item.sku)

    onLineChanged(index)
  }

  const calculatePrice = (item: Item): {price: number, onSale: boolean} => {
    const fromWeddingRegistry = weddingCalculatePrice(item.id, weddingRegistry as any)

    if (fromWeddingRegistry)
      return {price: fromWeddingRegistry, onSale: false}

    return getOrderPrice(item)
  }

  const onLineChanged = (index: number) => {
    const line: OrderLineEntry = form?.getFieldValue(['lines', index])
    mapEntryLine(line, entity?.taxRate as number)
    form?.setFieldValue(['lines', index], line)

    calculateTakeWithAndBackOrderQuantityFields(index)
    onAmountsChanged()
  }

  const onTakeWithChanged = (index: number) => {
    calculateTakeWithAndBackOrderQuantityFields(index, true)
  }

  const calculateTakeWithAndBackOrderQuantityFields = (index: number, fromTakeWithInput?: boolean) => {
    const field = ['lines', index]
    const row = form?.getFieldValue(field) as OrderLineEntry

    console.log(row)

    if (!row.sku || ITEM_NUMBERS_NOT_HAVING_EFFECTS.includes(row.sku))
      return

    const onHandQuantity = row.onHandQuantity
    const quantity = resolveNumber(row.quantity)
    const validTakeWithQuantity = Math.min(quantity, onHandQuantity)
    const takeWithEnteredByUser = fromTakeWithInput
      ? row.takeWithQuantity
      : null

    let takeWithQuantity = 0

    if (isNullOrEmpty(takeWithEnteredByUser))
      takeWithQuantity = validTakeWithQuantity
    else if (takeWithEnteredByUser && takeWithEnteredByUser > validTakeWithQuantity)
      takeWithQuantity = validTakeWithQuantity
    else
      takeWithQuantity = takeWithEnteredByUser ?? 0

    const backOrderQuantity = resolveNumber(quantity - takeWithQuantity, {changeNegativeToZero: true})

    form?.setFieldValue([...field, 'takeWithQuantity'], takeWithQuantity)
    form?.setFieldValue([...field, 'backOrderQuantity'], backOrderQuantity)
  }

  useEffect(() => {
    setForceUpdate(false)
  }, [forceUpdate])

  return (
    <Form.List name='lines'>
      {(fields, {add, remove}) => (
        <>
          <Form.Item style={{marginBottom: '0'}}>
            <Button onClick={() => add({quantity: 1})} icon={<PlusOutlined/>}>
              {translate('add', 'items')}
            </Button>
          </Form.Item>

          <table className='table mb-2'>
            <thead>
            <tr>
              <th className='text-center' style={{width: '5%'}}>
                {translate('repair_abbr')}
              </th>
              <th className='text-center' style={{width: '15%'}}>
                {translate('item_number_abbr')}
              </th>
              <th className='text-center' style={{width: '8%'}}>
                {translate('quantity_abbr')}
              </th>
              <th className='text-center' style={{width: '30%'}}>
                {translate('description')}
              </th>
              <th className='text-center' style={{width: '5%'}}>
                {translate('take_with_abbr')}
              </th>
              <th className='text-center' style={{width: '5%'}}>
                {translate('back_order_abbr')}
              </th>
              <th className='text-center' style={{width: '10%'}}>
                {translate('unit_price')}
              </th>
              <th className='text-center' style={{width: '10%'}}>
                {translate('extended')}
              </th>
              <th className='text-center' style={{width: '10%'}}>
                {translate('tax')}
              </th>
              <th className='text-center' style={{width: '5%'}}>
                {translate('taxable')}
              </th>
              <th className='text-center' style={{width: '3%'}}></th>
            </tr>
            </thead>

            <tbody className={styles['orderLines']}>
            {fields.map((field, index) => (
              <tr key={`${field.name}-${field.key}`}>
                <td className='d-flex align-items-start'>
                  <Form.Item
                    valuePropName='checked'
                    name={[field.name, 'isRepair']}
                  >
                    <OrderFormRepairCheckBox
                      onChange={checked => onIsRepairChanged(index, !!checked)}
                    />
                  </Form.Item>

                  <Form.Item name={[field.name, 'repairVersion']}>
                    <Button
                      className={styles.orderRepairVersionButton}
                      type='text'
                      shape='circle'
                      onClick={() => {
                        const line = form?.getFieldValue([
                          'lines',
                          index,
                        ]) as OrderLine
                        onSelectRepair(line?.repairVersion)
                      }}
                    >
                      {form?.getFieldValue([
                        'lines',
                        index,
                        'repairVersion',
                      ])}
                    </Button>
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, 'item', 'id']}
                    className={styles['orderLineItemNumber']}
                  >
                    <Combo<Item, number>
                      size='middle'
                      fetch={itemFetch}
                      onSelect={onSelectItem(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'quantity_abbr',
                        ),
                      },
                    ]}
                    name={[field.name, 'quantity']}
                  >
                    <NumericInput
                      className={`text-center`}
                      onChange={() => onLineChanged(index)}
                      decimalPlaces={0}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    noStyle
                    name={[field.name, 'description']}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'description',
                        ),
                      },
                    ]}
                  >
                    <Input/>
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, 'takeWithQuantity']}>
                    <NumericInput
                      className={`${styles.readOnlyInput} text-center`}
                      onChange={() => onTakeWithChanged(index)}
                      decimalPlaces={0}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, 'backOrderQuantity']}>
                    <NumericInput
                      disabled
                      className={`${styles.readOnlyInput} text-center`}
                      decimalPlaces={0}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, 'unitPrice']}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'missing_field_message',
                          'unit_price',
                        ),
                      },
                    ]}
                  >
                    <NumericInput
                      className={styles.amountInput}
                      onChange={() => onLineChanged(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, 'extended']}>
                    <NumericInput
                      className={`${styles.readOnlyInput} ${styles.amountInput}`}
                      disabled={true}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item name={[field.name, 'tax']}>
                    <NumericInput
                      className={`${styles.readOnlyInput} ${styles.amountInput}`}
                      disabled={true}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Form.Item
                    name={[field.name, 'taxable']}
                    valuePropName='checked'
                  >
                    <Checkbox
                      type='checkbox'
                      style={{display: 'flex', justifyContent: 'center'}}
                      onChange={() => onLineChanged(index)}
                    />
                  </Form.Item>
                </td>

                <td>
                  <Tooltip title={translate('remove')}>
                    <Button
                      onClick={() => {
                        const line = form?.getFieldValue(['lines', index]) as OrderLine
                        remove(index)

                        line.repairVersion && removeRepair(line.repairVersion)

                        setTimeout(() => {
                          onAmountsChanged()
                        }, 100)
                      }}
                      type='text'
                      shape='circle'
                      icon={<CloseOutlined/>}
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </>
      )}
    </Form.List>
  )
}

