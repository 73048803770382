import dayjs from 'dayjs'

export const standardFormat = 'YYYY-MM-DD'
export const dateFormat = 'M-D-YYYY'
export const dateFormatSlashed = 'M/DD/YYYY'
export const dateFormatAndTimeSlashed = `${dateFormatSlashed}, h:mm:ss a`

export const dateDisplay = (date: Date | undefined, format: string = dateFormat) => {
  if (!date)
    return ''
  return dayjs(date).format(format)
}

// @ts-ignore
export const toMoment = <T = Date>(date: Date | undefined | null): T => (date ? dayjs(date) : null) as T

