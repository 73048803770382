import { ReportFileEntry } from '../type';
import { httpRequest } from '../../../core';
import { HttpMethod } from '../../../core/type';

export class ReportFileService {
  save(dto: ReportFileEntry): Promise<void> {
    return httpRequest.execute({
      method: HttpMethod.POST,
      url: 'report-files',
      body: dto
    });
  }
}
