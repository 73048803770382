import { useState } from 'react'
import { translate } from '../../../core'
import { Checkbox, Form, Input } from 'antd'
import { Combo } from '../../../core/componet'
import { InventoryByPatternRequest, ItemType } from '../type'
import { getItemTypeComboOptions } from '../func/type-options'
import { Contact } from '../../contact/type/contact'
import { stringConcat } from '../../../core/until'
import { ReportRequestForm } from '../../../core/componet/Report/ReportRequestForm'
import { useItemReport } from './item-report.hook'
import { useLazyQuery } from '@apollo/client'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { ContactFindRequest } from '../../contact/type/contact.query'
import { GetVendorsForLookup } from '../../contact/graphql/contact.queries.graphql'
import { ItemSearchCategory } from '../component/ItemSearchCategory'

type InventoryByPatternRequestForm =
  Omit<InventoryByPatternRequest, 'categoryId' | 'subcategoryId' | 'subcategory2Id'>
  & {
  category?: Pick<InventoryByPatternRequest, 'categoryId' | 'subcategoryId' | 'subcategory2Id'>
}

export const ItemReportInventoryByPatternRequestButton = () => {
  const {show, component} = useReportInventoryByPattern()
  return (
    <>
       <span onClick={show}>
        {translate('inventory', 'by', 'pattern')}
      </span>
      {component}
    </>
  )
}

const useReportInventoryByPattern = () => {
  const [open, setOpen] = useState<boolean>(false)
  const {report} = useItemReport()
  const [findContacts] = useLazyQuery<
    QueryPageableResponse<Contact, 'contactsFind'>,
    QueryPageableRequest<ContactFindRequest>>(GetVendorsForLookup)

  return {
    component: <>
      <ReportRequestForm
        open={open}
        onClose={() => setOpen(false)}
        onComplete={(data: InventoryByPatternRequestForm) => {
          const {category} = data
          delete data.category
          return report.inventoryByPattern({
            ...data,
            categoryId: category?.categoryId ?? [],
            subcategoryId: category?.subcategoryId ?? [],
            subcategory2Id: category?.subcategory2Id ?? [],
          })
        }}
        title={translate('inventory', 'by', 'pattern')}>
        <Form.Item
          name='types'
          label={translate('type')}
        >
          <Combo<any, ItemType>
            allowMultiple
            size="middle"
            options={getItemTypeComboOptions()}
          />
        </Form.Item>

        <Form.Item
          label={translate('vendor')}
          name='vendorId'
        >
          <Combo<Contact, number>
            size="middle"
            fetch={async ({request, value, search}) => {
              const {data} = await findContacts({
                variables: {
                  request: {
                    ...request,
                    take: request?.take ?? 100,
                    skip: request?.skip ?? 0,
                    searchVendor: search,
                    id: value
                  }
                }
              })

              const list = data?.contactsFind.data ?? []

              return list.map((e) => ({
                value: e.id || 0,
                display: stringConcat(e.contactId, " - ", e.company),
                item: e || {},
              }))
            }}
          />
        </Form.Item>

        <Form.Item
          name='style'
          label={translate('style', '/', 'pattern')}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          name='category'
          label={translate('category')}
        >
          <ItemSearchCategory/>
        </Form.Item>

        <Form.Item name='inStock' valuePropName='checked'>
          <Checkbox>{translate('in_stock_only')}</Checkbox>
        </Form.Item>

        <Form.Item name='showPictures' valuePropName='checked'>
          <Checkbox>{translate('show_pictures')}</Checkbox>
        </Form.Item>
      </ReportRequestForm>
    </>,
    show: () => setOpen(true),
  }
}


