import { createSlice } from '@reduxjs/toolkit';

const initialState: AppConfiguration = {
  sideBarMenuEnabled: true
}
const appConfigurationSlice = createSlice({
  name: 'appConfiguration',
  initialState,
  reducers: {
    setSideBarMenuEnabled: (state: AppConfiguration, { payload }: { payload: AppConfiguration }) => {
      if (!payload) return;
      state.sideBarMenuEnabled = payload.sideBarMenuEnabled;
    }
  }
});

export const { setSideBarMenuEnabled } = appConfigurationSlice.actions;
export const appConfigurationReducer = appConfigurationSlice.reducer;


export interface AppConfiguration {
  sideBarMenuEnabled?: boolean;
}
